import React, { forwardRef, useImperativeHandle, useState } from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

export type ImageTrendToastContainerRefProps = {
  create403: () => void;
  create500: () => void;
};

/**
 * If you're trying to debug why you have double Toasts, that is just an
 * issue with React Strict Mode.  Don't worry about it and move on with your
 * life, unless you really want to fix it, but it'll take some time learning.
 */
// eslint-disable-next-line no-empty-pattern
const ImageTrendToastContainer = forwardRef(({}, ref: React.ForwardedRef<ImageTrendToastContainerRefProps>) => {
  const [toastList, setToastList] = useState<ImageTrendToastProps[]>([]);

  useImperativeHandle(ref, () => {
    const appendToList = (toast: ImageTrendToastProps) => {
      setToastList((prevState) => {
        if (prevState.length >= 5) {
          const [, ...arr] = prevState;
          return [...arr, toast];
        }
        return [...prevState, toast];
      });
    };

    return {
      create403: () => {
        appendToList({ title: 'Unauthorized', bodyText: 'You are unable to access this content.' });
      },
      create500: () => {
        appendToList({
          title: 'Uh oh',
          bodyText: 'Something went wrong!  Please contact support for additional information.',
        });
      },
    };
  }, []);

  return (
    <ToastContainer className="w-100 position-relative bottom-0 start-50 translate-middle-x">
      {toastList.map((_, i) => (
        <ImageTrendToast key={i} {..._} />
      ))}
    </ToastContainer>
  );
});

type ImageTrendToastProps = {
  title: string;
  bodyText: string;
};

const ImageTrendToast = (props: ImageTrendToastProps): JSX.Element => {
  const [show, setShow] = useState(true);

  return (
    <Toast bg="danger" onClose={() => setShow(false)} show={show} delay={3000} autohide>
      <Toast.Header closeButton>{props.title}</Toast.Header>
      <Toast.Body>{props.bodyText}</Toast.Body>
    </Toast>
  );
};

export default ImageTrendToastContainer;
