import { bool, number, object, string } from 'yup';
import { PartialShape } from '../../Components/common/Schema';

// @TODO: Combine this model with Kno2IntegrationModel.
export interface CreateKno2IntegrationModel {
  clientAccountId: string;
  createFirstUserFromPrimaryContact: boolean;
  primaryContactFirstName: string;
  primaryContactLastName: string;
  emailAddress: string;
  primaryContactPhoneNumber: string;
  primaryContactCustomerExtension?: string;
  role?: string;
  jobFunctionId?: number;
  organizationName: string;
  organizationSubdomain: string;
  organizationTypeId: string;
  organizationSpecialtyId: string;
  ehr: string;
  website?: string;
  npi?: string;
  organizationPrimaryPhone?: string;
  organizationPrimaryFax?: string;
  description?: string;
  careSetting?: string;
  street: string;
  city: string;
  state: string;
  postalCode: string;
}

const kno2shape: PartialShape<CreateKno2IntegrationModel> = {
  clientAccountId: string().label('Client Account ID').required(),
  createFirstUserFromPrimaryContact: bool().label('Create First User From Primary Contact'),

  // Organization
  organizationName: string().label('Organization Name').required().max(50),
  organizationSubdomain: string().label('Organization Subdomain').required().max(24),
  organizationPrimaryPhone: string().label('Organization Phone Number').required().phoneNumber(),
  // @TODO: Need to get these options from Kno2.
  organizationTypeId: string().label('Organization Type').required(),
  organizationSpecialtyId: string().label('Organization Specialty').required(),
  ehr: string().label('EHR').required(),
  jobFunctionId: number().label('Job Function').required(),
  role: string().label('Role').required(),

  // Primary Contact
  primaryContactFirstName: string().label('First Name').required(),
  primaryContactLastName: string().label('Last Name').required(),
  emailAddress: string().label('Email Address').required(),
  primaryContactPhoneNumber: string().label('Contact Phone Number').required().phoneNumber(),

  // Organization Address
  street: string().label('Street').required(),
  city: string().label('City').required(),
  state: string().label('State').required(),
  postalCode: string().label('Postal Code').required(),
};

export const kno2schema = object<CreateKno2IntegrationModel>().shape(kno2shape);
