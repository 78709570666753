import { Formik } from 'formik';
import { useEffect, useReducer } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useParams } from 'react-router';
import { useApiClient } from '../../API/useApiClient';
import ImageTrendForm from '../../Components/common/ImageTrendForm';
import { ImageTrendOption } from '../../Components/common/ImageTrendSelect';
import { dateTimeUtcToLocalString } from '../../Helpers/DateTimeHelper';
import NemsisConfigurationModel, {
  EditNemsisConfigurationModel,
  editNemsisConfigurationModelSchema,
} from '../../Models/IngressService/NemsisConfiguration';
import NemsisConfigurationPasswordField from './NemsisConfigurationPasswordField';

enum ActionTypes {
  SET_NEMSIS_CONFIGURATION = 'SET_NEMSIS_CONFIGURATION',
  ACTIVATE_NEMSIS_CONFIGURATION = 'ACTIVATE_NEMSIS_CONFIGURATION',
  DEACTIVATE_NEMSIS_CONFIGURATION = 'DEACTIVATE_NEMSIS_CONFIGURATION',
  SET_OPTIONS = 'SET_OPTIONS',
  SET_ISLOADING = 'SET_ISLOADING',
  SET_IS_EDIT = 'SET_IS_EDIT',
}

type Options = {
  schemaVersions: ImageTrendOption<number>[];
  dataSchemas: ImageTrendOption<number>[];
};

type State = {
  nemsisConfiguration: NemsisConfigurationModel;
  options: Options;
  isLoading: boolean;
  isEdit: boolean;
};

export const initialState: State = {
  nemsisConfiguration: {
    id: '',
    name: '',
    isActive: false,
    transactionServiceModuleId: '',
    transactionServiceModuleType: '',
    username: '',
    createdOnUtc: new Date(),
    modifiedOnUtc: undefined as Date | undefined,
    dataSchema: {
      name: '',
      value: 0,
    },
    schemaVersion: {
      name: '',
      value: 0,
    },
  },
  options: {
    schemaVersions: [],
    dataSchemas: [],
  },
  isLoading: true,
  isEdit: false,
};

type SetNemsisConfigurationAction = {
  type: typeof ActionTypes.SET_NEMSIS_CONFIGURATION;
  nemsisConfiguration: NemsisConfigurationModel;
};

type ActivateNemsisConfigurationAction = {
  type: typeof ActionTypes.ACTIVATE_NEMSIS_CONFIGURATION;
};

type DeactivateNemsisConfigurationAction = {
  type: typeof ActionTypes.DEACTIVATE_NEMSIS_CONFIGURATION;
};

type SetOptions = {
  type: typeof ActionTypes.SET_OPTIONS;
  options: Options;
};

type SetIsLoading = {
  type: typeof ActionTypes.SET_ISLOADING;
  isLoading: boolean;
};

type SetIsEdit = {
  type: typeof ActionTypes.SET_IS_EDIT;
  isEdit: boolean;
};

type ReducerActions =
  | SetNemsisConfigurationAction
  | ActivateNemsisConfigurationAction
  | DeactivateNemsisConfigurationAction
  | SetIsLoading
  | SetIsEdit
  | SetOptions;

const setNemsisConfiguration = (nemsisConfiguration: NemsisConfigurationModel): ReducerActions => ({
  type: ActionTypes.SET_NEMSIS_CONFIGURATION,
  nemsisConfiguration,
});

const activateNemsisConfiguration = (): ReducerActions => ({
  type: ActionTypes.ACTIVATE_NEMSIS_CONFIGURATION,
});

const deactivateNemsisConfiguration = (): ReducerActions => ({
  type: ActionTypes.DEACTIVATE_NEMSIS_CONFIGURATION,
});

const setOptions = (options: Options): ReducerActions => ({
  type: ActionTypes.SET_OPTIONS,
  options,
});

const setIsLoading = (isLoading: boolean): ReducerActions => ({
  type: ActionTypes.SET_ISLOADING,
  isLoading,
});

const setEdit = (isEdit: boolean): ReducerActions => ({
  type: ActionTypes.SET_IS_EDIT,
  isEdit,
});

export const reducer = (state: State, action: ReducerActions): State => {
  switch (action.type) {
    case ActionTypes.SET_NEMSIS_CONFIGURATION:
      return {
        ...state,
        nemsisConfiguration: action.nemsisConfiguration,
      };
    case ActionTypes.ACTIVATE_NEMSIS_CONFIGURATION:
      return {
        ...state,
        nemsisConfiguration: {
          ...state.nemsisConfiguration,
          isActive: true,
        },
      };
    case ActionTypes.DEACTIVATE_NEMSIS_CONFIGURATION:
      return {
        ...state,
        nemsisConfiguration: {
          ...state.nemsisConfiguration,
          isActive: false,
        },
      };
    case ActionTypes.SET_OPTIONS:
      const { options } = action;
      return {
        ...state,
        options,
      };
    case ActionTypes.SET_ISLOADING:
      const { isLoading } = action;
      return {
        ...state,
        isLoading,
      };
    case ActionTypes.SET_IS_EDIT:
      const { isEdit } = action;
      return {
        ...state,
        isEdit,
      };
    default:
      return state;
  }
};

const NemsisConfigurationView = (): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { moduleId } = useParams<{ moduleId: string }>();
  const apiClient = useApiClient();

  useEffect(() => {
    const fetchData = async () => {
      if (moduleId) {
        const nemsisConfigurationResult =
          await apiClient.IngressServiceClient.GetNemsisConfigurationByTransactionServiceModuleId(moduleId);
        if (nemsisConfigurationResult) {
          dispatch(setNemsisConfiguration(nemsisConfigurationResult));
        }

        const schemaVersions = apiClient.IngressServiceClient.GetNemsisSchemaVersions();
        const dataSchemas = apiClient.IngressServiceClient.GetNemsisDataSchemas();

        // Double await for half the wait
        const options = {
          schemaVersions: (await schemaVersions).map((_) => ({ label: _.name, value: _.value })),
          dataSchemas: (await dataSchemas).map((_) => ({ label: _.name, value: _.value })),
        };
        dispatch(setOptions(options));
      }
      dispatch(setIsLoading(false));
    };

    fetchData();
  }, [apiClient.IngressServiceClient, moduleId]);

  const handleEditClick = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(setEdit(true));
  };

  return (
    <>
      {state.isLoading ? (
        <>
          <h1>Nemsis Configuration</h1>
          <ImageTrendForm.LoadingSpinner />
        </>
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={state.nemsisConfiguration}
          validationSchema={editNemsisConfigurationModelSchema}
          onSubmit={async (values) => {
            try {
              const editValues: EditNemsisConfigurationModel = {
                name: values.name,
                nemsisDataSchema: values.dataSchema.value,
                nemsisSchemaVersion: values.schemaVersion.value,
              };
              const nemsisConfiguration = await apiClient.IngressServiceClient.PatchEditNemsisConfiguration(
                state.nemsisConfiguration.id,
                editValues,
              );
              dispatch(setNemsisConfiguration(nemsisConfiguration));
              dispatch(setEdit(false));
            } catch (error) {
              //dispatch(setAlert('Error occurred while updating NEMSIS Configuration.'));
            }
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <h1>
                Nemsis Configuration{' '}
                {!state.isEdit ? (
                  <Button onClick={handleEditClick}>Edit</Button>
                ) : (
                  <Button disabled={isSubmitting} type="submit">
                    Save
                  </Button>
                )}
              </h1>
              <ImageTrendForm.HiddenGroup controlId="nemsisConfiguration.id">
                <ImageTrendForm.Hidden defaultValue={state.nemsisConfiguration.id} />
              </ImageTrendForm.HiddenGroup>
              <ImageTrendForm.Group controlId="nemsisConfiguration.username">
                <ImageTrendForm.Label>Username</ImageTrendForm.Label>
                <ImageTrendForm.Plaintext value={state.nemsisConfiguration.username} />
              </ImageTrendForm.Group>
              <NemsisConfigurationPasswordField id={state.nemsisConfiguration.id} />
              <ImageTrendForm.Group controlId="nemsisConfiguration.name">
                <ImageTrendForm.Label>Configuration Name</ImageTrendForm.Label>
                <ImageTrendForm.Field isEditable={state.isEdit} name="name" />
              </ImageTrendForm.Group>
              <ImageTrendForm.Group controlId="nemsisConfiguration.isActive">
                <ImageTrendForm.Label>Active</ImageTrendForm.Label>
                <ImageTrendForm.Switch
                  checked={state.nemsisConfiguration.isActive}
                  onChange={(checked: boolean) => {
                    const toggleActiveStatusAsync = async () => {
                      if (checked) {
                        await apiClient.IngressServiceClient.PatchActivateNemsisConfiguration(
                          state.nemsisConfiguration.id,
                        );
                        dispatch(activateNemsisConfiguration());
                      } else {
                        await apiClient.IngressServiceClient.PatchDeactivateNemsisConfiguration(
                          state.nemsisConfiguration.id,
                        );
                        dispatch(deactivateNemsisConfiguration());
                      }
                    };
                    toggleActiveStatusAsync();
                  }}
                />
              </ImageTrendForm.Group>
              <ImageTrendForm.Group controlId="nemsisConfiguration.dataSchema">
                <ImageTrendForm.Label>Data Schema</ImageTrendForm.Label>
                <ImageTrendForm.SelectField
                  isEditable={state.isEdit}
                  options={state.options.dataSchemas}
                  name="dataSchema.value"
                />
              </ImageTrendForm.Group>
              <ImageTrendForm.Group controlId="nemsisConfiguration.schemaVersion">
                <ImageTrendForm.Label>Schema Version</ImageTrendForm.Label>
                <ImageTrendForm.SelectField
                  isEditable={state.isEdit}
                  options={state.options.schemaVersions}
                  name="schemaVersion.value"
                />
              </ImageTrendForm.Group>
              <ImageTrendForm.Group controlId="nemsisConfiguration.createdOn">
                <ImageTrendForm.Label>Created On</ImageTrendForm.Label>
                <ImageTrendForm.Plaintext value={dateTimeUtcToLocalString(state.nemsisConfiguration.createdOnUtc)} />
              </ImageTrendForm.Group>
              <ImageTrendForm.Group controlId="nemsisConfiguration.modifiedOn">
                <ImageTrendForm.Label>Modified On</ImageTrendForm.Label>
                <ImageTrendForm.Plaintext value={dateTimeUtcToLocalString(state.nemsisConfiguration.modifiedOnUtc)} />
              </ImageTrendForm.Group>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default NemsisConfigurationView;
