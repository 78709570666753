import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { faCogs, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { jwtDecode } from 'jwt-decode';
import { ForwardedRef, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import Col from 'react-bootstrap/Col';
import DropdownItem from 'react-bootstrap/DropdownItem';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import { FaEnvelopeOpenText, FaFileExport, FaHome, FaTools, FaUsers } from 'react-icons/fa';
import { Link, NavLink } from 'react-router-dom';
import { getTokenRequest } from '../../API/clients/AxiosFunctions';
import '../../Styling/overlay.css';
import routes from '../../common/routesDefinitions';
import { Roles } from '../security/Roles';
import ImageTrendToastContainer, { ImageTrendToastContainerRefProps } from './ImageTrendToast';
import './sidebar.scss';

type OverlayProps = {
  toastRef: ForwardedRef<ImageTrendToastContainerRefProps>;
};

const Overlay = ({ children, toastRef }: PropsWithChildren<OverlayProps>) => {
  const [roles, setRoles] = useState<string[]>([]);
  const { instance, inProgress } = useMsal();

  const sidebarPaths = [
    {
      path: routes.dashboard,
      name: 'Dashboard',
      icon: <FaHome />,
    },
    {
      path: routes.accounts,
      name: 'Accounts',
      icon: <FaUsers />,
    },
    {
      path: routes.transactions,
      name: 'Transactions',
      icon: <FaFileExport />,
    },
    {
      path: routes.audits,
      name: 'Audits',
      icon: <FaEnvelopeOpenText />,
    },
    {
      path: routes.resource_manager,
      name: 'Resource Manager',
      icon: <FaTools />,
    },
  ];

  useEffect(() => {
    const getRoles = async () => {
      if (inProgress === InteractionStatus.None) {
        const response = await getTokenRequest(instance);
        if (!response) return;
        const tokenRoles = (jwtDecode(response.accessToken) as { roles: string[] })?.roles || [];
        setRoles(tokenRoles);
      }
    };

    getRoles();
  }, [instance, inProgress]);

  const activeRole = useMemo(() => {
    return roles.every((x) => x !== Roles.SuperAdmin)
      ? roles.every((x) => x !== Roles.Admin)
        ? 'User'
        : 'Admin'
      : 'Super Admin';
  }, [roles]);

  const logout = () => {
    const logoutRequest = {
      account: instance.getActiveAccount(),
      postLogoutRedirectUri: '/',
    };

    instance.logoutPopup(logoutRequest);
  };

  return (
    <div className="container-main">
      <Navbar className="sidebar-main py-0">
        <Col className="d-flex flex-column align-items-top h-100 w-100">
          <Navbar.Brand>
            <img className="hih-logo" src="/img/hihlogoWhite.png" alt="HIH Logo (White)" />
          </Navbar.Brand>
          <div className="sidebar-divider"></div>
          <Nav className="sidenav d-flex flex-column overflow-y-auto overflow-x-hidden p-1 h-100">
            <Col className="d-flex flex-column align-items-top">
              {sidebarPaths.map((item, index) => (
                <NavLink to={item.path} key={index} className="sidebar-link">
                  <div className="sidebar-icon">{item.icon}</div>
                  <div className="sidebar-text">{item.name}</div>
                </NavLink>
              ))}
            </Col>
            <div className="mt-auto">
              <NavDropdown
                id="user-menu"
                drop="up"
                title={
                  <>
                    <span className="text-truncate">{instance.getActiveAccount()?.name}</span>{' '}
                    <span>({activeRole})</span>
                  </>
                }
              >
                {
                  <DropdownItem as={Link} to={routes.settings}>
                    <FontAwesomeIcon icon={faCogs} /> Settings
                  </DropdownItem>
                }
                <DropdownItem onClick={logout}>
                  <FontAwesomeIcon icon={faRightFromBracket} /> Logout
                </DropdownItem>
              </NavDropdown>
            </div>
          </Nav>
        </Col>
      </Navbar>
      <main className="content-main">
        {children}
        <div className="position-absolute bottom-0 d-flex justify-content-center">
          <ImageTrendToastContainer ref={toastRef} />
        </div>
      </main>
    </div>
  );
};

export default Overlay;
