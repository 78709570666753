// helper function to convert a UTC Date to a Local Date
export const dateTimeUtcToLocalString = (dateTimeString?: Date | null): string => {
  if (!dateTimeString) return '';
  var newDate = new Date(dateTimeString);
  newDate = new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60 * 1000);
  return newDate.toLocaleString();
};

export const dateTimeToDateInputString = (dateTimeString?: Date | null): string => {
  if (!dateTimeString) return '';
  var newDate = new Date(dateTimeString);
  return [
    newDate.getFullYear(),
    String(newDate.getMonth() + 1).padStart(2, '0'),
    String(newDate.getDate()).padStart(2, '0'),
  ].join('-');
};
