import { QueryParameters } from '../../Models/Common/QueryParameters';
import { QueryResults } from '../../Models/Common/QueryResults';
import { ClientAccountModel } from '../../Models/HihClient/ClientAccountModel';
import { CreateKno2IntegrationModel } from '../../Models/HihClient/CreateKno2IntegrationModel';
import { Kno2IntegrationModel } from '../../Models/HihClient/Kno2IntegrationModel';
import { CreateKno2IntegrationOrderFormModel } from '../../Models/TransactionService/Kno2IntegrationOrderFormModel';
import api from '../api';
import { ApiClientProps } from './ApiClient';
import getAxiosFunctions from './AxiosFunctions';
import { appendQueryString } from './QueryStrings';

export function createHihHelperApiClient(props: ApiClientProps) {
  const { getData, getFile, postData, putData } = getAxiosFunctions(props);

  const client = Object.freeze({
    GetClientAccountList: async function (query?: QueryParameters): Promise<QueryResults<ClientAccountModel>> {
      try {
        const endpoint = appendQueryString(`${api.hihHelperUrl}/client-accounts`, query);
        const response = await getData(endpoint);
        return response as QueryResults<ClientAccountModel>;
      } catch (error) {
        const message = 'Error fetching client account list';
        console.error(`${message}:`, error);
        throw error;
      }
    },
    GetClientAccount: async function (id: string): Promise<ClientAccountModel | null> {
      try {
        const endpoint = `${api.hihHelperUrl}/client-accounts/${id}`;
        const response = await getData(endpoint);
        return response as ClientAccountModel | null;
      } catch (error) {
        const message = 'Error fetching client account';
        console.error(`${message}:`, error);
        throw error;
      }
    },
    PostClientAccount: async function (clientAccount: ClientAccountModel): Promise<ClientAccountModel> {
      try {
        const endpoint = `${api.hihHelperUrl}/client-accounts/`;
        const response = (await postData(endpoint, clientAccount)) as ClientAccountModel;
        return response;
      } catch (error) {
        const message = 'Error creating client account';
        console.error(`${message}:`, error);
        throw new Error(`${message}.`);
      }
    },
    PutClientAccount: async function (clientAccount: ClientAccountModel): Promise<ClientAccountModel> {
      try {
        const endpoint = `${api.hihHelperUrl}/client-accounts/`;
        const response = (await putData(endpoint, clientAccount)) as ClientAccountModel;
        return response;
      } catch (error) {
        const message = 'Error updating client account';
        console.error(`${message}:`, error);
        throw new Error(`${message}.`);
      }
    },
    GetKno2IntegrationByClientId: async function (clientAccountId: string): Promise<Kno2IntegrationModel | null> {
      try {
        const endpoint = appendQueryString(`${api.hihHelperUrl}/kno2-integrations`, {
          clientAccountId: clientAccountId,
        });
        const data = await getData(endpoint);
        return data as Kno2IntegrationModel | null;
      } catch (error) {
        const message = 'Error fetching Kno2 Integration';
        console.error(`${message}:`, error);
        throw error;
      }
    },
    GetKno2Integration: async function (id: string): Promise<Kno2IntegrationModel | null> {
      try {
        const endpoint = `${api.hihHelperUrl}/kno2-integrations/${id}`;
        const data = await getData(endpoint);
        return data as Kno2IntegrationModel | null;
      } catch (error) {
        const message = 'Error fetching Kno2 Integration';
        console.error(`${message}:`, error);
        throw error;
      }
    },
    PostKno2Integration: async function (
      kno2Integration: CreateKno2IntegrationModel,
    ): Promise<CreateKno2IntegrationModel> {
      try {
        const endpoint = `${api.hihHelperUrl}/kno2-integrations`;
        const response = (await postData(endpoint, kno2Integration)) as CreateKno2IntegrationModel;
        return response;
      } catch (error) {
        const message = 'Error creating Kno2 Integration';
        console.error(`${message}:`, error);
        throw new Error(`${message}.`);
      }
    },
    GetKno2WebhookById: async function (id: string) {
      try {
        const endpoint = `${api.hihHelperUrl}/kno2-webhooks/${id}`;
        const response = await getData(endpoint);
        return response;
      } catch (error) {
        const message = 'Error fetching Kno2 Webhook by ID';
        console.error(`${message}:`, error);
        throw new Error(`${message}.`);
      }
    },
    ActivateKno2IntegrationWebhook: async function (data: any) {
      try {
        const endpoint = `${api.hihHelperUrl}/webhook/kno2-integrations/activate`;
        const response = await postData(endpoint, data);
        return response;
      } catch (error) {
        const message = 'Error activating Kno2 Integration Webhook';
        console.error(`${message}:`, error);
        throw new Error(`${message}.`);
      }
    },
    ActivateKno2Integration: async function (id: string) {
      try {
        const endpoint = `${api.hihHelperUrl}/kno2-webhooks/activate/${id}`;
        const response = await postData(endpoint, {});
        return response;
      } catch (error) {
        const message = 'Error activating Kno2 Integration';
        console.error(`${message}:`, error);
        throw new Error(`${message}.`);
      }
    },
    DeactivateKno2Integration: async function (id: string) {
      try {
        const endpoint = `${api.hihHelperUrl}/kno2-webhooks/deactivate/${id}`;
        const response = await postData(endpoint, {});
        return response;
      } catch (error) {
        const message = 'Error deactivating Kno2 Integration';
        console.error(`${message}:`, error);
        throw new Error(`${message}.`);
      }
    },
    GetOrganizationTypes: async function () {
      try {
        const endpoint = `${api.hihHelperUrl}/kno2-integrations/organization-types`;
        const response = await getData(endpoint);
        return response;
      } catch (error) {
        const message = 'Error fetching organization types';
        console.error(`${message}:`, error);
        throw new Error(`${message}.`);
      }
    },
    GetOrganizationSpecialties: async function () {
      try {
        const endpoint = `${api.hihHelperUrl}/kno2-integrations/organization-specialties`;
        const response = await getData(endpoint);
        return response;
      } catch (error) {
        const message = 'Error fetching organization specialties';
        console.error(`${message}:`, error);
        throw new Error(`${message}.`);
      }
    },
    GetJobFunctions: async function () {
      try {
        const endpoint = `${api.hihHelperUrl}/kno2-integrations/job-functions`;
        const response = await getData(endpoint);
        return response;
      } catch (error) {
        const message = 'Error fetching job functions';
        console.error(`${message}:`, error);
        throw new Error(`${message}.`);
      }
    },
    // Kno2IntegrationOrderForm
    GetKno2IntegrationOrderFormByKno2IntegrationId: async function (kno2IntegrationId: string) {
      try {
        const endpoint = `${api.hihHelperUrl}/kno2-integration-order-forms/kno2-integration-id/${kno2IntegrationId}`;
        const response = await getData(endpoint);
        return response;
      } catch (error) {
        const message = 'Error fetching Kno2 integration order form';
        console.error(`${message}:`, error);
        throw error;
      }
    },
    /**
     * Create a Kno2 Integration Order Form.
     * @param entity Entity to be saved.
     * @returns ID of the newly created entity.
     */
    PostKno2IntegrationOrderForm: async function (entity: CreateKno2IntegrationOrderFormModel) {
      try {
        const endpoint = `${api.hihHelperUrl}/kno2-integration-order-forms`;
        const response = (await postData(endpoint, entity)) as string;
        return response;
      } catch (error) {
        console.error('Error creating Kno2 integration order form:', error);
        throw error;
      }
    },
    /**
     * Downloads the Kno2 Integration Order Form CSV form.
     * @param id ID of the Kno2 Integration Order Form.
     * @returns CSV file with the necessary Kno2 Integration Order Form fields filled out.
     */
    ExportKno2IntegrationOrderFormCsv: async function (id: string) {
      try {
        const endpoint = `${api.hihHelperUrl}/kno2-integration-order-forms/${id}/export`;
        const response = await getFile(endpoint);
        return response;
      } catch (error) {
        console.error('Error exporting Kno2 integration order form CSV:', error);
        throw error;
      }
    },
  });
  return client;
}

export type HihHelperApiClient = ReturnType<typeof createHihHelperApiClient>;
