import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { useApiClient } from '../../API/useApiClient';
import ImageTrendForm from '../../Components/common/ImageTrendForm';
import ImageTrendPagination from '../../Components/common/ImageTrendPagination';
import ImageTrendTable from '../../Components/common/ImageTrendTable';
import { DefaultPagination, PaginationReducer } from '../../Components/common/Pagination.types';
import { dateTimeUtcToLocalString } from '../../Helpers/DateTimeHelper';
import { QueryResults } from '../../Models/Common/QueryResults';
import { ClientAccountModel } from '../../Models/HihClient/ClientAccountModel';
import routes from '../../common/routesDefinitions';

const ClientAccountList = () => {
  const [data, setData] = useState<QueryResults<ClientAccountModel>>({ results: [], totalRows: 0 });
  const [isLoading, setIsLoading] = useState(true);
  const apiClient = useApiClient();
  const columnHelper = createColumnHelper<ClientAccountModel>();
  const [tableState, dispatchTableState] = useImmerReducer(PaginationReducer, DefaultPagination);

  const resultColumns: ColumnDef<ClientAccountModel, any>[] = [
    columnHelper.accessor('name', {
      header: 'Name',
      cell: ({ row }) => (
        <Link to={routes.client_accounts_details.replace(':id', row.original.id.toString())}>{row.original.name}</Link>
      ),
    }),
    columnHelper.accessor((row) => dateTimeUtcToLocalString(row.createdOnUtc), {
      id: 'createdOnUtc',
      header: 'Created On',
    }),
  ];

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const data = await apiClient.HihHelperClient.GetClientAccountList(tableState);
      setData(data);
      setIsLoading(false);
    };

    fetchData();
  }, [apiClient.HihHelperClient, tableState]);

  return (
    <>
      <div>
        <h1>Client Accounts</h1>
        {isLoading ? (
          <ImageTrendForm.LoadingSpinner />
        ) : data?.results?.length > 0 ? (
          <div>
            <ImageTrendTable columns={resultColumns} data={data.results} striped={true} />
            <ImageTrendPagination
              paginationQuery={tableState}
              updatePaginationQuery={dispatchTableState}
              total={data.totalRows}
            />
          </div>
        ) : (
          <p>No data available</p>
        )}
        <Link to={routes.client_accounts_create}>
          <Button>
            <FaPlus /> Create
          </Button>
        </Link>
      </div>
    </>
  );
};

export default ClientAccountList;
