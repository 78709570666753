import { CacheLookupPolicy, Configuration, PopupRequest, SilentRequest } from '@azure/msal-browser';
import api from './API/api';

export const msalConfig: Configuration = {
  auth: {
    clientId: `${api.msal_client_id}`,
    authority: 'https://login.microsoftonline.com/c0526fff-f991-46f0-92eb-95ec79c25ada',
    knownAuthorities: ['https://login.microsoftonline.com'],
    redirectUri: '/',
  },
};

export const loginRequest: PopupRequest = {
  scopes: [`api://${api.msal_api_domain}/access_as_user`],
  domainHint: 'imagetrend.com',
};

export const apiRequest: SilentRequest = {
  scopes: [`api://${api.msal_api_domain}/access_as_user`],
  forceRefresh: false,
  cacheLookupPolicy: CacheLookupPolicy.Default,
};
