import { AccountInfo } from "@azure/msal-browser";
import { Roles } from "./Roles";

export const IsAccountAdmin = (account: AccountInfo): boolean =>
{
    return AccountHasRole(account, Roles.Admin);
}

export const IsAccountSuperAdmin = (account: AccountInfo): boolean =>{
    return AccountHasRole(account, Roles.SuperAdmin);
}

const AccountHasRole = (account: AccountInfo, role: string): boolean =>
{
    return !!account.idTokenClaims?.roles && account.idTokenClaims.roles.indexOf(role) !== -1;
}