import { addMethod, number, object, string, StringSchema } from 'yup';
import { PartialShape } from '../../Components/common/Schema';
import { ClientAccountModel } from '../../Models/HihClient/ClientAccountModel';

const shape: PartialShape<ClientAccountModel> = {
  name: string().label('Name').required(),
  ticketNumber: number().label('Ticket Number').required(),
  customerId: number().label('Customer ID').required(),
};

/**
 * Super awesome custom yup formats.
 */

function hexadecimal(this: StringSchema) {
  // eslint-disable-next-line no-template-curly-in-string
  return this.matches(/^(0x)?[0-9a-fA-F]+$/, '${path} is not a valid hexadecimal value');
}
addMethod(string, 'hexadecimal', hexadecimal);

function phoneNumber(this: StringSchema) {
  return this.matches(
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    // eslint-disable-next-line no-template-curly-in-string
    '${path} is not a valid phone number',
  );
}
addMethod(string, 'phoneNumber', phoneNumber);

export const addYupMethods = () => {
  addMethod(string, 'hexadecimal', hexadecimal);
  addMethod(string, 'phoneNumber', phoneNumber);
};

export const clientAccountSchema = object<ClientAccountModel>().shape(shape);
