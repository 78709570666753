import React, { useReducer, useState, ReactNode, useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';

interface SearchFilterProps {
    onSearch: (filters: any) => void;
    onReset: () => void;
    children: ReactNode;
}

const initialState = {
    startDate: '',
    endDate: '',
    moduleId: '',
    statusCode: '',
    correlationId: ''
};

const filterReducer = (state: typeof initialState, action: { type: string; payload?: any }) => {
    switch (action.type) {
        case 'SET_START_DATE':
            return { ...state, startDate: action.payload };
        case 'SET_END_DATE':
            return { ...state, endDate: action.payload };
        case 'SET_MODULE_ID':
            return { ...state, moduleId: action.payload };
        case 'SET_STATUS_CODE':
            return { ...state, statusCode: action.payload };
        case 'SET_CORRELATION_ID':
            return { ...state, correlationId: action.payload };
        case 'RESET':
            return initialState;
        default:
            return state;
    }
};

const SearchFilter: React.FC<SearchFilterProps> = ({ onSearch, onReset, children }) => {
    const [filters, dispatch] = useReducer(filterReducer, initialState);
    const [isExpanded, setIsExpanded] = useState(false);

    const handleSearch = useCallback(() => {
        onSearch(filters);
    }, [onSearch, filters]);

    const handleReset = useCallback(() => {
        dispatch({ type: 'RESET' });
        onReset();
    }, [onReset]);

    return (
        <Container fluid className="mb-3 px-0">
            <Button
                variant="secondary"
                onClick={() => setIsExpanded(!isExpanded)}
                aria-expanded={isExpanded}
                aria-controls="filter-container"
                className="w-100 text-left d-flex justify-content-between"
            >
                <span>Filters</span>
                <span aria-hidden="true">
                    {isExpanded ? '▲' : '▼'}
                </span>
            </Button>
            <Collapse in={isExpanded}>
                <div id="filter-container" className="p-3 border rounded bg-light">
                    <Form>
                        <Row>
                            {React.Children.map(children, (child) =>
                                React.isValidElement(child) &&
                                React.cloneElement(child, {
                                    dispatch,
                                    filters,
                                    ...child.props,
                                })
                            )}
                        </Row>
                        <Row className="mt-3">
                            <Col className="d-flex justify-content-start">
                                <Button variant="primary" onClick={handleSearch} aria-label="Search">
                                    Search
                                </Button>
                                <Button variant="secondary" onClick={handleReset} aria-label="Reset">
                                    Reset
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Collapse>
        </Container>
    );
};

export default SearchFilter;
