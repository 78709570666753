import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { useApiClient } from '../../API/useApiClient';
import DateRange from '../../Components/Filters/DateRange';
import ModuleID from '../../Components/Filters/ModuleID';
import SearchFilter from '../../Components/Filters/SearchFilter';
import StatusCode from '../../Components/Filters/StatusCode';
import ImageTrendForm from '../../Components/common/ImageTrendForm';
import ImageTrendPagination from '../../Components/common/ImageTrendPagination';
import ImageTrendTable from '../../Components/common/ImageTrendTable';
import { DefaultPagination, PaginationReducer } from '../../Components/common/Pagination.types';
import { dateTimeUtcToLocalString } from '../../Helpers/DateTimeHelper';
import { QueryResults } from '../../Models/Common/QueryResults';
import { Kno2TransactionListItem } from '../../Models/TransactionService/Kno2Transaction';
import routes from '../../common/routesDefinitions';
import Kno2StatusIcon, { Kno2TransactionStatusEnum } from './Kno2TransactionStatusIcon';

const initialState = {
  startDate: '',
  endDate: '',
  moduleId: '',
  statusCode: '',
  correlationId: '',
};

const filterReducer = (state: typeof initialState, action: { type: string; payload?: any }) => {
  switch (action.type) {
    case 'SET_START_DATE':
      return { ...state, startDate: action.payload };
    case 'SET_END_DATE':
      return { ...state, endDate: action.payload };
    case 'SET_MODULE_ID':
      return { ...state, moduleId: action.payload };
    case 'SET_STATUS_CODE':
      return { ...state, statusCode: action.payload };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};

const Kno2TransactionList = () => {
  const [filters, dispatch] = useReducer(filterReducer, initialState);
  const [appliedFilters, setAppliedFilters] = useState(initialState);
  const [data, setData] = useState<QueryResults<Kno2TransactionListItem>>({ results: [], totalRows: 0 });
  const [isLoading, setIsLoading] = useState(true);
  const [orderDirection, setOrderDirection] = useState('desc');
  const apiClient = useApiClient();
  const columnHelper = createColumnHelper<Kno2TransactionListItem>();
  const [tableState, dispatchTableState] = useImmerReducer(PaginationReducer, DefaultPagination);

  const toggleSortingOrder = useCallback(() => {
    setOrderDirection((prevDirection) => (prevDirection === 'desc' ? 'asc' : 'desc'));
  }, []);

  const resultColumns: ColumnDef<Kno2TransactionListItem, any>[] = useMemo(
    () => [
      columnHelper.accessor('moduleName', {
        header: 'Module Name',
        cell: ({ row }) => (
          <Link to={routes.kno2_transactions_details.replace(':id', row.original.id.toString())}>
            {row.original.moduleName}
          </Link>
        ),
      }),
      {
        header: 'Status Code',
        cell: ({ row }) => (
          <div>
            <Kno2StatusIcon statusCode={row.original.status.value} />
          </div>
        ),
        id: 'statusCode',
      },
      columnHelper.accessor((row: Kno2TransactionListItem) => row.status.name, { id: 'status', header: 'Status' }),
      columnHelper.accessor('toAddress', { header: 'To Address' }),
      columnHelper.accessor('kno2MessageStatus', {
        header: 'Kno2 Message Status',
        cell: ({ row }) => <>{row.original.kno2MessageStatus || 'None'}</>,
      }),
      columnHelper.accessor((row: Kno2TransactionListItem) => dateTimeUtcToLocalString(row.createdOnUtc), {
        id: 'createdOnUtc',
        header: () => (
          <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={toggleSortingOrder}>
            Created On {orderDirection === 'desc' ? '▼' : '▲'}
          </div>
        ),
        enableSorting: true,
      }),
    ],
    [orderDirection, columnHelper, toggleSortingOrder],
  );

  /* ToDo: Modify ImageTrendTable for sorting by adding sortingState, 
             updating useReactTable, making headers clickable, sortable columns*/
  // const table = useReactTable({
  //     data: data.results,
  //     columns: resultColumns,
  //     getCoreRowModel: getCoreRowModel(),
  //     getSortedRowModel: getSortedRowModel(),
  //     manualSorting: true,
  // });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const queryParameters = {
        ...tableState,
        ...appliedFilters,
        orderBy: 'createdOnUtc',
        orderDirection,
      };
      try {
        const data = await apiClient.TransactionServiceClient.GetKno2TransactionList(queryParameters);
        setData(data);
      } catch (error) {
        console.error('Error fetching Kno2 transaction list:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [tableState, orderDirection, appliedFilters, apiClient.TransactionServiceClient]);

  const handleSearch = useCallback(() => {
    setAppliedFilters(filters);
  }, [filters]);

  const handleReset = useCallback(() => {
    dispatch({ type: 'RESET' });
    setAppliedFilters(initialState);
  }, []);

  const statusOptions = useMemo(
    () =>
      Object.entries(Kno2TransactionStatusEnum)
        .filter(([, value]) => typeof value === 'number')
        .map(([key, value]) => ({
          value: value as number,
          label: key,
        })),
    [],
  );

  return (
    <div>
      <h1>Kno2 Transactions</h1>

      <SearchFilter onSearch={handleSearch} onReset={handleReset}>
        <DateRange dispatch={dispatch} filters={filters} />
        <ModuleID dispatch={dispatch} filters={filters} />
        <StatusCode dispatch={dispatch} filters={filters} statusOptions={statusOptions} />
      </SearchFilter>

      {isLoading ? (
        <ImageTrendForm.LoadingSpinner />
      ) : data?.results?.length > 0 ? (
        <div>
          <ImageTrendTable columns={resultColumns} data={data.results} striped={true} />
          <ImageTrendPagination
            paginationQuery={tableState}
            updatePaginationQuery={dispatchTableState}
            total={data.totalRows}
          />
        </div>
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};

export default Kno2TransactionList;
