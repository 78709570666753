import { Schema, object, string } from 'yup';
import { PartialShape } from '../../../Components/common/Schema';
import { DestinationMappingModel, DestinationInfo } from '../../../Models/TransactionService/DestinationMappingModel';

const destinationInfoShape: Schema<DestinationInfo> = object().shape({
  destinationName: string().label('Destination Name').required(),
  destinationCode: string().label('Destination Code').required(),
  directAddress: string().label('Direct Address').required(),
  faxNumber: string().label('Fax Number'),
  oid: string().label('OID'),
});

const shape: PartialShape<DestinationMappingModel> = {
  destinationInfo: destinationInfoShape,
};

export const destinationMappingSchema = object<DestinationMappingModel>().shape(shape);
