import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import { Link } from 'react-router-dom';
import routes from '../../common/routesDefinitions';

const Accounts = () => {
  return (
    <div>
      <h1>Accounts</h1>
      <br />
      <ListGroup>
        <ListGroupItem>
          <Link to={routes.client_accounts}>Manage Client Accounts</Link>
        </ListGroupItem>
      </ListGroup>
    </div>
  );
};

export default Accounts;
