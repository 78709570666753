import { Formik } from 'formik';
import { useEffect, useReducer } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { FaPlus } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { useApiClient } from '../../../API/useApiClient';
import BackButton from '../../../Components/common/BackButton';
import ImageTrendForm from '../../../Components/common/ImageTrendForm';
import { dateTimeUtcToLocalString } from '../../../Helpers/DateTimeHelper';
import { DestinationMappingModel } from '../../../Models/TransactionService/DestinationMappingModel';
import routes from '../../../common/routesDefinitions';
import { destinationMappingSchema } from '../DestinationMappings/DestinationMapping.types';

interface DestinationMappingProps {
  isCreate?: boolean;
}

enum ActionTypes {
  SET_DESTINATION_MAPPING = 'SET_DESTINATION_MAPPING',
  SET_ISLOADING = 'SET_ISLOADING',
  SET_EDIT = 'SET_EDIT',
  SET_ALERT = 'SET_ALERT',
  DEACTIVATE_DESTINATION_MAPPING = 'DEACTIVATE_DESTINATION_MAPPING',
}

type State = {
  destinationMapping: DestinationMappingModel;
  isLoading: boolean;
  edit: boolean;
  alert?: string;
};

export const initialState: State = {
  destinationMapping: {
    id: 0,
    destinationInfo: {
      destinationName: '',
      destinationCode: '',
      directAddress: '',
      faxNumber: '',
      oid: '',
    },
    kno2SenderConfigurationId: '',
    makeActiveUponCreation: false,
    isActive: false,
    createdOnUtc: new Date(),
    createdBy: '',
    modifiedOnUtc: new Date(),
    modifiedBy: '',
  },
  isLoading: true,
  edit: false,
};

type SetDestinationMapping = {
  type: typeof ActionTypes.SET_DESTINATION_MAPPING;
  destinationMapping: DestinationMappingModel;
};

type DeactivateDestinationMapping = {
  type: typeof ActionTypes.DEACTIVATE_DESTINATION_MAPPING;
};

type SetIsLoading = {
  type: typeof ActionTypes.SET_ISLOADING;
  isLoading: boolean;
};

type SetEdit = {
  type: typeof ActionTypes.SET_EDIT;
  edit: boolean;
};

type SetAlert = {
  type: typeof ActionTypes.SET_ALERT;
  alert?: string;
};

type ReducerActions = SetDestinationMapping | DeactivateDestinationMapping | SetIsLoading | SetEdit | SetAlert;

const setDestinationMapping = (destinationMapping: DestinationMappingModel): ReducerActions => ({
  type: ActionTypes.SET_DESTINATION_MAPPING,
  destinationMapping,
});

const deactivateDestinationMapping = (): ReducerActions => ({
  type: ActionTypes.DEACTIVATE_DESTINATION_MAPPING,
});

const setIsLoading = (isLoading: boolean): ReducerActions => ({
  type: ActionTypes.SET_ISLOADING,
  isLoading,
});

const setEdit = (edit: boolean): ReducerActions => ({
  type: ActionTypes.SET_EDIT,
  edit,
});

const setAlert = (alert?: string): ReducerActions => ({
  type: ActionTypes.SET_ALERT,
  alert,
});

export const reducer = (state: State, action: ReducerActions): State => {
  switch (action.type) {
    case ActionTypes.SET_DESTINATION_MAPPING:
      const { destinationMapping } = action;
      return {
        ...state,
        destinationMapping,
      };
    case ActionTypes.DEACTIVATE_DESTINATION_MAPPING:
      return {
        ...state,
        destinationMapping: {
          ...state.destinationMapping,
          isActive: false,
        },
      };
    case ActionTypes.SET_ISLOADING:
      const { isLoading } = action;
      return {
        ...state,
        isLoading,
      };
    case ActionTypes.SET_EDIT:
      const { edit } = action;
      return {
        ...state,
        edit,
      };
    case ActionTypes.SET_ALERT:
      const { alert } = action;
      return {
        ...state,
        alert,
      };
    default:
      return state;
  }
};

const DestinationMapping = (props: DestinationMappingProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { destinationMappingId } = useParams<{ destinationMappingId: string }>();
  const { kno2SenderConfigurationId } = useParams<{ kno2SenderConfigurationId: string }>();
  const apiClient = useApiClient();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setEdit(!!props.isCreate));
    const fetchData = async () => {
      if (destinationMappingId) {
        const destinationMappingResult =
          await apiClient.TransactionServiceClient.GetDestinationMappingById(destinationMappingId);
        if (destinationMappingResult) {
          dispatch(setDestinationMapping(destinationMappingResult));
        } else {
          dispatch(setAlert('Error occurred while fetching Destination Mapping details'));
        }
      }
      dispatch(setIsLoading(false));
    };

    fetchData();
  }, [apiClient.TransactionServiceClient, destinationMappingId, props.isCreate]);

  const handleEditClick = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(setEdit(true));
  };

  const handleDeactivateClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    try {
      await apiClient.TransactionServiceClient.DeactivateDestinationMapping(state.destinationMapping.id);
      dispatch(deactivateDestinationMapping());
    } catch (error) {
      dispatch(setAlert('Error occurred while deactivating Destination Mapping'));
    }
  };

  return (
    <div>
      <Row>
        <Col>
          <h1>{props.isCreate && 'Create '}Destination Mapping</h1>
        </Col>
      </Row>
      <hr />
      {state.isLoading ? (
        <ImageTrendForm.LoadingSpinner />
      ) : (
        <>
          <Formik
            enableReinitialize={true}
            initialValues={{ ...state.destinationMapping, kno2SenderConfigurationId: kno2SenderConfigurationId ?? '' }}
            validationSchema={destinationMappingSchema}
            onSubmit={async (values) => {
              try {
                if (props.isCreate) {
                  const id = await apiClient.TransactionServiceClient.PostDestinationMapping(values);
                  navigate(routes.destination_mappings_details.replace(':destinationMappingId', id.toString()));
                } else {
                  const updatedDestinationMapping = await apiClient.TransactionServiceClient.PutDestinationInfo(values);
                  dispatch(setEdit(false));
                  dispatch(setDestinationMapping(updatedDestinationMapping));
                }
              } catch (error) {
                dispatch(setAlert('Error occurred while saving Destination Mapping'));
              }
            }}
          >
            {({ handleSubmit, getFieldProps, isSubmitting }) => (
              <Form className="form-horizontal" onSubmit={handleSubmit}>
                <Alert show={!!state.alert} variant="danger" dismissible onClose={() => dispatch(setAlert())}>
                  {state.alert}
                </Alert>
                <ImageTrendForm.HiddenGroup controlId="kno2SenderConfigurationId">
                  <ImageTrendForm.Hidden defaultValue={kno2SenderConfigurationId} />
                </ImageTrendForm.HiddenGroup>
                <ImageTrendForm.Group controlId="destinationInfo.destinationName">
                  <ImageTrendForm.Label required>Destination Name</ImageTrendForm.Label>
                  <ImageTrendForm.Field isEditable={state.edit} name="destinationInfo.destinationName" />
                </ImageTrendForm.Group>
                <ImageTrendForm.Group controlId="destinationInfo.destinationCode">
                  <ImageTrendForm.Label required>Destination Code</ImageTrendForm.Label>
                  <ImageTrendForm.Field isEditable={state.edit} name="destinationInfo.destinationCode" />
                </ImageTrendForm.Group>
                <ImageTrendForm.Group controlId="destinationInfo.directAddress">
                  <ImageTrendForm.Label required>Direct Address</ImageTrendForm.Label>
                  <ImageTrendForm.Field isEditable={state.edit} name="destinationInfo.directAddress" />
                </ImageTrendForm.Group>
                <ImageTrendForm.Group controlId="destinationInfo.faxNumber">
                  <ImageTrendForm.Label>Fax Number</ImageTrendForm.Label>
                  <ImageTrendForm.Field isEditable={state.edit} name="destinationInfo.faxNumber" />
                </ImageTrendForm.Group>
                <ImageTrendForm.Group controlId="destinationInfo.oid">
                  <ImageTrendForm.Label required>OID</ImageTrendForm.Label>
                  <ImageTrendForm.Field isEditable={state.edit} name="destinationInfo.oid" />
                </ImageTrendForm.Group>
                {!!props.isCreate ? (
                  <>
                    <ImageTrendForm.Group controlId="makeActiveUponCreation">
                      <ImageTrendForm.Check
                        type="checkbox"
                        className="fw-bold"
                        label="Make Active Upon Creation"
                        {...getFieldProps('makeActiveUponCreation')}
                      />
                    </ImageTrendForm.Group>
                    <Button disabled={isSubmitting} type="submit">
                      <FaPlus /> Create
                    </Button>
                  </>
                ) : state.edit ? (
                  <div>
                    <Button disabled={isSubmitting} type="submit">
                      Save
                    </Button>
                    <Button
                      variant="danger"
                      onClick={handleDeactivateClick}
                      type="button"
                      style={{ marginLeft: '10px' }}
                    >
                      Deactivate
                    </Button>
                  </div>
                ) : (
                  <Button onClick={handleEditClick} type="button">
                    Edit
                  </Button>
                )}
                {!props.isCreate && (
                  <>
                    <hr />
                    <ImageTrendForm.Group controlId="isActive">
                      <ImageTrendForm.Label>Active</ImageTrendForm.Label>
                      <ImageTrendForm.Plaintext value={state.destinationMapping.isActive ? 'Yes' : 'No'} />
                    </ImageTrendForm.Group>
                    <ImageTrendForm.Group controlId="id">
                      <ImageTrendForm.Label>Id</ImageTrendForm.Label>
                      <ImageTrendForm.Plaintext value={state.destinationMapping.id} />
                    </ImageTrendForm.Group>
                    <ImageTrendForm.Group controlId="kno2SenderConfigurationId">
                      <ImageTrendForm.Label>Kno2 Sender Configuration Id</ImageTrendForm.Label>
                      <ImageTrendForm.Plaintext value={state.destinationMapping.kno2SenderConfigurationId} />
                    </ImageTrendForm.Group>
                    <ImageTrendForm.Group controlId="createdOnUtc">
                      <ImageTrendForm.Label>Created On</ImageTrendForm.Label>
                      <ImageTrendForm.Plaintext
                        value={dateTimeUtcToLocalString(state.destinationMapping.createdOnUtc)}
                      />
                    </ImageTrendForm.Group>
                    <ImageTrendForm.Group controlId="createdBy">
                      <ImageTrendForm.Label>Created By</ImageTrendForm.Label>
                      <ImageTrendForm.Plaintext value={state.destinationMapping.createdBy} />
                    </ImageTrendForm.Group>
                    <ImageTrendForm.Group controlId="modifiedOnUtc">
                      <ImageTrendForm.Label>Modified On</ImageTrendForm.Label>
                      <ImageTrendForm.Plaintext
                        value={dateTimeUtcToLocalString(state.destinationMapping.modifiedOnUtc)}
                      />
                    </ImageTrendForm.Group>
                    <ImageTrendForm.Group controlId="modifiedBy">
                      <ImageTrendForm.Label>Modified By</ImageTrendForm.Label>
                      <ImageTrendForm.Plaintext value={state.destinationMapping.modifiedBy} />
                    </ImageTrendForm.Group>
                  </>
                )}
              </Form>
            )}
          </Formik>
          <BackButton
            to={routes.destination_mappings}
            state={{
              kno2SenderConfigId: state.destinationMapping.kno2SenderConfigurationId,
            }}
          />
        </>
      )}
    </div>
  );
};

export default DestinationMapping;
