import { string, date, number, object } from 'yup';
import { PartialShape } from '../../Components/common/Schema';
import { Kno2IntegrationOrderFormModel } from '../../Models/TransactionService/Kno2IntegrationOrderFormModel';

const shape: PartialShape<Kno2IntegrationOrderFormModel> = {
  singleOrMultiAgency: string().label('Single or Multi Agency').required(),
  locationLegalEntityBusinessName: string().label('Location, Legal Entity, or Business Name').required(),
  orderDate: date().label('Order Date').required(),
  goLiveDate: date().label('Go Live Date').required(),
  invoicingMetric: string().label('Invoicing Metric').required(),
  metricQuantity: number().label('Metric Quantity').required(),
};

export const kno2IntegrationOrderFormSchema = object<Kno2IntegrationOrderFormModel>().shape(shape);
