import { IPublicClientApplication } from '@azure/msal-browser';
import { MutableRefObject } from 'react';
import { ImageTrendToastContainerRefProps } from '../../Components/common/ImageTrendToast';
import { createHihHelperApiClient } from './HihHelperApiClient';
import { createIngressServiceClient } from './IngressServiceApiClient';
import { createOutcomesServiceClient } from './OutcomesServiceApiClient';
import { createTransactionServiceClient } from './TransactionServiceApiClient';

export interface ApiClientProps {
  instance: IPublicClientApplication;
  toastRef: MutableRefObject<ImageTrendToastContainerRefProps | null>;
}

export function createApiClient(props: ApiClientProps) {
  const client = Object.freeze({
    HihHelperClient: createHihHelperApiClient(props),
    IngressServiceClient: createIngressServiceClient(props),
    OutcomesServiceClient: createOutcomesServiceClient(props),
    TransactionServiceClient: createTransactionServiceClient(props),
  });
  return client;
}

export type ApiClient = ReturnType<typeof createApiClient>;
