import { useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { useApiClient } from '../../API/useApiClient';
import ImageTrendForm from '../../Components/common/ImageTrendForm';
import { asYesOrNo } from '../../Helpers/BooleanHelper';
import { dateTimeUtcToLocalString } from '../../Helpers/DateTimeHelper';
import { Kno2StandardModuleModel } from '../../Models/TransactionService/Kno2StandardModuleModel';

enum ActionTypes {
  SET_KNO2_SENDER_CONFIGURATION = 'SET_KNO2_SENDER_CONFIGURATION',
  SET_ISLOADING = 'SET_ISLOADING',
}

type State = {
  kno2StandardModule: Kno2StandardModuleModel;
  isLoading: boolean;
};

export const initialState: State = {
  kno2StandardModule: {
    id: '',
    name: '',
    clientAccountId: '',
    organizationId: '',
    messageSubject: '',
    isActive: false,
    messageType: {
      name: '',
      value: 0,
    },
    eliteSite: {
      url: '',
      organization: '',
    },
    eliteIntegrationAccount: {
      privateKeyHexadecimal: '',
      token: '',
    },
    createdOnUtc: new Date(),
    createdBy: '',
    modifiedBy: '',
    pdfAttachmentDetail: {
      customDocumentType: '',
      nemsisFileTypeCode: '',
      convertAttachmentsToCda: false,
      messageType: {
        name: '',
        value: 0,
      },
      createdOnUtc: new Date(),
      createdBy: '',
      modifiedBy: '',
    },
    moduleAddress: {
      id: '',
      kno2SourceType: {
        name: '',
        value: 0,
      },
      fromAddress: '',
      defaultDestinationAddress: '',
      createdOnUtc: new Date(),
      createdBy: '',
      modifiedBy: '',
    },
  },
  isLoading: true,
};

type SetKno2SenderConfigurationAction = {
  type: typeof ActionTypes.SET_KNO2_SENDER_CONFIGURATION;
  kno2StandardModule: Kno2StandardModuleModel;
};

type SetIsLoading = {
  type: typeof ActionTypes.SET_ISLOADING;
  isLoading: boolean;
};

type ReducerActions = SetKno2SenderConfigurationAction | SetIsLoading;

const setKno2StandardConfig = (kno2StandardModule: Kno2StandardModuleModel): ReducerActions => ({
  type: ActionTypes.SET_KNO2_SENDER_CONFIGURATION,
  kno2StandardModule,
});

const setIsLoading = (isLoading: boolean): ReducerActions => ({
  type: ActionTypes.SET_ISLOADING,
  isLoading,
});

export const reducer = (state: State, action: ReducerActions): State => {
  switch (action.type) {
    case ActionTypes.SET_KNO2_SENDER_CONFIGURATION:
      const { kno2StandardModule } = action;
      return {
        ...state,
        kno2StandardModule,
      };
    case ActionTypes.SET_ISLOADING:
      const { isLoading } = action;
      return {
        ...state,
        isLoading,
      };
    default:
      return state;
  }
};

const Kno2StandardModuleSubview = (): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { moduleId } = useParams<{ moduleId: string }>();
  const apiClient = useApiClient();

  useEffect(() => {
    const fetchData = async () => {
      if (moduleId) {
        const kno2OutcomeUserResult = await apiClient.TransactionServiceClient.GetKno2ModuleById(moduleId);
        if (kno2OutcomeUserResult) {
          dispatch(setKno2StandardConfig(kno2OutcomeUserResult));
        }
      }
      dispatch(setIsLoading(false));
    };

    fetchData();
  }, [apiClient.TransactionServiceClient, apiClient.IngressServiceClient, moduleId]);

  return (
    <div>
      {state.isLoading ? (
        <ImageTrendForm.LoadingSpinner />
      ) : (
        <>
          <ImageTrendForm.Group controlId="name">
            <ImageTrendForm.Label>Name</ImageTrendForm.Label>
            <ImageTrendForm.Plaintext value={state.kno2StandardModule.name} />
          </ImageTrendForm.Group>
          <ImageTrendForm.Group controlId="isActive">
            <ImageTrendForm.Label>Active</ImageTrendForm.Label>
            <ImageTrendForm.Plaintext value={asYesOrNo(state.kno2StandardModule.isActive)} />
          </ImageTrendForm.Group>
          <ImageTrendForm.Group controlId="moduleAddress.organizationId">
            <ImageTrendForm.Label>Organization ID</ImageTrendForm.Label>
            <ImageTrendForm.Plaintext value={state.kno2StandardModule.organizationId} />
          </ImageTrendForm.Group>
          <ImageTrendForm.Group controlId="moduleAddress.messageSubject">
            <ImageTrendForm.Label>Message Subject</ImageTrendForm.Label>
            <ImageTrendForm.Plaintext value={state.kno2StandardModule.messageSubject} />
          </ImageTrendForm.Group>
          <ImageTrendForm.Group controlId="moduleAddress.eliteSite.organization">
            <ImageTrendForm.Label>Elite Site Organization</ImageTrendForm.Label>
            <ImageTrendForm.Plaintext value={state.kno2StandardModule.eliteSite.organization} />
          </ImageTrendForm.Group>
          <ImageTrendForm.Group controlId="moduleAddress.eliteSite.url">
            <ImageTrendForm.Label>Elite Site URL</ImageTrendForm.Label>
            <ImageTrendForm.Plaintext value={state.kno2StandardModule.eliteSite.url} />
          </ImageTrendForm.Group>
          <ImageTrendForm.Group controlId="moduleAddress.eliteIntegrationAccount.token">
            <ImageTrendForm.Label>Elite Integration Account Token</ImageTrendForm.Label>
            <ImageTrendForm.Plaintext value={state.kno2StandardModule.eliteIntegrationAccount.token} />
          </ImageTrendForm.Group>
          <ImageTrendForm.Group controlId="moduleAddress.eliteIntegrationAccount.privateKeyHexadecimal">
            <ImageTrendForm.Label>Elite Integration Account Private Key</ImageTrendForm.Label>
            <ImageTrendForm.Password
              value={`0x${state.kno2StandardModule.eliteIntegrationAccount.privateKeyHexadecimal}`}
            />
          </ImageTrendForm.Group>
          <ImageTrendForm.Group controlId="createdOn">
            <ImageTrendForm.Label>Created On</ImageTrendForm.Label>
            <ImageTrendForm.Plaintext value={dateTimeUtcToLocalString(state.kno2StandardModule.createdOnUtc)} />
          </ImageTrendForm.Group>
          <ImageTrendForm.Group controlId="createdBy">
            <ImageTrendForm.Label>Created By</ImageTrendForm.Label>
            <ImageTrendForm.Plaintext value={state.kno2StandardModule.createdBy} />
          </ImageTrendForm.Group>
          <hr />
          <h1>Module Address</h1>
          <ImageTrendForm.Group controlId="moduleAddress.fromAddress">
            <ImageTrendForm.Label>From Address</ImageTrendForm.Label>
            <ImageTrendForm.Plaintext value={state.kno2StandardModule.moduleAddress.fromAddress} />
          </ImageTrendForm.Group>
          <ImageTrendForm.Group controlId="moduleAddress.defaultDestinationAddress">
            <ImageTrendForm.Label>Default Destination Address</ImageTrendForm.Label>
            <ImageTrendForm.Plaintext value={state.kno2StandardModule.moduleAddress.defaultDestinationAddress} />
          </ImageTrendForm.Group>
          <ImageTrendForm.Group controlId="moduleAddress.kno2SourceType">
            <ImageTrendForm.Label>Kno2 Source Type</ImageTrendForm.Label>
            <ImageTrendForm.Plaintext value={state.kno2StandardModule.moduleAddress.kno2SourceType.name} />
          </ImageTrendForm.Group>
          {!!state.kno2StandardModule.pdfAttachmentDetail && (
            <>
              <hr />
              <h1>Attachment Detail</h1>
              <ImageTrendForm.Group controlId="pdfAttachmentDetail.messageType.name">
                <ImageTrendForm.Label>Message Type</ImageTrendForm.Label>
                <ImageTrendForm.Plaintext value={state.kno2StandardModule.pdfAttachmentDetail.messageType.name} />
              </ImageTrendForm.Group>
              <ImageTrendForm.Group controlId="pdfAttachmentDetail.customDocumentType">
                <ImageTrendForm.Label>Custom Document Type</ImageTrendForm.Label>
                <ImageTrendForm.Plaintext value={state.kno2StandardModule.pdfAttachmentDetail.customDocumentType} />
              </ImageTrendForm.Group>
              <ImageTrendForm.Group controlId="pdfAttachmentDetail.nemsisFileTypeCode">
                <ImageTrendForm.Label>NEMSIS File Type Code</ImageTrendForm.Label>
                <ImageTrendForm.Plaintext value={state.kno2StandardModule.pdfAttachmentDetail.nemsisFileTypeCode} />
              </ImageTrendForm.Group>
              <ImageTrendForm.Group controlId="pdfAttachmentDetail.convertAttachmentsToCda">
                <ImageTrendForm.Label>Convert Attachments To CDA</ImageTrendForm.Label>
                <ImageTrendForm.Plaintext
                  value={asYesOrNo(state.kno2StandardModule.pdfAttachmentDetail.convertAttachmentsToCda)}
                />
              </ImageTrendForm.Group>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Kno2StandardModuleSubview;
