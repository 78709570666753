import Pagination from 'react-bootstrap/Pagination';

interface PaginationProps {
  handlePreviousPage: () => void;
  handleNextPage: () => void;
  currentPage: number;
  totalPages: number;
}

const HihPagination = (props: PaginationProps) => {
  return (
    <div>
      <Pagination>
        <Pagination.Prev onClick={props.handlePreviousPage} disabled={props.currentPage === 1} />
        <Pagination.Item active={true}>
          Page {props.currentPage} of {props.totalPages}
        </Pagination.Item>
        <Pagination.Next
          onClick={props.handleNextPage}
          disabled={props.currentPage === props.totalPages || props.totalPages === 0}
        />
      </Pagination>
    </div>
  );
};

export default HihPagination;
