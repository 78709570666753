import { ErrorMessage, Field, FieldProps, useFormikContext } from 'formik';
import ImageTrendForm from '../common/ImageTrendForm';
import { ImageTrendOption, ImageTrendSingleSelect } from '../common/ImageTrendSelect';
import { Kno2WizardFormData } from './Kno2Wizard';

interface SelectFieldComponentProps<T> {
  name: string;
  label: string;
  options: ImageTrendOption<T>[];
  required?: boolean;
  onChange?: (selectedOption: ImageTrendOption<T> | null) => void;
}

const SelectFieldComponent = <T,>({ name, label, options, required, onChange }: SelectFieldComponentProps<T>) => {
  const { setFieldValue, setFieldTouched, errors, touched } = useFormikContext<Kno2WizardFormData>();

  const handleSelectChange = (newValue: unknown) => {
    const selectedOption = newValue as ImageTrendOption<T> | null;
    setFieldValue(name, selectedOption ? selectedOption.value : '');
    setFieldTouched(name, true);

    if (onChange) {
      onChange(selectedOption);
    }
  };

  const nameParts = name.split('.');
  const error = nameParts.length > 1 ? (errors as any)[nameParts[0]]?.[nameParts[1]] : (errors as any)[name];
  const touch = nameParts.length > 1 ? (touched as any)[nameParts[0]]?.[nameParts[1]] : (touched as any)[name];

  return (
    <ImageTrendForm.NormalGroup controlId={name}>
      <ImageTrendForm.Label required={required}>{label}</ImageTrendForm.Label>
      <Field name={name}>
        {({ field }: FieldProps) => (
          <ImageTrendSingleSelect
            id={`${name}-container`}
            inputId={name}
            value={options.find((option) => option.value === field.value)}
            onChange={handleSelectChange}
            options={options}
            isInvalid={error && touch}
          />
        )}
      </Field>
      <ErrorMessage name={name} component="div" className="invalid-feedback" />
    </ImageTrendForm.NormalGroup>
  );
};

export default SelectFieldComponent;
