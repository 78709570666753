import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import Table from 'react-bootstrap/Table';

interface ImageTrendTableProps<T> {
  columns: ColumnDef<T>[];
  data: T[];
  showFooter?: boolean;
  striped?: boolean;
  tableClassName?: string;
}

const ImageTrendTable = <T,>({
  columns,
  data,
  showFooter = false,
  striped = false,
  tableClassName = '',
}: ImageTrendTableProps<T>) => {
  const table = useReactTable<T>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Table className={tableClassName} striped={striped}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id} className="bg-it-white">
                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
            ))}
          </tr>
        ))}
      </tbody>
      {showFooter && (
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id} className="bg-it-white">
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      )}
    </Table>
  );
};

export default ImageTrendTable;
