import { Form, Formik, FormikErrors, FormikTouched } from 'formik';
import React, { useContext, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../../API/appContext';
import '../../Styling/dataEntry.scss';
import { PartialShape } from '../common/Schema';
import { CreateKno2ModuleModel } from './Kno2Module.types';
import { NemsisFileTypeCodeEnum } from './NemsisValues';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import SubmissionResults from './SubmissionResults';
import { number, object, string } from 'yup';

export interface Kno2WizardFormData {
  module: CreateKno2ModuleModel;
  nemsisConfig: {
    name: string;
    nemsisDataSchema: number | string;
    nemsisSchemaVersion: number | string;
    transactionServiceModuleId: string;
    transactionServiceModuleType: string;
  };
}

const validationSchema = object().shape({
  module: object().shape<PartialShape<CreateKno2ModuleModel>>({
    moduleWorkerType: number().label('Module Type').required(),
    name: string().label('Module Name').required(),
    organizationId: string().label('Organization ID').required(),
    messageSubject: string().label('Message Subject').required(),
    eliteSite: object().shape({
      url: string().label('Elite URL').required().url(),
      organization: string().label('Elite Organization').required(),
    }),
    eliteIntegrationAccount: object().shape({
      token: string().label('Integration Account Token').required(),
      hexPrivateKey: string().label('Integration Account Private Key Hexadecimal').required().hexadecimal(),
    }),
    kno2ModuleAddress: object().shape({
      kno2SourceType: string().label('Source Type').required(),
      fromAddress: string().label('From Address').required(),
      defaultDestinationAddress: string().label('Default Destination Address'),
    }),
    pdfAttachmentDetail: object().shape({
      customDocumentType: string().label('Custom Document Type').required(),
      nemsisFileTypeCode: string().label('NEMSIS File Type Code').required(),
    }),
  }),
  nemsisConfig: object().shape({
    name: string().label('Configuration Name').required(),
    nemsisDataSchema: string().label('NEMSIS Data Schema').required(),
    nemsisSchemaVersion: string().label('NEMSIS Schema Version').required(),
    transactionServiceModuleType: string().label('Transaction Service Module Type').required(),
  }),
});

const Kno2Wizard: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [alert, setAlert] = useState<string[]>([]);
  const location = useLocation();
  const navigate = useNavigate();
  // @TODO: >:(
  const { clientAccount } = location.state as { clientAccount: { id: string } };

  const appContext = useContext(AppContext);

  if (!appContext) {
    throw new Error('AppContext is not provided');
  }

  const { apiClient } = appContext;

  const flattenErrors = (data: object): string[] => {
    const errors = Object.values(data).flat();
    if (errors.every((_) => typeof _ === 'string')) {
      return errors;
    }
    // In case we get weird validation errors related to not having proper formatting.
    return errors
      .filter((_) => typeof _ === 'object')
      .map(
        (_) =>
          Object.values(_)
            .flat()
            .filter((_) => typeof _ === 'string') as string[],
      )
      .flat();
  };

  const handleSubmit = async (values: Kno2WizardFormData) => {
    try {
      // Validate Kno2Module and NemsisConfiguration
      const moduleValidation = apiClient.TransactionServiceClient.ValidateCreateKno2Module(values.module);
      const configValidation = apiClient.IngressServiceClient.ValidateNemsisConfiguration({
        ...values.nemsisConfig,
        transactionServiceModuleId: '00000000-0000-0000-0000-000000000000',
      });

      const validationErrors = Array.of(
        ...flattenErrors((await moduleValidation).data),
        ...flattenErrors((await configValidation).data),
      );
      if (validationErrors.length > 0) {
        setAlert(validationErrors);
        return;
      }

      // Submit Kno2Module data
      const response = await apiClient.TransactionServiceClient.PostKno2Module(values.module);

      const moduleId = response.data;

      values.nemsisConfig.transactionServiceModuleId = moduleId;

      // @TODO: We need a signing procedure, we shouldn't allow people to send data here
      // without some verification, just in case (especially since it's not the same service.)
      const nemsisResponse = await apiClient.IngressServiceClient.PostNemsisConfiguration(values.nemsisConfig);

      navigate('/submission-results', {
        state: {
          nemsisResponse,
          error: null,
        },
      });
    } catch (error: any) {
      console.error('Error creating configurations:', error);
    }
  };

  const validateCurrentStep = async (
    currentStep: number,
    validateForm: () => Promise<FormikErrors<Kno2WizardFormData>>,
    setErrors: (errors: FormikErrors<Kno2WizardFormData>) => void,
    setTouched: (touched: FormikTouched<Kno2WizardFormData>) => void,
    values: Kno2WizardFormData,
  ) => {
    const stepFields = [
      ['module.moduleWorkerType', 'module.name'], // step 1 Probably keep these on their respective pages, easier to maintain
      [
        'module.organizationId',
        'module.kno2ModuleAddress.kno2SourceType',
        'module.kno2ModuleAddress.fromAddress',
        'module.kno2ModuleAddress.defaultDestinationAddress',
        'module.messageSubject',
        'module.eliteSite.url',
        'module.eliteSite.organization',
        'module.eliteIntegrationAccount.token',
        'module.eliteIntegrationAccount.hexPrivateKey',
      ], // step 2
      ['module.pdfAttachmentDetail.customDocumentType', 'module.pdfAttachmentDetail.nemsisFileTypeCode'], // step 3
      [
        'nemsisConfig.name',
        'nemsisConfig.nemsisDataSchema',
        'nemsisConfig.nemsisSchemaVersion',
        'nemsisConfig.transactionServiceModuleType',
      ], // step 4
    ];

    const fieldsToValidate = stepFields[currentStep];
    const errors = await validateForm();
    const touchedFields: FormikTouched<Kno2WizardFormData> = {} as FormikTouched<Kno2WizardFormData>;

    fieldsToValidate.forEach((field) => {
      const [section, key] = field.split('.') as [keyof Kno2WizardFormData, string];
      if (!touchedFields[section]) {
        (touchedFields as any)[section] = {};
      }
      (touchedFields as any)[section][key] = true;
    });

    setTouched(touchedFields);
    setErrors(errors);

    const stepErrors = fieldsToValidate.reduce((acc, field) => {
      const [section, key] = field.split('.') as [keyof Kno2WizardFormData, string];
      if (errors[section] && (errors[section] as any)[key]) {
        if (!acc[section]) {
          (acc as any)[section] = {};
        }
        (acc as any)[section][key] = (errors[section] as any)[key];
      }
      return acc;
    }, {} as FormikErrors<Kno2WizardFormData>);

    if (Object.keys(stepErrors).length === 0) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  return (
    <Formik<Kno2WizardFormData>
      initialValues={{
        module: {
          name: '',
          clientAccountId: clientAccount.id,
          organizationId: '',
          messageSubject: '',
          eliteSite: {
            url: '',
            organization: '',
          },
          eliteIntegrationAccount: {
            token: '',
            hexPrivateKey: '',
          },
          pdfAttachmentDetail: {
            customDocumentType: '',
            convertAttachmentToCda: false,
            nemsisFileTypeCode: NemsisFileTypeCodeEnum.OtherHealthcareRecord,
          },
          kno2ModuleAddress: {
            sourceType: 0,
            fromAddress: '',
            defaultDestinationAddress: '',
          },
        },
        nemsisConfig: {
          name: '',
          nemsisDataSchema: '',
          nemsisSchemaVersion: '',
          transactionServiceModuleId: '',
          transactionServiceModuleType: '',
        },
      }}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values, setFieldValue, validateForm, setErrors, setTouched }) => (
        <Form className="wizard-container">
          <div className="step-container">
            <>
              {alert.length > 0 && (
                <Alert variant="danger" dismissible>
                  <ul className="mb-0">
                    {alert.map((_) => (
                      <li>{_}</li>
                    ))}
                  </ul>
                </Alert>
              )}
            </>
            {currentStep === 0 && <Step1 formData={values} setFieldValue={setFieldValue} />}
            {currentStep === 1 && <Step2 formData={values} setFieldValue={setFieldValue} />}
            {currentStep === 2 && <Step3 formData={values} setFieldValue={setFieldValue} />}
            {currentStep === 3 && <Step4 formData={values} setFieldValue={setFieldValue} />}
            {
              // @TODO: Fix this.  This doesn't get used and bloats the page.
            }
            {currentStep === 4 && <SubmissionResults />}
            <div className="mt-3 d-flex justify-content-between">
              {currentStep === 0 && (
                <Button variant="secondary" onClick={() => navigate(-1)}>
                  Cancel
                </Button>
              )}
              {currentStep > 0 && currentStep < 4 && (
                <Button variant="secondary" onClick={() => setCurrentStep(currentStep - 1)}>
                  Previous
                </Button>
              )}
              {currentStep < 3 && (
                <Button
                  variant="primary"
                  onClick={() => validateCurrentStep(currentStep, validateForm, setErrors, setTouched, values)}
                >
                  Next
                </Button>
              )}
              {currentStep === 3 && (
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  Submit
                </Button>
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Kno2Wizard;
