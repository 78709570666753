import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import { Outlet } from "react-router";
import { IsAccountSuperAdmin } from "./RoleHelper";

const RequireSuperAdminRoleRoute = () => {
    const {instance} = useMsal();
    const [userIsSuperAdmin, setUserIsSuperAdmin] = useState(false);
    
    useEffect(() => {
      const activeAccount = instance.getActiveAccount();
      const hasAdminRole = !!activeAccount && IsAccountSuperAdmin(activeAccount);
      if (hasAdminRole !== userIsSuperAdmin) {
        setUserIsSuperAdmin(hasAdminRole);
      }
    }, [instance, userIsSuperAdmin, setUserIsSuperAdmin]);

    if (userIsSuperAdmin) {
      return <Outlet />;
    }
  
    return <Alert variant="warning">Access Denied</Alert>;
  };
  
  export default RequireSuperAdminRoleRoute;
  