export enum NemsisFileTypeCodeEnum {
  OtherAudioRecording = 4509001,
  BillingInformation = 4509003,
  DiagnosticImage = 4509005,
  DNR = 4509006,
  LivingWill = 4509008,
  ECG = 4509009,
  Guardianship = 4509011,
  OtherHealthcareRecord = 4509013,
  Other = 4509015,
  PatientIdentification = 4509017,
  PatientRefusalSheet = 4509019,
  OtherPicture = 4509021,
  OtherVideo = 4509025,
  ePCR = 4509027,
} 

interface NemsisFileTypeCodeValue {
  label: string;
  value: number;
}

export const NemsisFileTypeCode: {
  [key in NemsisFileTypeCodeEnum]: NemsisFileTypeCodeValue;
} = {
  [NemsisFileTypeCodeEnum.OtherAudioRecording]: {
    label: '(4509001) Other Audio Recording',
    value: NemsisFileTypeCodeEnum.OtherAudioRecording,
  },
  [NemsisFileTypeCodeEnum.BillingInformation]: {
    label: '(4509003) Billing Information',
    value: NemsisFileTypeCodeEnum.BillingInformation,
  },
  [NemsisFileTypeCodeEnum.DiagnosticImage]: {
    label: '(4509005) Diagnostic Image (CT, X-ray, US, etc.)',
    value: NemsisFileTypeCodeEnum.DiagnosticImage,
  },
  [NemsisFileTypeCodeEnum.DNR]: {
    label: '(4509006) DNR',
    value: NemsisFileTypeCodeEnum.DNR,
  },
  [NemsisFileTypeCodeEnum.LivingWill]: {
    label: '(4509008) Living Will',
    value: NemsisFileTypeCodeEnum.LivingWill,
  },
  [NemsisFileTypeCodeEnum.ECG]: {
    label: '(4509009) ECG/Lab Results',
    value: NemsisFileTypeCodeEnum.ECG,
  },
  [NemsisFileTypeCodeEnum.Guardianship]: {
    label: '(4509011) Guardianship/Power of Attorney',
    value: NemsisFileTypeCodeEnum.Guardianship,
  },
  [NemsisFileTypeCodeEnum.OtherHealthcareRecord]: {
    label: '(4509013) Other Healthcare Record',
    value: NemsisFileTypeCodeEnum.OtherHealthcareRecord,
  },
  [NemsisFileTypeCodeEnum.Other]: {
    label: '(4509015) Other',
    value: NemsisFileTypeCodeEnum.Other,
  },
  [NemsisFileTypeCodeEnum.PatientIdentification]: {
    label: '(4509017) Patient Identification',
    value: NemsisFileTypeCodeEnum.PatientIdentification,
  },
  [NemsisFileTypeCodeEnum.PatientRefusalSheet]: {
    label: '(4509019) Patient Refusal Sheet',
    value: NemsisFileTypeCodeEnum.PatientRefusalSheet,
  },
  [NemsisFileTypeCodeEnum.OtherPicture]: {
    label: '(4509021) Other Picture/Graphic',
    value: NemsisFileTypeCodeEnum.OtherPicture,
  },
  [NemsisFileTypeCodeEnum.OtherVideo]: {
    label: '(4509025) Other Video/Movie',
    value: NemsisFileTypeCodeEnum.OtherVideo,
  },
  [NemsisFileTypeCodeEnum.ePCR]: {
    label: '(4509027) ePCR',
    value: NemsisFileTypeCodeEnum.ePCR,
  },
};
