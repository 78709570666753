import { Formik } from 'formik';
import { useEffect, useReducer } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { FaArrowLeft, FaPlus } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useApiClient } from '../../API/useApiClient';
import ImageTrendForm from '../../Components/common/ImageTrendForm';
import { dateTimeUtcToLocalString } from '../../Helpers/DateTimeHelper';
import { ClientAccountModel } from '../../Models/HihClient/ClientAccountModel';
import { Kno2IntegrationModel } from '../../Models/HihClient/Kno2IntegrationModel';
import { Kno2OutcomeUserModel } from '../../Models/OutcomesService/Kno2OutcomeUser';
import routes from '../../common/routesDefinitions';
import Kno2StandardModuleList from '../Kno2Modules/Kno2StandardModuleList';
import { clientAccountSchema } from './ClientAccount.types';

interface ClientAccountProps {
  isCreate?: boolean;
}

enum ActionTypes {
  SET_CLIENT_ACCOUNT = 'SET_CLIENT_ACCOUNT',
  SET_KNO2_INTEGRATION = 'SET_KNO2_INTEGRATION',
  SET_KNO2_OUTCOME_USER = 'SET_KNO2_OUTCOME_USER',
  SET_ISLOADING = 'SET_ISLOADING',
  SET_EDIT = 'SET_EDIT',
  SET_ALERT = 'SET_ALERT',
}

type State = {
  clientAccount: ClientAccountModel;
  kno2Integration?: Kno2IntegrationModel;
  kno2OutcomeUser?: Kno2OutcomeUserModel;
  isLoading: boolean;
  edit: boolean;
  alert?: string;
};

export const initialState: State = {
  clientAccount: {
    id: '',
    name: '',
    ticketNumber: 0,
    customerId: 0,
    createdOnUtc: new Date(),
  },
  isLoading: true,
  edit: false,
};

type SetClientAccountAction = {
  type: typeof ActionTypes.SET_CLIENT_ACCOUNT;
  clientAccount: ClientAccountModel;
};

type SetKno2IntegrationAction = {
  type: typeof ActionTypes.SET_KNO2_INTEGRATION;
  kno2Integration?: Kno2IntegrationModel;
};

type SetKno2OutcomeUserAction = {
  type: typeof ActionTypes.SET_KNO2_OUTCOME_USER;
  kno2OutcomeUser?: Kno2OutcomeUserModel;
};

type SetIsLoading = {
  type: typeof ActionTypes.SET_ISLOADING;
  isLoading: boolean;
};

type SetEdit = {
  type: typeof ActionTypes.SET_EDIT;
  edit: boolean;
};

type SetAlert = {
  type: typeof ActionTypes.SET_ALERT;
  alert?: string;
};

type ReducerActions =
  | SetClientAccountAction
  | SetKno2IntegrationAction
  | SetKno2OutcomeUserAction
  | SetIsLoading
  | SetEdit
  | SetAlert;

const setClientAccount = (clientAccount: ClientAccountModel): ReducerActions => ({
  type: ActionTypes.SET_CLIENT_ACCOUNT,
  clientAccount,
});

const setKno2Integration = (kno2Integration?: Kno2IntegrationModel): ReducerActions => ({
  type: ActionTypes.SET_KNO2_INTEGRATION,
  kno2Integration,
});

const setKno2OutcomeUser = (kno2OutcomeUser?: Kno2OutcomeUserModel): ReducerActions => ({
  type: ActionTypes.SET_KNO2_OUTCOME_USER,
  kno2OutcomeUser,
});

const setIsLoading = (isLoading: boolean): ReducerActions => ({
  type: ActionTypes.SET_ISLOADING,
  isLoading,
});

const setEdit = (edit: boolean): ReducerActions => ({
  type: ActionTypes.SET_EDIT,
  edit,
});

const setAlert = (alert?: string): ReducerActions => ({
  type: ActionTypes.SET_ALERT,
  alert,
});

export const reducer = (state: State, action: ReducerActions): State => {
  switch (action.type) {
    case ActionTypes.SET_CLIENT_ACCOUNT:
      const { clientAccount } = action;
      return {
        ...state,
        clientAccount,
      };
    case ActionTypes.SET_KNO2_INTEGRATION:
      const { kno2Integration } = action;
      return {
        ...state,
        kno2Integration,
      };
    case ActionTypes.SET_KNO2_OUTCOME_USER:
      const { kno2OutcomeUser } = action;
      return {
        ...state,
        kno2OutcomeUser,
      };
    case ActionTypes.SET_ISLOADING:
      const { isLoading } = action;
      return {
        ...state,
        isLoading,
      };
    case ActionTypes.SET_EDIT:
      const { edit } = action;
      return {
        ...state,
        edit,
      };
    case ActionTypes.SET_ALERT:
      const { alert } = action;
      return {
        ...state,
        alert,
      };
    default:
      return state;
  }
};

const ClientAccount = (props: ClientAccountProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { id } = useParams<{ id: string }>();
  const apiClient = useApiClient();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setEdit(!!props.isCreate));
    const fetchData = async () => {
      if (id) {
        const clientAccountResult = await apiClient.HihHelperClient.GetClientAccount(id);
        if (clientAccountResult) {
          dispatch(setClientAccount(clientAccountResult));

          const kno2IntegrationResult = await apiClient.HihHelperClient.GetKno2IntegrationByClientId(
            clientAccountResult.id,
          );
          dispatch(setKno2Integration(kno2IntegrationResult || undefined));

          const kno2OutcomeUserResult = await apiClient.OutcomesServiceClient.GetOutcomeUserByClientAccountId(
            clientAccountResult.id,
          );
          dispatch(setKno2OutcomeUser(kno2OutcomeUserResult || undefined));
        } else {
          // @TODO: What if we go to a bad route?
          //navigate(routes.accounts);
        }
      }
      dispatch(setIsLoading(false));
    };

    fetchData();
  }, [apiClient.HihHelperClient, apiClient.OutcomesServiceClient, id, props.isCreate]);

  const handleStartWizard = () => {
    if (state.kno2Integration && state.clientAccount) {
      // @TODO: DO NOT SEND THROUGH STATE HERE!!!
      // We should ALWAYS grab the information on the page unless it's following info given to the page like the wizard
      // (e.g. Step 1 -> Step 2, in which case it's doable, though we should be using a different method there.)
      // By sending through state, we cannot bookmark this link.  For the wizard, this makes sense, since form
      // info changes.  However, at the start of the wizard, this information shouldn't be imported from this
      // page, since we could use that link from other pages.
      navigate(routes.kno2_wizard, {
        state: { kno2Integration: state.kno2Integration, clientAccount: state.clientAccount },
      });
    }
  };

  const handleEditClick = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(setEdit(true));
  };

  return (
    <div>
      <Row>
        <Col>
          <h1>{props.isCreate && 'Create '}Client Account</h1>
        </Col>
      </Row>
      <hr />
      {state.isLoading ? (
        <ImageTrendForm.LoadingSpinner />
      ) : (
        <>
          <Formik
            enableReinitialize={true}
            initialValues={state.clientAccount}
            validationSchema={clientAccountSchema}
            onSubmit={async (values) => {
              try {
                if (props.isCreate) {
                  await apiClient.HihHelperClient.PostClientAccount(values);
                  navigate(routes.client_accounts);
                } else {
                  await apiClient.HihHelperClient.PutClientAccount(values);
                  dispatch(setEdit(false));
                }
              } catch (error) {
                dispatch(setAlert('Error occurred while saving Client Account.'));
              }
            }}
          >
            {({ handleSubmit, isSubmitting }) => (
              <Form className="form-horizontal" onSubmit={handleSubmit}>
                <Alert show={!!state.alert} variant="danger" dismissible onClose={() => dispatch(setAlert())}>
                  {state.alert}
                </Alert>
                <ImageTrendForm.Group controlId="name">
                  <ImageTrendForm.Label required>Name</ImageTrendForm.Label>
                  <ImageTrendForm.Field isEditable={state.edit} name="name" />
                </ImageTrendForm.Group>
                <ImageTrendForm.Group controlId="ticketNumber">
                  <ImageTrendForm.Label required>Ticket Number</ImageTrendForm.Label>
                  <ImageTrendForm.Field isEditable={state.edit} name="ticketNumber" />
                </ImageTrendForm.Group>
                <ImageTrendForm.Group controlId="customerId">
                  <ImageTrendForm.Label required>Customer ID</ImageTrendForm.Label>
                  <ImageTrendForm.Field isEditable={state.edit} name="customerId" />
                </ImageTrendForm.Group>
                {!props.isCreate && (
                  <>
                    <ImageTrendForm.Group controlId="createdOn">
                      <ImageTrendForm.Label>Created On</ImageTrendForm.Label>
                      <ImageTrendForm.Plaintext value={dateTimeUtcToLocalString(state.clientAccount.createdOnUtc)} />
                    </ImageTrendForm.Group>
                  </>
                )}
                {!!props.isCreate ? (
                  <Button disabled={isSubmitting} type="submit">
                    <FaPlus /> Create
                  </Button>
                ) : state.edit ? (
                  <Button disabled={isSubmitting} type="submit">
                    Save
                  </Button>
                ) : (
                  <Button onClick={handleEditClick} type="button">
                    Edit
                  </Button>
                )}
              </Form>
            )}
          </Formik>
          {!props.isCreate && !!state.clientAccount.id && (
            <>
              <hr />
              <h2>Kno2 Integration</h2>
              {!!state.kno2Integration ? (
                <>
                  <Form>
                    <ImageTrendForm.Group>
                      <ImageTrendForm.Label>Organization</ImageTrendForm.Label>
                      <ImageTrendForm.Plaintext
                        linkTo={`${routes.kno2_integration_details.replace(':kno2IntegrationId', state.kno2Integration.id ?? '')}`}
                        value={state.kno2Integration.organizationName}
                      />
                    </ImageTrendForm.Group>
                    <Form.Group as={Row} className="mb-3" controlId="integrationCreatedOn">
                      <Form.Label column sm="2" className="fw-bold">
                        Created On
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control
                          plaintext
                          readOnly
                          defaultValue={dateTimeUtcToLocalString(state.kno2Integration?.createdOnUtc ?? '')}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="status">
                      <Form.Label column sm="2" className="fw-bold">
                        Status
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control
                          plaintext
                          readOnly
                          defaultValue={state.kno2Integration.grantedUseForAccessWithKno2 ? 'Active' : 'Inactive'}
                        />
                      </Col>
                    </Form.Group>
                  </Form>
                  <hr />
                  <Kno2StandardModuleList clientAccountId={state.clientAccount.id} />
                  <div className="mb-3">
                    <Button variant="primary" onClick={handleStartWizard}>
                      <FaPlus /> Add Kno2 Module
                    </Button>
                  </div>
                </>
              ) : (
                <Row className="mb-3">
                  <Col sm="12">
                    <div className="form-control-plaintext">
                      <Link to={routes.kno2_integration_create.replace(':clientId', state.clientAccount?.id ?? '')}>
                        <Button variant="primary">
                          <FaPlus /> Create
                        </Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              )}
              <hr />
              <h2>Outcome User</h2>
              <Form>
                {
                  // @TODO: Change to a list of outcome users.
                }
                {!!state.kno2OutcomeUser ? (
                  <>
                    <ImageTrendForm.Group controlId="outcomeEliteSiteOrganization">
                      <ImageTrendForm.Label>Elite Site Organization</ImageTrendForm.Label>
                      <ImageTrendForm.Plaintext
                        linkTo={`${routes.kno2_outcome_users_view.replace(':clientId', state.clientAccount.id)}`}
                        value={state.kno2OutcomeUser.eliteSiteOrganization}
                      />
                    </ImageTrendForm.Group>
                    <ImageTrendForm.Group controlId="outcomeCreatedOnUtc">
                      <ImageTrendForm.Label>Created On</ImageTrendForm.Label>
                      <ImageTrendForm.Plaintext value={dateTimeUtcToLocalString(state.kno2OutcomeUser.createdOnUtc)} />
                    </ImageTrendForm.Group>
                  </>
                ) : (
                  <Row className="mb-3">
                    <Col>
                      <Link to={routes.kno2_outcome_users_create.replace(':clientId', state.clientAccount.id)}>
                        <Button variant="primary">
                          <FaPlus /> Create
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                )}
              </Form>
            </>
          )}
          <hr />
          <Link to={routes.client_accounts}>
            <Button variant="secondary">
              <FaArrowLeft /> Back
            </Button>
          </Link>
        </>
      )}
    </div>
  );
};

export default ClientAccount;
