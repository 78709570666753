import React from 'react';
import Button from 'react-bootstrap/Button';
import FormGroup from 'react-bootstrap/FormGroup';
import { Link, useLocation } from 'react-router-dom';
import routes from '../../common/routesDefinitions';

interface SubmissionResponse {
  nemsisResponse: any;
  error: string | null | { [key: string]: any };
}

const SubmissionResults: React.FC = () => {
  const location = useLocation();
  const { nemsisResponse, error } = location.state as SubmissionResponse;

  return (
    <div className="wizard-container">
      <div className="step-container">
        <h2>Submission Results</h2>
        {error ? (
          <div className="alert alert-danger">
            <h4>Submission failed</h4>
            {typeof error === 'string' ? <p>{error}</p> : <pre>{JSON.stringify(error, null, 2)}</pre>}
          </div>
        ) : (
          <>
            <FormGroup>
              <h4>NEMSIS Response</h4>
              <pre>{JSON.stringify(nemsisResponse, null, 2)}</pre>
            </FormGroup>
            <div className="alert alert-success">
              <p>Form submitted successfully!</p>
              <p>Record Username and Password from the Nemsis Response for future use.</p>
            </div>
          </>
        )}
        <Link to={routes.accounts}>
          <Button>Go to Accounts</Button>
        </Link>
      </div>
    </div>
  );
};

export default SubmissionResults;
