import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleQuestion, faCircleXmark, faClock, faEnvelope, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export enum Kno2TransactionStatusEnum {
  Unknown = 0,
  Processing = 1,
  CompleteSuccessfulDelivery = 2,
  Errored = 3,
  CompleteAwaitingStatus = 4,
  CompleteFailedDelivery = 5,
}

interface Kno2TransactionStatusValue {
  name: string;
  color?: string;
  icon?: IconProp;
  cornerIcon?: IconProp;
}

export const Kno2TransactionStatus: {
  [key in Kno2TransactionStatusEnum]: Kno2TransactionStatusValue;
} = {
  [Kno2TransactionStatusEnum.Unknown]: {
    name: 'Unknown',
    icon: faCircleQuestion,
  },
  [Kno2TransactionStatusEnum.Processing]: {
    name: 'Transaction is currently being processed',
    icon: faSpinner,
  },
  [Kno2TransactionStatusEnum.CompleteSuccessfulDelivery]: {
    name: 'Transaction completed with successful message delivery',
    color: 'green',
    icon: faEnvelope,
  },
  [Kno2TransactionStatusEnum.Errored]: {
    name: 'Transaction has an error to diagnose',
    color: 'red',
    icon: faCircleXmark,
  },
  [Kno2TransactionStatusEnum.CompleteAwaitingStatus]: {
    name: 'Transaction has processed and is awaiting a status for the message sent',
    icon: faClock,
  },
  [Kno2TransactionStatusEnum.CompleteFailedDelivery]: {
    name: 'Transaction completed with failed message delivery',
    color: 'red',
    cornerIcon: faCircleXmark,
  },
};

interface Kno2StatusIconProps {
  statusCode: Kno2TransactionStatusEnum;
}

const Kno2StatusIcon = ({ statusCode }: Kno2StatusIconProps) => {
  const status = Kno2TransactionStatus[statusCode];
  if (status.cornerIcon) {
    return (
      <>
        <span className="fa-layers fa-fw fa-2x">
          <FontAwesomeIcon icon={faEnvelope} />
          <FontAwesomeIcon
            icon={status.cornerIcon}
            color={status.color}
            title={status.name}
            transform={'shrink-10 up-4 right-6'}
          />
        </span>
      </>
    );
  }
  return (
    <>
      <FontAwesomeIcon className="fa-fw" size="2x" icon={status.icon!} color={status.color} title={status.name} />
    </>
  );
};

export default Kno2StatusIcon;
