import React from 'react';
import ImageTrendForm from '../common/ImageTrendForm';
import { Kno2WizardFormData } from './Kno2Wizard';
import { NemsisFileTypeCode, NemsisFileTypeCodeEnum } from './NemsisValues';

interface StepProps {
  formData: Kno2WizardFormData;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const Step3: React.FC<StepProps> = ({ formData }) => {
  return (
    <div>
      <h2>Step 3: Kno2 Attachment Configuration</h2>

      <ImageTrendForm.NormalGroup controlId="module.pdfAttachmentDetail.customDocumentType">
        <ImageTrendForm.Label required>Custom Document Type</ImageTrendForm.Label>
        <ImageTrendForm.Field name="module.pdfAttachmentDetail.customDocumentType" isEditable={true} />
      </ImageTrendForm.NormalGroup>

      <ImageTrendForm.NormalGroup controlId="module.pdfAttachmentDetail.convertAttachmentsToCda">
        <ImageTrendForm.Label>Convert Attachments to CDA</ImageTrendForm.Label>
        <ImageTrendForm.SwitchField name="module.pdfAttachmentDetail.convertAttachmentsToCda" />
      </ImageTrendForm.NormalGroup>

      <ImageTrendForm.NormalGroup controlId="module.pdfAttachmentDetail.nemsisFileTypeCode">
        <ImageTrendForm.Label required>NEMSIS File Type Code</ImageTrendForm.Label>
        <ImageTrendForm.CreatableSelect
          name="module.pdfAttachmentDetail.nemsisFileTypeCode"
          isEditable={true}
          options={[
            { label: 'Common', options: [NemsisFileTypeCode[4509013]] },
            {
              label: 'Values',
              options: Object.values(NemsisFileTypeCode).filter(
                (_) => _.value !== NemsisFileTypeCodeEnum.OtherHealthcareRecord,
              ),
            },
          ]}
        />
      </ImageTrendForm.NormalGroup>
    </div>
  );
};

export default Step3;
