import React from 'react';
import Button, { ButtonProps } from 'react-bootstrap/Button';
import { FaArrowLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';

type BackButtonProps = {
  to: string;
  state?: any;
} & ButtonProps;

const BackButton: React.FC<BackButtonProps> = ({ to, state, ...props }) => {
  return (
    <Link to={to} state={state}>
      <Button variant="secondary" {...props}>
        <FaArrowLeft /> Back
      </Button>
    </Link>
  );
};

export default BackButton;
