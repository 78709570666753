import Button from 'react-bootstrap/Button';
import '../../Styling/login.css';
import Card from 'react-bootstrap/Card';

interface LoginPageProps {
  handleSubmit: () => void;
}

const LoginPage = ({ handleSubmit }: LoginPageProps) => {
  return (
    <>
      <div className="Login">
        <Card className="hih-background px-5 pt-5 border-0">
          <Card.Body className="border-0">
            <Card>
              <Card.Body>
                <div className="form-login">
                  <h2>HIH2 Login</h2>
                  <Button onClick={handleSubmit}>LOGIN</Button>
                  <span className="form-info">
                    Want more info on HIH? Click{' '}
                    <a
                      href="https://www.imagetrend.com/solutions/health-information-hub/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      here
                    </a>
                  </span>
                </div>
              </Card.Body>
            </Card>
            <img className="form-img" src="/img/hihlogoBlack.png" alt="hihlogoBlack" />
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default LoginPage;
