
const Audits = () => {
  return (
    <div>
      <h1>Audits</h1>
      {/* <div>
        <Link to={routes.audits_nemsisfilerecord}>Nemsis File Record Audits</Link>
      </div>
      <div>
        <Link to={routes.audits_nemsissubmitdata}>Nemsis Submit Data Audits</Link>
      </div> */}
    </div>
  );
};

export default Audits;