import { faEye, faMinus, faPlus, faSlash, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { Field, FieldAttributes, FieldHookConfig, useField } from 'formik';
import React, { isValidElement, useCallback, useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { FormCheckProps } from 'react-bootstrap/FormCheck';
import FormContext from 'react-bootstrap/FormContext';
import FormControl, { FormControlProps } from 'react-bootstrap/FormControl';
import { FormGroupProps } from 'react-bootstrap/FormGroup';
import { FormLabelProps } from 'react-bootstrap/FormLabel';
import InputGroup from 'react-bootstrap/InputGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import { ListGroupItemProps } from 'react-bootstrap/ListGroupItem';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import {
  ImageTrendOption,
  ImageTrendOptionOrGroup,
  ImageTrendSelectProps,
  ImageTrendSingleCreatableSelect,
  ImageTrendSingleSelect,
  getImageTrendOption,
} from './ImageTrendSelect';

type ImageTrendFormLabelProps = {
  required?: boolean;
} & FormLabelProps;

const ImageTrendFormLabel = ({ required, children, className, ...props }: ImageTrendFormLabelProps) => {
  return (
    <Form.Label className={(className || '') + ' fw-bold col-form-label'} {...props}>
      {children}
      {required ? <span className="d-inline text-danger"> *</span> : <></>}
    </Form.Label>
  );
};

type ImageTrendFormSelectProps<T> = {
  options: ImageTrendOptionOrGroup<T>[];
  isEditable: boolean;
} & ImageTrendSelectProps &
  FieldHookConfig<any>;

const ImageTrendFormSingleSelect = <T extends unknown>({
  options,
  id,
  isEditable,
  ...props
}: ImageTrendFormSelectProps<T>) => {
  const [field, meta, helper] = useField(props.name);
  const { controlId } = useContext(FormContext);
  return (
    <ImageTrendSingleSelect
      isClearable
      isDisabled={!isEditable}
      value={getImageTrendOption(field.value, options)}
      onChange={(option) => helper.setValue((option as ImageTrendOption<T>)?.value ?? null)}
      onBlur={() => helper.setTouched(true)}
      isInvalid={meta.touched && !!meta.error}
      feedback={meta.error}
      options={options}
      inputId={controlId || id}
      id={`${controlId || id}-container`}
      {...props}
    />
  );
};

const ImageTrendFormCreatableSelect = <T extends unknown>({
  options,
  id,
  isEditable,
  ...props
}: ImageTrendFormSelectProps<T>) => {
  const [field, meta, helper] = useField(props.name);
  return (
    <ImageTrendSingleCreatableSelect
      isClearable
      isDisabled={!isEditable}
      value={
        getImageTrendOption(field.value, options) || (field.value && { label: field.value, value: field.value }) || null
      }
      onChange={(option) => {
        helper.setValue((option as ImageTrendOption<T>)?.value || null);
      }}
      onBlur={() => helper.setTouched(true)}
      isInvalid={meta.touched && !!meta.error}
      feedback={meta.error}
      options={options}
      inputId={id}
      id={`${id}-container`}
      {...props}
    />
  );
};

type ImageTrendFormControlProps<T> = {
  isEditable: boolean;
} & FieldAttributes<T>;

type ImageTrendPasswordProps<T> = {
  initiallyOpen?: boolean;
} & ImageTrendFormControlProps<T>;

const ImageTrendPassword = ({ name, isEditable, initiallyOpen, ...props }: ImageTrendPasswordProps<any>) => {
  const [showText, setShowText] = useState<boolean>(initiallyOpen);
  return (
    <>
      <InputGroup>
        <FormControl {...props} type={showText ? 'text' : 'password'} name={name} disabled={!isEditable} />
        <Button
          title={(!showText ? 'Show' : 'Hide') + ' Text'}
          variant="secondary"
          onClick={() => setShowText(!showText)}
        >
          <span className="fa-layers fa-fw">
            <FontAwesomeIcon icon={faEye} />
            {showText && <FontAwesomeIcon icon={faSlash} />}
          </span>
        </Button>
      </InputGroup>
    </>
  );
};

const ImageTrendField = ({
  children,
  name,
  isEditable,
  as = Form.Control,
  ...props
}: ImageTrendFormControlProps<FormControlProps>) => {
  const [, meta] = useField(name);
  return (
    <>
      <Field {...props} as={as} isInvalid={meta.touched && !!meta.error} name={name} disabled={!isEditable} />
      <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </>
  );
};

type DivProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

type ImageTrendPlaintextProps = {
  linkTo?: string;
} & DivProps &
  FormControlProps;

const ImageTrendPlaintext = ({ linkTo, value, id, ...props }: ImageTrendPlaintextProps) => {
  const { controlId } = useContext(FormContext);
  if (linkTo) {
    return (
      <div {...props} id={controlId || id} className={(props.className ?? '') + ' form-control-plaintext'}>
        <Link to={linkTo}>{value}</Link>
      </div>
    );
  }
  return (
    <div {...props} id={controlId || id} className={(props.className ?? '') + ' form-control-plaintext'}>
      {value}
    </div>
  );
};

const ImageTrendFormHidden = (props: Omit<FormControlProps, 'value'>) => {
  return <Form.Control hidden aria-hidden {...props} />;
};

type ImageTrendFormGroupProps = {} & FormGroupProps;

const ImageTrendFormGroup = ({ children, ...props }: ImageTrendFormGroupProps) => {
  // Get labels and fields and display them how we want!
  const { labels, fields } = useCallback(() => {
    const labels: JSX.Element[] = [];
    const fields: JSX.Element[] = [];
    React.Children.forEach(children, (child) => {
      if (isValidElement(child)) {
        if (child.type === ImageTrendFormLabel) {
          labels.push(child);
        } else {
          fields.push(child);
        }
      }
    });
    return { labels, fields };
  }, [children])();

  return (
    <Form.Group as={Row} className="mb-3" {...props}>
      <Col sm={2}>{labels}</Col>
      <Col sm={10}>{fields}</Col>
    </Form.Group>
  );
};

const ImageTrendFormUnrowedGroup = ({ children, ...props }: ImageTrendFormGroupProps) => {
  return (
    <Form.Group className="mb-3" {...props}>
      {children}
    </Form.Group>
  );
};

const ImageTrendFormHiddenGroup = ({ children, ...props }: ImageTrendFormGroupProps) => {
  return (
    <ImageTrendFormGroup hidden aria-hidden {...props}>
      {children}
    </ImageTrendFormGroup>
  );
};

/**
 * D:< The guy who made this package didn't extract the property interface!
 */
type ImageTrendFieldSwitchProps = {
  onChange?: (checked: boolean) => void;
  checked?: boolean;
  disabled?: boolean;
  onLabel?: string;
  offLabel?: string;
  // These SHOULD be mapped to Bootstrap, since we could have custom colors!
  onColor?: string;
  offColor?: string;
  size?: 'xs' | 'sm' | 'lg';
  // Because we're sophisticated!
  className?: string;
  width?: number;
  height?: number;
};

// @TODO:  Make this whole thing 30x better, no ID, wtf???
const ImageTrendSwitch = ({
  className,
  onLabel = 'Yes',
  offLabel = 'No',
  onColor = 'success',
  offColor = 'danger',
  ...props
}: ImageTrendFieldSwitchProps) => {
  return (
    <div className="flex-column">
      <BootstrapSwitchButton
        onlabel={onLabel}
        offlabel={offLabel}
        onstyle={onColor as any}
        offstyle={offColor as any}
        style={(className ?? '') + ' d-inline-flex'}
        {...props}
      />
    </div>
  );
};

const ImageTrendSwitchField = ({ ...props }: ImageTrendFieldSwitchProps & { name: string }) => {
  const [, , helper] = useField(props.name);
  return (
    <ImageTrendSwitch
      onChange={(value) => {
        helper.setValue(value);
      }}
      {...props}
    />
  );
};

const ImageTrendFormCheck = ({ children, ...props }: FormCheckProps) => {
  return (
    <Col>
      <Form.Check {...props}>{children}</Form.Check>
    </Col>
  );
};

const ImageTrendSpinner = () => {
  return <FontAwesomeIcon size="2x" className="fa-spin-pulse" icon={faSpinner} />;
};

const ImageTrendLoading = () => {
  return (
    <div className="h-100 d-flex justify-content-center align align-items-center p-1">
      <ImageTrendSpinner />
      &nbsp;Loading
    </div>
  );
};

type ImageTrendListViewProps = {
  title: string;
  value: any;
  linkTo?: string;
  type?: 'text' | 'textarea';
} & ListGroupItemProps;
const ImageTrendListView = ({ title, value, children, ...props }: ImageTrendListViewProps) => {
  const [expand, setExpand] = useState(false);

  let text = props.linkTo ? <Link to={props.linkTo}>{value}</Link> : value;
  if (props.type === 'textarea' && typeof value === 'string' && value.split('\n').length > 4) {
    text = (
      <>
        <div className="overflow-y-hidden" style={{ whiteSpace: 'preserve', maxHeight: expand ? undefined : '6rem' }}>
          {text}
        </div>
        <Button variant="secondary" onClick={() => setExpand(!expand)} className="w-100">
          {expand ? (
            <span>
              <FontAwesomeIcon icon={faMinus} /> Show Less
            </span>
          ) : (
            <span>
              <FontAwesomeIcon icon={faPlus} /> Show More
            </span>
          )}
        </Button>
      </>
    );
  }

  return (
    <ListGroup.Item {...props}>
      <Row>
        <Col sm={2}>
          <span className="fw-bold">{title}</span>
        </Col>
        <Col sm={10}>{text}</Col>
        {children}
      </Row>
    </ListGroup.Item>
  );
};

const ImageTrendForm = {
  Check: ImageTrendFormCheck,
  Label: ImageTrendFormLabel,
  SelectField: ImageTrendFormSingleSelect,
  CreatableSelect: ImageTrendFormCreatableSelect,
  Field: ImageTrendField,
  Group: ImageTrendFormGroup,
  NormalGroup: ImageTrendFormUnrowedGroup,
  Password: ImageTrendPassword,
  Plaintext: ImageTrendPlaintext,
  Hidden: ImageTrendFormHidden,
  HiddenGroup: ImageTrendFormHiddenGroup,
  LoadingSpinner: ImageTrendLoading,
  ListView: ImageTrendListView,
  Spinner: ImageTrendSpinner,
  Switch: ImageTrendSwitch,
  SwitchField: ImageTrendSwitchField,
};

export default ImageTrendForm;
