import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useParams } from 'react-router-dom';
import routes from '../../common/routesDefinitions';
import BackButton from '../../Components/common/BackButton';
import Kno2StandardModuleSubview from './Kno2StandardModuleSubview';
import NemsisConfigurationView from './NemsisConfigurationView';

const Kno2StandardModuleView = (): JSX.Element => {
  const { clientId, transactionId } = useParams<{ clientId: string; transactionId: string }>();

  return (
    <div>
      <Row>
        <Col>
          <h1>Kno2 Standard Module</h1>
        </Col>
      </Row>
      <hr />
      <Kno2StandardModuleSubview />
      <hr />
      <NemsisConfigurationView />
      <hr />
      {clientId ? (
        <BackButton to={routes.client_accounts_details.replace(':id', clientId)} />
      ) : transactionId ? (
        <BackButton to={routes.kno2_transactions_details.replace(':id', transactionId)} />
      ) : (
        <BackButton to={routes.client_accounts} />
      )}
    </div>
  );
};

export default Kno2StandardModuleView;
