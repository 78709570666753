import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { TransactionRecordListItem } from '../../Models/TransactionService/TransactionRecordListItem';
import '../../Styling/index.css';
import { useApiClient } from '../../API/useApiClient';

interface DataTableProps {}

const TransactionRecord: React.FC<DataTableProps> = () => {
  // @TODO: Merge this into a reducer.
  const [data, setData] = useState<TransactionRecordListItem[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [createdOnStart, setCreatedOnStart] = useState('');
  const [createdOnEnd, setCreatedOnEnd] = useState('');
  const [messageModuleId, setMessageModuleId] = useState('');
  const [transactionStatus, setTransactionStatus] = useState('');
  const apiClient = useApiClient();

  const messageModuleIdDropdownValues = [
    { key: 'Lakeville General', value: '1' },
    { key: 'Mercy Hospital', value: '2' },
    { key: 'Hennepin Clinic', value: '3' },
  ];

  const transactionStatusDropdownValues = [
    { key: 'Complete', value: 'Complete' },
    { key: 'Failed', value: 'Failed' },
    { key: 'Processing', value: 'Processing' },
    { key: 'Ready to Send', value: 'ReadyToSend' },
    { key: 'Unknown', value: 'Unknown' },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const { data, totalPages } = await apiClient.TransactionServiceClient.GetTransactionRecordList({
        PageNumber: currentPage,
        CreatedOnStartUtc: createdOnStart,
        CreatedOnEndUtc: createdOnEnd,
        MessageModuleId: messageModuleId,
        TransactionStatus: transactionStatus,
      });
      setData(data);
      setTotalPages(totalPages);
    };

    fetchData();
  }, [
    apiClient.TransactionServiceClient,
    currentPage,
    createdOnStart,
    createdOnEnd,
    transactionStatus,
    messageModuleId,
  ]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleTimeFilter = (interval: number) => {
    const currentTime = new Date();
    currentTime.setHours(currentTime.getHours() - interval);
    const createdOnStart = currentTime.toLocaleString();
    setCreatedOnStart(createdOnStart);
    setCreatedOnEnd('');
    setCurrentPage(1);
  };

  const handleMessageModuleId = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setMessageModuleId(event.target.value);
  };

  const handleTransactionStatus = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setTransactionStatus(event.target.value);
  };

  return (
    <div>
      <h1>Transaction Records</h1>
      <h2>Search Parameters</h2>
      <div>
        <button onClick={() => handleTimeFilter(1)}>Last Hour</button>
        <button onClick={() => handleTimeFilter(6)}>Last 6 Hours</button>
        <button onClick={() => handleTimeFilter(24)}>Last 24 Hours</button>
      </div>
      <div>
        <label htmlFor="startDateTimePicker">Start Date: </label>
        <input
          type="datetime-local"
          id="startDateTimePicker"
          value={createdOnStart}
          onChange={(event) => setCreatedOnStart(event.target.value)}
        />
        <label htmlFor="endDateTimePicker">End Date: </label>
        <input
          type="datetime-local"
          id="endDateTimePicker"
          value={createdOnEnd}
          onChange={(event) => setCreatedOnEnd(event.target.value)}
        />
      </div>
      <div>
        <label htmlFor="messageModuleIdDropdown">MessageModuleName: </label>
        <select id="messageModuleIdDropdown" value={messageModuleId} onChange={handleMessageModuleId}>
          <option value="">Select...</option>
          {messageModuleIdDropdownValues.map((option) => (
            <option key={option.key} value={option.value}>
              {option.key}
            </option>
          ))}
        </select>
        <label htmlFor="transactionStatusDropdown">Status: </label>
        <select id="transactionStatusDropdown" value={transactionStatus} onChange={handleTransactionStatus}>
          <option value="">Select...</option>
          {transactionStatusDropdownValues.map((option) => (
            <option key={option.key} value={option.value}>
              {option.key}
            </option>
          ))}
        </select>
      </div>
      {data && data.length > 0 ? (
        <div>
          <table>
            <thead>
              <tr>
                <th>TransactionRecordId</th>
                <th>MessageModuleName</th>
                <th>Status</th>
                <th>MessageType</th>
                <th>IncidentId</th>
                <th>CreatedOn</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.messageModuleName}</td>
                  <td>{item.transactionStatus}</td>
                  <td>{item.messageType}</td>
                  <td>{item.incidentId}</td>
                  <td>{new Date(item.createdOnUtc).toLocaleString()}</td>
                  <td>
                    <Link to={`/transactions/transactionrecord/transactionrecorddetails/${item.id}`}>Details</Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div>
            <button onClick={handlePreviousPage} disabled={currentPage === 1}>
              Prev
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button onClick={handleNextPage} disabled={currentPage === totalPages || totalPages === 0}>
              Next
            </button>
          </div>
        </div>
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};

export default TransactionRecord;
