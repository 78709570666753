import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../API/appContext';
import { NameValuePair } from '../../Models/TransactionService/NameValuePair';
import ImageTrendForm from '../common/ImageTrendForm';
import { ImageTrendOption } from '../common/ImageTrendSelect';
import { Kno2WizardFormData } from './Kno2Wizard';
import SelectFieldComponent from './SelectFieldComponent';

interface StepProps {
  formData: Kno2WizardFormData;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const Step1: React.FC<StepProps> = ({ formData, setFieldValue }) => {
  const appContext = useContext(AppContext);

  if (!appContext) {
    throw new Error('AppContext is not provided');
  }

  const { apiClient } = appContext;

  const [moduleTypeOptions, setModuleTypeOptions] = useState<ImageTrendOption<number>[]>([]);

  useEffect(() => {
    const fetchModuleTypes = async () => {
      try {
        const response = await apiClient.TransactionServiceClient.GetModuleWorkerTypes();
        if (response) {
          const options = response.map((type: NameValuePair) => ({
            value: type.value,
            label: type.name,
          }));
          setModuleTypeOptions(options);
        }
      } catch (error) {
        console.error('Error fetching module worker types:', error);
      }
    };

    fetchModuleTypes();
  }, [apiClient]);

  const handleModuleTypeChange = (selectedOption: ImageTrendOption<number> | null) => {
    if (selectedOption) {
      setFieldValue('module.moduleWorkerType', selectedOption.value);
      setFieldValue('nemsisConfig.transactionServiceModuleType', selectedOption.label);
    } else {
      // Clear fields if no option is selected
      setFieldValue('module.moduleWorkerType', null);
      setFieldValue('nemsisConfig.transactionServiceModuleType', '');
    }
  };

  return (
    <div>
      <h2>Step 1: Select Module Type</h2>
      <SelectFieldComponent
        name="module.moduleWorkerType"
        required
        label="Module Type"
        options={moduleTypeOptions}
        onChange={handleModuleTypeChange}
      />
      <ImageTrendForm.NormalGroup controlId="module.name">
        <ImageTrendForm.Label required>Module Name</ImageTrendForm.Label>
        <ImageTrendForm.Field name="module.name" isEditable={true} />
      </ImageTrendForm.NormalGroup>
    </div>
  );
};

export default Step1;
