import { InteractionType } from '@azure/msal-browser';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react';
import { ForwardedRef } from 'react';
import { Outlet } from 'react-router';
import { ImageTrendToastContainerRefProps } from '../common/ImageTrendToast';
import Overlay from '../common/Overlay';
import Login from '../login/Login';

interface Props {
  toastRef: ForwardedRef<ImageTrendToastContainerRefProps>;
}

const RequireAuthRoute = (props: Props) => {
  useMsalAuthentication(InteractionType.Popup);

  return (
    <>
      <AuthenticatedTemplate>
        <Overlay toastRef={props.toastRef}>
          <Outlet />
        </Overlay>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </>
  );
};

export default RequireAuthRoute;
