import { NemsisAuditListQuery } from '../../Models/IngressService/NemsisAuditListQuery';
import NemsisConfigurationModel, {
  EditNemsisConfigurationModel,
} from '../../Models/IngressService/NemsisConfiguration';
import { NemsisFileRecordAuditListItem } from '../../Models/IngressService/NemsisFileRecordAuditListItem';
import { NemsisSubmitDataAuditListItem } from '../../Models/IngressService/NemsisSubmitDataAuditListItem';
import { NameValuePair } from '../../Models/TransactionService/NameValuePair';
import api from '../api';
import { ApiClientProps } from './ApiClient';
import getAxiosFunctions from './AxiosFunctions';
import { appendQueryString } from './QueryStrings';

export function createIngressServiceClient(props: ApiClientProps) {
  const baseUrl = `${api.ingressServiceUrl}`;
  const { getData, postData, postResponse, deleteData, patchData } = getAxiosFunctions(props);

  const client = Object.freeze({
    GetNemsisFileRecordAuditList: async function (query: NemsisAuditListQuery) {
      try {
        const endpoint = appendQueryString(`${baseUrl}/NemsisFileRecordAudit/GetAll`, query);
        const response = (await getData(endpoint)) as { data: NemsisFileRecordAuditListItem[]; totalPages: number };
        return response;
      } catch (error) {
        console.error(`Error fetching transaction record list:`, error);
        throw new Error('Error fetching transaction record list.');
      }
    },
    GetNemsisSubmitDataAuditList: async function (query: NemsisAuditListQuery) {
      try {
        const endpoint = appendQueryString(`${baseUrl}/NemsisSubmitDataAudit/GetAll`, query);
        const response = (await getData(endpoint)) as { data: NemsisSubmitDataAuditListItem[]; totalPages: number };
        return response;
      } catch (error) {
        console.error(`Error fetching transaction record list:`, error);
        throw new Error('Error fetching transaction record list.');
      }
    },
    GetClientAccountById: async function (id: string) {
      try {
        const endpoint = `${baseUrl}/client-accounts/${id}`;
        const response = await getData(endpoint);
        return response;
      } catch (error) {
        console.error(`Error fetching client account by id:`, error);
        throw new Error('Error fetching client account by id.');
      }
    },
    CreateClientAccount: async function (data: any) {
      try {
        const endpoint = `${baseUrl}/client-accounts`;
        const response = await postData(endpoint, data);
        return response;
      } catch (error) {
        console.error(`Error creating client account:`, error);
        throw new Error('Error creating client account.');
      }
    },
    GetKno2IntegrationById: async function (id: string) {
      try {
        const endpoint = `${baseUrl}/kno2-integrations/${id}`;
        const response = await getData(endpoint);
        return response;
      } catch (error) {
        console.error(`Error fetching Kno2 integration by id:`, error);
        throw new Error('Error fetching Kno2 integration by id.');
      }
    },
    GetOrganizationTypes: async function () {
      try {
        const endpoint = `${baseUrl}/kno2-integrations/organization-types`;
        const response = await getData(endpoint);
        return response;
      } catch (error) {
        console.error(`Error fetching organization types:`, error);
        throw new Error('Error fetching organization types.');
      }
    },
    GetOrganizationSpecialties: async function () {
      try {
        const endpoint = `${baseUrl}/kno2-integrations/organization-specialties`;
        const response = await getData(endpoint);
        return response;
      } catch (error) {
        console.error(`Error fetching organization specialties:`, error);
        throw new Error('Error fetching organization specialties.');
      }
    },
    GetJobFunctions: async function () {
      try {
        const endpoint = `${baseUrl}/kno2-integrations/job-functions`;
        const response = await getData(endpoint);
        return response;
      } catch (error) {
        console.error(`Error fetching job functions:`, error);
        throw new Error('Error fetching job functions.');
      }
    },
    CreateKno2Webhook: async function (data: any) {
      try {
        const endpoint = `${baseUrl}/kno2-webhooks`;
        const response = await postData(endpoint, data);
        return response;
      } catch (error) {
        console.error(`Error creating Kno2 webhook:`, error);
        throw new Error('Error creating Kno2 webhook.');
      }
    },
    GetKno2Webhooks: async function () {
      try {
        const endpoint = `${baseUrl}/kno2-webhooks`;
        const response = await getData(endpoint);
        return response;
      } catch (error) {
        console.error(`Error fetching Kno2 webhooks:`, error);
        throw new Error('Error fetching Kno2 webhooks.');
      }
    },
    GetKno2WebhookById: async function (id: string) {
      try {
        const endpoint = `${baseUrl}/kno2-webhooks/${id}`;
        const response = await getData(endpoint);
        return response;
      } catch (error) {
        console.error(`Error fetching Kno2 webhook by id:`, error);
        throw new Error('Error fetching Kno2 webhook by id.');
      }
    },
    ActivateKno2IntegrationWebhook: async function (data: any) {
      try {
        const endpoint = `${baseUrl}/webhook/kno2-integrations/activate`;
        const response = await postData(endpoint, data);
        return response;
      } catch (error) {
        console.error(`Error activating Kno2 integration webhook:`, error);
        throw new Error('Error activating Kno2 integration webhook.');
      }
    },
    // NEMSIS Configuration
    ValidateNemsisConfiguration: async function (data: any) {
      try {
        const endpoint = `${baseUrl}/nemsis-configurations/validate`;
        const response = await postResponse(endpoint, data);
        return response;
      } catch (error) {
        console.error('Error validating Kno2 standard module:', error);
        throw error;
      }
    },
    PostNemsisConfiguration: async function (data: any) {
      try {
        const endpoint = `${baseUrl}/nemsis-configurations`;
        const response = (await postData(endpoint, data)) as number;
        return response;
      } catch (error) {
        console.error(`Error creating NEMSIS configurations:`, error);
        throw error;
      }
    },
    GetNemsisConfigurationById: async function (id: string) {
      try {
        const endpoint = `${baseUrl}/nemsis-configurations/${id}`;
        const response = await getData(endpoint);
        return response;
      } catch (error) {
        console.error(`Error fetching NEMSIS configuration by id:`, error);
        throw new Error('Error fetching NEMSIS configuration by id.');
      }
    },
    GetNemsisConfigurationByTransactionServiceModuleId: async function (transactionServiceModuleId: string) {
      try {
        const endpoint = appendQueryString(`${baseUrl}/nemsis-configurations/`, { transactionServiceModuleId });
        const response = await getData(endpoint);
        return response as NemsisConfigurationModel | null;
      } catch (error) {
        console.error(`Error fetching NEMSIS configuration by module id ${transactionServiceModuleId}:`, error);
        throw error;
      }
    },
    DeleteNemsisConfigurationById: async function (id: string) {
      try {
        const endpoint = `${baseUrl}/nemsis-configurations/${id}`;
        await deleteData(endpoint);
      } catch (error) {
        console.error(`Error deleting NEMSIS configuration by id:`, error);
        throw new Error('Error deleting NEMSIS configuration by id.');
      }
    },
    /**
     * Resets the password on the NEMSIS Configuration by ID
     * @param id The ID of the NEMSIS Configuration
     * @returns The new generated password
     */
    PatchNemsisConfigurationPasswordReset: async (id: string): Promise<string> => {
      try {
        const endpoint = `${baseUrl}/nemsis-configurations/${id}/password`;
        const response = (await patchData(endpoint)) as string;
        return response;
      } catch (error) {
        console.error(`Error reseting NEMSIS configuration password:`, error);
        throw error;
      }
    },
    PatchActivateNemsisConfiguration: async (id: string) => {
      try {
        const endpoint = `${baseUrl}/nemsis-configurations/${id}/activate`;
        await patchData(endpoint);
        return true;
      } catch (error) {
        console.error(`Error activating NEMSIS configuration:`, error);
        throw error;
      }
    },
    PatchDeactivateNemsisConfiguration: async (id: string) => {
      try {
        const endpoint = `${baseUrl}/nemsis-configurations/${id}/deactivate`;
        await patchData(endpoint);
        return true;
      } catch (error) {
        console.error(`Error deactivating NEMSIS configuration:`, error);
        throw error;
      }
    },
    PatchEditNemsisConfiguration: async (id: string, nemsisConfiguration: EditNemsisConfigurationModel) => {
      try {
        const endpoint = `${baseUrl}/nemsis-configurations/${id}`;
        const response = (await patchData(endpoint, nemsisConfiguration)) as NemsisConfigurationModel;
        return response;
      } catch (error) {
        console.error(`Error creating NEMSIS configurations:`, error);
        throw error;
      }
    },
    GetNemsisDataSchemas: async function (): Promise<NameValuePair[]> {
      try {
        const endpoint = `${baseUrl}/nemsis-configurations/data-schemas`;
        const response = await getData(endpoint);
        return response;
      } catch (error) {
        console.error(`Error fetching NEMSIS data schemas:`, error);
        throw error;
      }
    },
    GetNemsisSchemaVersions: async function (): Promise<NameValuePair[]> {
      try {
        const endpoint = `${baseUrl}/nemsis-configurations/schema-versions`;
        const response = await getData(endpoint);
        return response;
      } catch (error) {
        console.error(`Error fetching NEMSIS schema versions:`, error);
        throw error;
      }
    },
    GetNemsisSubmissionById: async function (id: string) {
      try {
        const endpoint = `${baseUrl}/nemsis-submissions/${id}`;
        const response = await getData(endpoint);
        return response;
      } catch (error) {
        console.error(`Error fetching NEMSIS submission by id:`, error);
        throw new Error('Error fetching NEMSIS submission by id.');
      }
    },
    GetNemsisFileRecordDataById: async function (id: string) {
      try {
        const endpoint = `${baseUrl}/nemsis-submissions/file-record-data/${id}`;
        const response = await getData(endpoint);
        return response;
      } catch (error) {
        console.error(`Error fetching NEMSIS file record data by id:`, error);
        throw new Error('Error fetching NEMSIS file record data by id.');
      }
    },
    GetNemsisSubmissionStatusCodes: async function () {
      try {
        const endpoint = `${baseUrl}/nemsis-submissions/status-codes`;
        const response = await getData(endpoint);
        return response;
      } catch (error) {
        console.error(`Error fetching NEMSIS submission status codes:`, error);
        throw new Error('Error fetching NEMSIS submission status codes.');
      }
    },
  });

  return client;
}

export type IngressServiceClient = ReturnType<typeof createIngressServiceClient>;
