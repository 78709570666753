import { Kno2OutcomeUserModel } from '../../Models/OutcomesService/Kno2OutcomeUser';
import api from '../api';
import { ApiClientProps } from './ApiClient';
import getAxiosFunctions from './AxiosFunctions';
import { appendQueryString } from './QueryStrings';

export function createOutcomesServiceClient(props: ApiClientProps) {
  const baseUrl = `${api.outcomesServiceUrl}`;
  const { getData, postData, deleteData } = getAxiosFunctions(props);

  const client = Object.freeze({
    GetOutcomeUserByClientAccountId: async function (clientAccountId: string): Promise<Kno2OutcomeUserModel | null> {
      try {
        const url = appendQueryString(`${baseUrl}/outcome-users`, { clientAccountId });
        const response = await getData(url);
        return response as Kno2OutcomeUserModel | null;
      } catch (error) {
        console.error(`Error fetching Kno2 outcome user ${clientAccountId}:`, error);
        throw error;
      }
    },
    PostKno2OutcomeUser: async function (status: Kno2OutcomeUserModel): Promise<string> {
      try {
        const response = (await postData(`${baseUrl}/outcome-users`, status)) as string;
        return response;
      } catch (error: any) {
        console.error('Error posting Kno2 outcome user:', error);
        throw new Error(error?.response?.data?.message);
      }
    },
    DeleteKno2OutcomeUser: async function (id: string) {
      try {
        const endpoint = `${baseUrl}/outcome-users/${id}`;
        await deleteData(endpoint);
      } catch (error) {
        console.error(`Error deleting Kno2 outcome user ${id}: ${error}`);
        throw error;
      }
    },
  });

  return client;
}

export type OutcomesServiceClient = ReturnType<typeof createOutcomesServiceClient>;
