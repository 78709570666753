import Pagination from 'react-bootstrap/Pagination';
import Row from 'react-bootstrap/Row';
import { OffsetLimit, PaginationQuery, ReducerAction } from './Pagination.types';
import { getPaginationShowingString } from './PaginationHelper';

interface ImageTrendPaginationProps<R extends OffsetLimit> {
  paginationQuery: R;
  updatePaginationQuery: React.Dispatch<ReducerAction<R>>;
  total: number;
}

const ImageTrendPagination = <R extends OffsetLimit>({
  paginationQuery,
  updatePaginationQuery,
  total,
}: ImageTrendPaginationProps<R>) => {
  const currentPage = paginationQuery.offset / paginationQuery.limit + 1;
  const totalPages = Math.ceil(total / paginationQuery.limit);

  const handleOnChange = (offset: number) => {
    updatePaginationQuery({
      type: PaginationQuery.offset,
      data: {
        ...paginationQuery,
        offset,
      },
    });
  };

  return (
    <>
      <Row className="mx-1">
        <p>{getPaginationShowingString(currentPage, paginationQuery.limit, total)}</p>
      </Row>
      <Pagination>
        <Pagination.Prev
          disabled={currentPage <= 1}
          onClick={() => handleOnChange(paginationQuery.offset - paginationQuery.limit)}
        />
        <Pagination.Item active={true}>
          Page {currentPage} of {totalPages}
        </Pagination.Item>
        <Pagination.Next
          disabled={currentPage >= totalPages}
          onClick={() => handleOnChange(paginationQuery.offset + paginationQuery.limit)}
        />
      </Pagination>
    </>
  );
};

export default ImageTrendPagination;
