import { number, object, string } from 'yup';
import { PartialShape } from '../../Components/common/Schema';
import { NameValuePair } from '../TransactionService/NameValuePair';

export default interface NemsisConfigurationModel {
  id: string;
  name: string;
  isActive: boolean;
  transactionServiceModuleId: string;
  transactionServiceModuleType: string;
  username: string;
  dataSchema: NameValuePair;
  schemaVersion: NameValuePair;
  createdOnUtc: Date;
  modifiedOnUtc?: Date;
}

export interface EditNemsisConfigurationModel {
  name: string;
  nemsisDataSchema: number;
  nemsisSchemaVersion: number;
}

const editNemsisConfigurationShape = {
  name: string().label('Name').required(),
  dataSchema: object().shape<PartialShape<NameValuePair>>({
    value: number().label('NEMSIS Data Schema').required(),
  }),
  schemaVersion: object().shape<PartialShape<NameValuePair>>({
    value: number().label('NEMSIS Schema Version').required(),
  }),
};

export const editNemsisConfigurationModelSchema =
  object<NemsisConfigurationModel>().shape(editNemsisConfigurationShape);
