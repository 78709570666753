import { Formik } from 'formik';
import { useEffect, useReducer } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { FaPlus } from 'react-icons/fa';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { useApiClient } from '../../API/useApiClient';
import ImageTrendForm from '../../Components/common/ImageTrendForm';
import { CreateKno2IntegrationModel, kno2schema } from '../../Models/HihClient/CreateKno2IntegrationModel';
import routes from '../../common/routesDefinitions';
import { StateOptions } from '../../Shared/StateOptions';
import CreatableSelect from 'react-select/creatable';

enum ActionTypes {
  SET_ORGANIZATION_TYPES = 'SET_ORGANIZATION_TYPES',
  SET_ORGANIZATION_SPECIALTIES = 'SET_ORGANIZATION_SPECIALTIES',
  SET_JOB_FUNCTIONS = 'SET_JOB_FUNCTIONS',
  SET_ISLOADING = 'SET_ISLOADING',
  SET_ALERT = 'SET_ALERT',
}

type State = {
  organizationTypes: { key: string; value: string }[];
  organizationSpecialties: { key: string; value: string }[];
  jobFunctions: { key: string; value: number }[];
  isLoading: boolean;
  alert?: string;
};

const initialState: State = {
  organizationTypes: [],
  organizationSpecialties: [],
  jobFunctions: [],
  isLoading: true,
};

type SetOrganizationTypesAction = {
  type: typeof ActionTypes.SET_ORGANIZATION_TYPES;
  organizationTypes: { key: string; value: string }[];
};

type SetOrganizationSpecialtiesAction = {
  type: typeof ActionTypes.SET_ORGANIZATION_SPECIALTIES;
  organizationSpecialties: { key: string; value: string }[];
};

type SetJobFunctionsAction = {
  type: typeof ActionTypes.SET_JOB_FUNCTIONS;
  jobFunctions: { key: string; value: number }[];
};

type SetIsLoading = {
  type: typeof ActionTypes.SET_ISLOADING;
  isLoading: boolean;
};

type SetAlert = {
  type: typeof ActionTypes.SET_ALERT;
  alert?: string;
};

type ReducerActions =
  | SetOrganizationTypesAction
  | SetOrganizationSpecialtiesAction
  | SetJobFunctionsAction
  | SetIsLoading
  | SetAlert;

const setOrganizationTypes = (organizationTypes: { key: string; value: string }[]): ReducerActions => ({
  type: ActionTypes.SET_ORGANIZATION_TYPES,
  organizationTypes,
});

const setOrganizationSpecialties = (organizationSpecialties: { key: string; value: string }[]): ReducerActions => ({
  type: ActionTypes.SET_ORGANIZATION_SPECIALTIES,
  organizationSpecialties,
});

const setJobFunctions = (jobFunctions: { key: string; value: number }[]): ReducerActions => ({
  type: ActionTypes.SET_JOB_FUNCTIONS,
  jobFunctions,
});

const setIsLoading = (isLoading: boolean): ReducerActions => ({
  type: ActionTypes.SET_ISLOADING,
  isLoading,
});

const setAlert = (alert?: string): ReducerActions => ({
  type: ActionTypes.SET_ALERT,
  alert,
});

const reducer = (state: State, action: ReducerActions): State => {
  switch (action.type) {
    case ActionTypes.SET_ORGANIZATION_TYPES:
      const { organizationTypes } = action;
      return {
        ...state,
        organizationTypes,
      };
    case ActionTypes.SET_ORGANIZATION_SPECIALTIES:
      const { organizationSpecialties } = action;
      return {
        ...state,
        organizationSpecialties,
      };
    case ActionTypes.SET_JOB_FUNCTIONS:
      const { jobFunctions } = action;
      return {
        ...state,
        jobFunctions,
      };
    case ActionTypes.SET_ISLOADING:
      const { isLoading } = action;
      return {
        ...state,
        isLoading,
      };
    case ActionTypes.SET_ALERT:
      const { alert } = action;
      return {
        ...state,
        alert,
      };
    default:
      return state;
  }
};

const CreateKno2Integration = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { clientId } = useParams<{ clientId: string }>();
  const apiClient = useApiClient();
  const navigate = useNavigate();

  const ehrOptions = [
    { label: 'ImageTrend', value: 'ImageTrend' },
    { label: 'Elite', value: 'Elite' },
    { label: 'Epic', value: 'Epic' },
    { label: 'ESO', value: 'ESO' },
  ];

  const roleOptions = [
    { label: 'User', value: 'User' },
    { label: 'Administrator', value: 'Administrator' },
  ];

  const stateOptions = StateOptions.getStates();

  const initialModel: CreateKno2IntegrationModel = {
    clientAccountId: clientId ?? '',
    createFirstUserFromPrimaryContact: true,
    primaryContactFirstName: '',
    primaryContactLastName: '',
    emailAddress: '',
    primaryContactPhoneNumber: '',
    primaryContactCustomerExtension: '',
    role: '',
    jobFunctionId: undefined,
    organizationName: '',
    organizationSubdomain: '',
    organizationTypeId: '',
    organizationSpecialtyId: '',
    ehr: '',
    website: '',
    npi: '',
    organizationPrimaryPhone: '',
    organizationPrimaryFax: '',
    description: '',
    careSetting: '',
    street: '',
    city: '',
    state: '',
    postalCode: '',
  };

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const organizationTypesResponse = await apiClient.HihHelperClient.GetOrganizationTypes();
        const organizationSpecialtiesResponse = await apiClient.HihHelperClient.GetOrganizationSpecialties();
        const jobFunctionsResponse = await apiClient.HihHelperClient.GetJobFunctions();

        const organizationTypes = organizationTypesResponse.map((type: { key: string; value: string }) => ({
          label: type.key,
          value: type.value,
        }));
        const organizationSpecialties = organizationSpecialtiesResponse.map((type: { key: string; value: string }) => ({
          label: type.key,
          value: type.value,
        }));
        const jobFunctions = jobFunctionsResponse.map((type: { name: string; id: number; role: string }) => ({
          label: type.name,
          value: type.id,
        }));

        dispatch(setOrganizationTypes(organizationTypes));
        dispatch(setOrganizationSpecialties(organizationSpecialties));
        dispatch(setJobFunctions(jobFunctions));
      } catch (error) {
        console.error('Error fetching dropdown list options:', error);
      }
      dispatch(setIsLoading(false));
    };

    fetchDropdownOptions();
  }, [apiClient]);

  const handleFormSubmit = async (model: CreateKno2IntegrationModel) => {
    try {
      await apiClient.HihHelperClient.PostKno2Integration(model);
      navigate(routes.client_accounts_details.replace(':id', initialModel.clientAccountId ?? ''));
    } catch (error) {
      dispatch(setAlert('Failed to Create Kno2 Integration'));
    }
  };

  return (
    <div>
      <h1>Create Kno2 Integration</h1>
      <br />
      {state.isLoading ? (
        <ImageTrendForm.LoadingSpinner />
      ) : (
        <>
          <Formik
            enableReinitialize={true}
            initialValues={initialModel}
            validationSchema={kno2schema}
            onSubmit={handleFormSubmit}
          >
            {({ handleSubmit, values, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <Alert show={!!state.alert} variant="danger" dismissible onClose={() => dispatch(setAlert())}>
                  {state.alert}
                </Alert>
                <ImageTrendForm.HiddenGroup controlId="clientAccountId">
                  <ImageTrendForm.Hidden defaultValue={initialModel.clientAccountId} />
                </ImageTrendForm.HiddenGroup>
                <ImageTrendForm.Group controlId="createFirstUserFromPrimaryContact">
                  <ImageTrendForm.Check
                    type="checkbox"
                    name="createFirstUserFromPrimaryContact"
                    className="fw-bold"
                    label="Create First User From Primary Contact"
                  />
                </ImageTrendForm.Group>
                <hr />
                <h2 className="fw-bold">Organization</h2>
                <ImageTrendForm.Group controlId="organizationName">
                  <ImageTrendForm.Label required>Organization Name</ImageTrendForm.Label>
                  <ImageTrendForm.Field isEditable={true} name="organizationName" />
                </ImageTrendForm.Group>
                <ImageTrendForm.Group controlId="organizationSubdomain">
                  <ImageTrendForm.Label required>Organization Subdomain</ImageTrendForm.Label>
                  <ImageTrendForm.Field isEditable={true} name="organizationSubdomain" />
                </ImageTrendForm.Group>
                <ImageTrendForm.Group controlId="organizationTypeId">
                  <ImageTrendForm.Label required>Organization Type</ImageTrendForm.Label>
                  <Col sm={10}>
                    <Select
                      name="organizationTypeId"
                      inputId="organizationTypeId"
                      options={state.organizationTypes}
                      value={state.organizationTypes.find((option) => option.value === values.organizationTypeId)}
                      onChange={(option) => setFieldValue('organizationTypeId', option ? option.value : '')}
                    />
                  </Col>
                </ImageTrendForm.Group>
                <ImageTrendForm.Group controlId="organizationSpecialtyId">
                  <ImageTrendForm.Label required>Organization Specialty</ImageTrendForm.Label>
                  <Col sm={10}>
                    <Select
                      name="organizationSpecialtyId"
                      inputId="organizationSpecialtyId"
                      options={state.organizationSpecialties}
                      value={state.organizationSpecialties.find(
                        (option) => option.value === values.organizationSpecialtyId,
                        '',
                      )}
                      onChange={(option) => setFieldValue('organizationSpecialtyId', option?.value ?? '')}
                    />
                  </Col>
                </ImageTrendForm.Group>
                <ImageTrendForm.Group controlId="ehr">
                  <ImageTrendForm.Label required>EHR</ImageTrendForm.Label>
                  <Col sm={10}>
                    <CreatableSelect
                      name="ehr"
                      options={ehrOptions}
                      value={
                        ehrOptions.find((option) => option.value === values.ehr) || {
                          label: values.ehr,
                          value: values.ehr,
                        }
                      }
                      onChange={(option) => setFieldValue('ehr', option ? option.value : '')}
                      onCreateOption={(inputValue) => setFieldValue('ehr', inputValue)}
                      isClearable
                    />
                  </Col>
                </ImageTrendForm.Group>
                <ImageTrendForm.Group controlId="website">
                  <ImageTrendForm.Label>Website</ImageTrendForm.Label>
                  <ImageTrendForm.Field isEditable={true} name="website" />
                </ImageTrendForm.Group>
                <ImageTrendForm.Group controlId="npi">
                  <ImageTrendForm.Label>NPI</ImageTrendForm.Label>
                  <ImageTrendForm.Field isEditable={true} name="npi" />
                </ImageTrendForm.Group>
                {
                  // @TODO: Validate phone numbers without freaking out (on blur)
                }
                <ImageTrendForm.Group controlId="organizationPrimaryPhone">
                  <ImageTrendForm.Label required>Organization Primary Phone</ImageTrendForm.Label>
                  <ImageTrendForm.Field isEditable={true} name="organizationPrimaryPhone" />
                </ImageTrendForm.Group>
                <ImageTrendForm.Group controlId="organizationPrimaryFax">
                  <ImageTrendForm.Label>Organization Primary Fax</ImageTrendForm.Label>
                  <ImageTrendForm.Field isEditable={true} name="organizationPrimaryFax" />
                </ImageTrendForm.Group>
                <ImageTrendForm.Group controlId="description">
                  <ImageTrendForm.Label>Description</ImageTrendForm.Label>
                  <ImageTrendForm.Field isEditable={true} name="description" />
                </ImageTrendForm.Group>
                <ImageTrendForm.Group controlId="careSetting">
                  <ImageTrendForm.Label>Care Setting</ImageTrendForm.Label>
                  <ImageTrendForm.Field isEditable={true} name="careSetting" />
                </ImageTrendForm.Group>
                <hr />
                <h2 className="fw-bold">Contact</h2>
                <ImageTrendForm.Group controlId="primaryContactFirstName">
                  <ImageTrendForm.Label required>Primary Contact First Name</ImageTrendForm.Label>
                  <ImageTrendForm.Field isEditable={true} name="primaryContactFirstName" />
                </ImageTrendForm.Group>
                <ImageTrendForm.Group controlId="primaryContactLastName">
                  <ImageTrendForm.Label required>Primary Contact Last Name</ImageTrendForm.Label>
                  <ImageTrendForm.Field isEditable={true} name="primaryContactLastName" />
                </ImageTrendForm.Group>
                <ImageTrendForm.Group controlId="emailAddress">
                  <ImageTrendForm.Label required>Email Address</ImageTrendForm.Label>
                  <ImageTrendForm.Field isEditable={true} name="emailAddress" />
                </ImageTrendForm.Group>
                <ImageTrendForm.Group controlId="primaryContactPhoneNumber">
                  <ImageTrendForm.Label required>Primary Contact Phone Number</ImageTrendForm.Label>
                  <ImageTrendForm.Field isEditable={true} name="primaryContactPhoneNumber" />
                </ImageTrendForm.Group>
                <ImageTrendForm.Group controlId="primaryContactCustomerExtension">
                  <ImageTrendForm.Label>Primary Contact Customer Extension</ImageTrendForm.Label>
                  <ImageTrendForm.Field isEditable={true} name="primaryContactCustomerExtension" />
                </ImageTrendForm.Group>
                <ImageTrendForm.Group controlId="role">
                  <ImageTrendForm.Label required>Role</ImageTrendForm.Label>
                  <Col sm={10}>
                    <Select
                      name="role"
                      inputId="role"
                      options={roleOptions}
                      value={roleOptions.find((option) => option.value === values.role)}
                      onChange={(option) => setFieldValue('role', option ? option.value : '')}
                    />
                  </Col>
                </ImageTrendForm.Group>
                <ImageTrendForm.Group controlId="jobFunctionId">
                  <ImageTrendForm.Label required>Job Function</ImageTrendForm.Label>
                  <Col sm={10}>
                    <Select
                      name="jobFunctionId"
                      inputId="jobFunctionId"
                      options={state.jobFunctions}
                      value={state.jobFunctions.find((option) => option.value === values.jobFunctionId)}
                      onChange={(option) => setFieldValue('jobFunctionId', option ? option.value : '')}
                    />
                  </Col>
                </ImageTrendForm.Group>
                <hr />
                <h2 className="fw-bold">Location</h2>
                <ImageTrendForm.Group controlId="street">
                  <ImageTrendForm.Label required>Street</ImageTrendForm.Label>
                  <ImageTrendForm.Field isEditable={true} name="street" />
                </ImageTrendForm.Group>
                <ImageTrendForm.Group controlId="city">
                  <ImageTrendForm.Label required>City</ImageTrendForm.Label>
                  <ImageTrendForm.Field isEditable={true} name="city" />
                </ImageTrendForm.Group>
                <ImageTrendForm.Group controlId="state">
                  <ImageTrendForm.Label required>State</ImageTrendForm.Label>
                  <Col sm={10}>
                    <Select
                      name="state"
                      inputId="state"
                      options={stateOptions}
                      value={stateOptions.find((option) => option.value === values.state)}
                      onChange={(option) => setFieldValue('state', option ? option.value : '')}
                    />
                  </Col>
                </ImageTrendForm.Group>
                <ImageTrendForm.Group controlId="postalCode">
                  <ImageTrendForm.Label required>Postal Code</ImageTrendForm.Label>
                  <ImageTrendForm.Field isEditable={true} name="postalCode" />
                </ImageTrendForm.Group>
                <hr />
                <Button variant="primary" type="submit">
                  <FaPlus /> Create
                </Button>
              </Form>
            )}
          </Formik>
        </>
      )}
    </div>
  );
};

export default CreateKno2Integration;
