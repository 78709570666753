import { AxiosResponse } from 'axios';
import { CreateKno2ModuleModel } from '../../Components/wizard/Kno2Module.types';
import { QueryParameters } from '../../Models/Common/QueryParameters';
import { QueryResults } from '../../Models/Common/QueryResults';
import { DestinationMappingModel } from '../../Models/TransactionService/DestinationMappingModel';
import { Kno2MessageStatus } from '../../Models/TransactionService/Kno2MessageStatus';
import { Kno2MessageStatusError } from '../../Models/TransactionService/Kno2MessageStatusError';
import { Kno2StandardModuleListQueryParameters } from '../../Models/TransactionService/Kno2StandardModuleListQueryParameters';
import { Kno2StandardModuleModel } from '../../Models/TransactionService/Kno2StandardModuleModel';
import { Kno2TransactionDetail, Kno2TransactionListItem } from '../../Models/TransactionService/Kno2Transaction';
import { NameValuePair } from '../../Models/TransactionService/NameValuePair';
import { TransactionRecordListItem } from '../../Models/TransactionService/TransactionRecordListItem';
import { TransactionRecordListQuery } from '../../Models/TransactionService/TransactionRecordListQuery';
import { TransactionRecordModel } from '../../Models/TransactionService/TransactionRecordModel';
import api from '../api';
import { ApiClientProps } from './ApiClient';
import getAxiosFunctions from './AxiosFunctions';
import { appendQueryString } from './QueryStrings';

export function createTransactionServiceClient(props: ApiClientProps) {
  const baseUrl = `${api.transactionServiceUrl}`;
  const { getData, postData, postResponse, putData, deleteData } = getAxiosFunctions(props);

  const client = Object.freeze({
    GetTransactionRecord: async function (id: string): Promise<TransactionRecordModel | undefined> {
      try {
        const response = (await getData(`${baseUrl}/TransactionRecord/Get/${id}`)) as TransactionRecordModel;
        return response;
      } catch (error) {
        console.error(`Error fetching transaction record ${id}:`, error);
      }
    },
    GetTransactionRecordList: async function (
      query: TransactionRecordListQuery,
    ): Promise<{ data: TransactionRecordListItem[]; totalPages: number }> {
      try {
        const endpoint = appendQueryString(`${baseUrl}/TransactionRecord/GetTransactionRecords`, query);
        const response = (await getData(endpoint)) as { data: TransactionRecordListItem[]; totalPages: number };
        return response;
      } catch (error) {
        console.error(`Error fetching transaction record list:`, error);
        throw new Error('Error fetching transaction record list.');
      }
    },
    /**
     * Gets the Kno2Transaction delivery error
     * @param id
     * @returns
     */
    GetKno2TransactionMessageStatusError: async (id: string): Promise<Kno2MessageStatusError> => {
      try {
        const endpoint = `${baseUrl}/kno2-transaction/${id}/kno2-message-status`;
        const response = await getData(endpoint);
        return response as Kno2MessageStatusError;
      } catch (error) {
        console.error('Error posting Kno2 message status:', error);
        throw error;
      }
    },
    PostKno2MessageStatus: async function (status: Kno2MessageStatus): Promise<void> {
      try {
        await postData(`${baseUrl}/webhook/kno2-message-status`, status);
      } catch (error) {
        console.error('Error posting Kno2 message status:', error);
      }
    },
    PostDestinationMapping: async function (destinationMapping: DestinationMappingModel): Promise<number> {
      try {
        const endpoint = `${baseUrl}/destination-mappings`;
        const requestBody = {
          kno2SenderConfigurationId: destinationMapping.kno2SenderConfigurationId,
          makeActiveUponCreation: destinationMapping.makeActiveUponCreation,
          ...destinationMapping.destinationInfo,
        };
        const response = (await postData(endpoint, requestBody)) as number;
        return response;
      } catch (error) {
        const message = 'Error creating Destination Mapping';
        console.error(`${message}:`, error);
        throw error;
      }
    },
    GetDestinationMappingById: async function (id: number | string): Promise<DestinationMappingModel | null> {
      try {
        const endpoint = `${baseUrl}/destination-mappings/${id}`;
        const response = await getData(endpoint);
        return response as DestinationMappingModel | null;
      } catch (error) {
        const message = 'Error fetching Destination Mapping';
        console.error(`${message}:`, error);
        throw new Error(`${message}.`);
      }
    },
    GetDestinationMappingsByKno2SenderConfigurationId: async function (kno2SenderConfigurationId: string) {
      try {
        const endpoint = `${baseUrl}/destination-mappings/kno2-sender-config/${kno2SenderConfigurationId}`;
        const response = await getData(endpoint);
        return response;
      } catch (error) {
        const message = 'Error fetching Destination Mapping list';
        console.error(`${message}:`, error);
        throw new Error(`${message}.`);
      }
    },
    /**
     * Updates a destination info.
     * @param destinationMapping The destination mapping with the info values to be updated.
     * @returns The destination mapping with the updated destination info.
     */
    PutDestinationInfo: async function (destinationMapping: DestinationMappingModel) {
      try {
        const endpoint = `${baseUrl}/destination-mappings/destination-info`;
        var updatedEntity = (await putData(endpoint, {
          destinationMappingId: destinationMapping.id,
          ...destinationMapping.destinationInfo,
        })) as DestinationMappingModel;
        return updatedEntity;
      } catch (error) {
        const message = 'Error updating Destination Mapping info';
        console.error(`${message}:`, error);
        throw error;
      }
    },
    DeactivateDestinationMapping: async function (id: number) {
      try {
        const endpoint = `${baseUrl}/destination-mappings/deactivate/${id}`;
        await putData(endpoint, id);
      } catch (error) {
        const message = 'Error deactivating Destination Mapping';
        console.error(`${message}:`, error);
        throw error;
      }
    },
    GetKno2SenderSourceTypes: async function (): Promise<NameValuePair[]> {
      try {
        const response = await getData(`${baseUrl}/kno2-modules/source-types`);
        return response;
      } catch (error) {
        console.error('Error fetching Kno2 sender source types:', error);
        throw error;
      }
    },
    GetKno2ModuleById: async function (id: string) {
      try {
        const response = await getData(`${baseUrl}/kno2-modules/${id}`);
        return response as Kno2StandardModuleModel | null;
      } catch (error) {
        console.error(`Error fetching Kno2 standard module ${id}:`, error);
        throw error;
      }
    },
    ValidateCreateKno2Module: async function (data: CreateKno2ModuleModel) {
      try {
        const endpoint = `${baseUrl}/kno2-modules/validate`;
        const response = await postResponse(endpoint, data);
        return response;
      } catch (error) {
        console.error('Error validating Kno2 standard module:', error);
        throw error;
      }
    },
    PostKno2Module: async function (data: CreateKno2ModuleModel): Promise<AxiosResponse<any, any>> {
      try {
        const endpoint = `${baseUrl}/kno2-modules`;
        const response = await postResponse(endpoint, data);
        return response;
      } catch (error) {
        console.error('Error creating Kno2 standard module:', error);
        throw error;
      }
    },
    GetKno2SenderConfigWithModuleNameDict: async function () {
      try {
        // @TODO: We should add query params here.
        const endpoint = appendQueryString(`${baseUrl}/kno2-sender-configs/module-name`, {});
        const response = await getData(endpoint);
        return response;
      } catch (error) {
        console.error('Error fetching Kno2 standard modules list:', error);
        throw error;
      }
    },
    GetKno2StandardModuleListByClientId: async function (query: Kno2StandardModuleListQueryParameters) {
      try {
        const endpoint = appendQueryString(`${baseUrl}/kno2-modules`, query);
        const response = await getData(endpoint);
        return response as QueryResults<Kno2StandardModuleModel>;
      } catch (error) {
        console.error('Error fetching Kno2 standard modules list:', error);
        throw error;
      }
    },
    GetKno2TransactionById: async function (id: number): Promise<any> {
      try {
        const response = await getData(`${baseUrl}/kno2-transactions/${id}`);
        return response;
      } catch (error) {
        console.error(`Error fetching Kno2 transaction ${id}:`, error);
      }
    },
    GetModuleWorkerTypes: async function (): Promise<NameValuePair[]> {
      try {
        const response = await getData(`${baseUrl}/modules/worker-types`);
        return response;
      } catch (error) {
        console.error('Error fetching module worker types:', error);
        throw error;
      }
    },
    GetNemsisFileTypeById: async function (id: number): Promise<any> {
      try {
        const response = await getData(`${baseUrl}/nemsis-file-types/${id}`);
        return response;
      } catch (error) {
        console.error(`Error fetching NEMSIS file type ${id}:`, error);
      }
    },
    DeleteNemsisFileTypeById: async function (id: number): Promise<void> {
      try {
        await deleteData(`${baseUrl}/nemsis-file-types/${id}`);
      } catch (error) {
        console.error(`Error deleting NEMSIS file type ${id}:`, error);
      }
    },
    PutNemsisFileType: async function (data: any): Promise<void> {
      try {
        await putData(`${baseUrl}/nemsis-file-types/ext-doc-type`, data);
      } catch (error) {
        console.error('Error updating NEMSIS file type:', error);
      }
    },
    PostNemsisFileType: async function (data: any): Promise<void> {
      try {
        await postData(`${baseUrl}/nemsis-file-types`, data);
      } catch (error) {
        console.error('Error creating NEMSIS file type:', error);
      }
    },
    GetKno2TransactionList: async function (query?: QueryParameters): Promise<QueryResults<Kno2TransactionListItem>> {
      try {
        const endpoint = appendQueryString(`${baseUrl}/kno2-transactions`, query);
        const response = await getData(endpoint);
        return response;
      } catch (error) {
        console.error(`Error fetching Kno2 transaction list:`, error);
        throw new Error('Error fetching Kno2 transaction list.');
      }
    },
    GetKno2TransactionDetail: async function (id: number | string): Promise<Kno2TransactionDetail> {
      try {
        const endpoint = `${baseUrl}/kno2-transactions/${id}`;
        const response = await getData(endpoint);
        return response;
      } catch (error) {
        console.error(`Error fetching Kno2 transaction detail:`, error);
        throw new Error('Error fetching Kno2 transaction detail.');
      }
    },
  });

  return client;
}

export type TransactionServiceClient = ReturnType<typeof createTransactionServiceClient>;
