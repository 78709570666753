import { Formik, FormikTouched, setNestedObjectValues } from 'formik';
import { useEffect, useReducer } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useNavigate, useParams } from 'react-router-dom';
import { useApiClient } from '../../API/useApiClient';
import BackButton from '../../Components/common/BackButton';
import CreateButton from '../../Components/common/CreateButton';
import ImageTrendForm from '../../Components/common/ImageTrendForm';
import { dateTimeToDateInputString, dateTimeUtcToLocalString } from '../../Helpers/DateTimeHelper';
import {
  CreateKno2IntegrationOrderFormModel,
  Kno2IntegrationOrderFormModel,
} from '../../Models/TransactionService/Kno2IntegrationOrderFormModel';
import routes from '../../common/routesDefinitions';
import { kno2IntegrationOrderFormSchema } from './Kno2IntegrationOrderForm.types';

interface Kno2IntegrationOrderFormProps {
  isCreate?: boolean;
}

enum ActionTypes {
  SET_KNO2_INTEGRATION_ORDER_FORM = 'SET_KNO2_INTEGRATION_ORDER_FORM',
  SET_ISLOADING = 'SET_ISLOADING',
  SET_EDIT = 'SET_EDIT',
  SET_ALERT = 'SET_ALERT',
  SET_DISABLED = 'SET_DISABLED',
}

type State = {
  kno2IntegrationOrderForm: Kno2IntegrationOrderFormModel;
  isLoading: boolean;
  edit: boolean;
  alert?: string;
  disabled: boolean;
};

type SetKno2IntegrationOrderFormAction = {
  type: typeof ActionTypes.SET_KNO2_INTEGRATION_ORDER_FORM;
  kno2IntegrationOrderForm: Kno2IntegrationOrderFormModel;
};

type SetIsLoading = {
  type: typeof ActionTypes.SET_ISLOADING;
  isLoading: boolean;
};

type SetEdit = {
  type: typeof ActionTypes.SET_EDIT;
  edit: boolean;
};

type SetAlert = {
  type: typeof ActionTypes.SET_ALERT;
  alert?: string;
};

type SetDisabled = {
  type: typeof ActionTypes.SET_DISABLED;
  disabled: boolean;
};

type ReducerActions = SetKno2IntegrationOrderFormAction | SetIsLoading | SetEdit | SetAlert | SetDisabled;

const setKno2IntegrationOrderForm = (kno2IntegrationOrderForm: Kno2IntegrationOrderFormModel): ReducerActions => ({
  type: ActionTypes.SET_KNO2_INTEGRATION_ORDER_FORM,
  kno2IntegrationOrderForm,
});

const setIsLoading = (isLoading: boolean): ReducerActions => ({
  type: ActionTypes.SET_ISLOADING,
  isLoading,
});

const setEdit = (edit: boolean): ReducerActions => ({
  type: ActionTypes.SET_EDIT,
  edit,
});

const setAlert = (alert?: string): ReducerActions => ({
  type: ActionTypes.SET_ALERT,
  alert,
});

const setDisabled = (disabled: boolean): ReducerActions => ({
  type: ActionTypes.SET_DISABLED,
  disabled,
});

export const reducer = (state: State, action: ReducerActions): State => {
  switch (action.type) {
    case ActionTypes.SET_KNO2_INTEGRATION_ORDER_FORM:
      const { kno2IntegrationOrderForm } = action;
      return {
        ...state,
        kno2IntegrationOrderForm,
      };
    case ActionTypes.SET_ISLOADING:
      const { isLoading } = action;
      return {
        ...state,
        isLoading,
      };
    case ActionTypes.SET_EDIT:
      const { edit } = action;
      return {
        ...state,
        edit,
      };
    case ActionTypes.SET_ALERT:
      const { alert } = action;
      return {
        ...state,
        alert,
      };
    case ActionTypes.SET_DISABLED:
      const { disabled } = action;
      return {
        ...state,
        disabled,
      };
    default:
      return state;
  }
};

const Kno2IntegrationOrderForm = (props: Kno2IntegrationOrderFormProps) => {
  const { kno2IntegrationId } = useParams<{ kno2IntegrationId: string }>();
  const initialState: State = {
    kno2IntegrationOrderForm: {
      kno2IntegrationId: kno2IntegrationId ?? '',
      version2: '',
      singleOrMultiAgency: '',
      sponsoringEntity: '',
      locationLegalEntityBusinessName: '',
      orderDate: dateTimeToDateInputString(new Date()),
      goLiveDate: dateTimeToDateInputString(new Date()),
      invoicingMetric: '',
      metricQuantity: 0,
      notes: '',
      kno2Integration: {
        id: '',
        clientAccountId: '',
        grantedUseForAccessWithKno2: false,
        isActivatedOnKno2Network: false,
        kno2OrganizationId: '',
        primaryContactFirstName: '',
        primaryContactLastName: '',
        primaryContactEmail: '',
        primaryContactPhone: '',
        primaryContactRole: '',
        primaryContactJobFunctionId: 0,
        organizationName: '',
        organizationSubdomain: '',
        organizationTypeId: '',
        organizationSpecialtyId: '',
        organizationEHR: '',
        organizationStreet: '',
        organizationCity: '',
        organizationState: '',
        organizationPostalCode: '',
        createdOnUtc: new Date(),
        modifiedOnUtc: new Date(),
        createFirstUserFromPrimaryContact: false,
      },
      createdOnUtc: new Date(),
      createdBy: '',
      modifiedBy: '',
    },
    isLoading: true,
    edit: false,
    disabled: false,
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const apiClient = useApiClient();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setEdit(!!props.isCreate));
    const fetchData = async () => {
      if (kno2IntegrationId) {
        const kno2IntegrationOrderFormResult =
          await apiClient.HihHelperClient.GetKno2IntegrationOrderFormByKno2IntegrationId(kno2IntegrationId);
        if (kno2IntegrationOrderFormResult) {
          dispatch(setKno2IntegrationOrderForm(kno2IntegrationOrderFormResult));
        } else {
          // @TODO: What if we go to a bad route?
          //navigate(routes.accounts);
        }
      }
      dispatch(setIsLoading(false));
    };

    fetchData();
  }, [apiClient.HihHelperClient, apiClient.OutcomesServiceClient, kno2IntegrationId, props.isCreate]);

  // const handleEditClick = (e: React.MouseEvent) => {
  //   e.preventDefault();
  //   dispatch(setEdit(true));
  // };

  const handleSaveAndDownloadClick = async (values: CreateKno2IntegrationOrderFormModel) => {
    dispatch(setDisabled(true));
    try {
      if (props.isCreate) {
        var response = await apiClient.HihHelperClient.PostKno2IntegrationOrderForm(values);
        await apiClient.HihHelperClient.ExportKno2IntegrationOrderFormCsv(response);
        navigate(
          routes.kno2_integration_order_form_details.replace(
            ':kno2IntegrationId',
            state.kno2IntegrationOrderForm.kno2IntegrationId,
          ),
        );
      }
    } catch (error) {
      dispatch(setAlert('Error occurred while saving Kno2 Integration Order Form.'));
      dispatch(setDisabled(false));
    }
  };

  const handleExportClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (state.kno2IntegrationOrderForm.id) {
      apiClient.HihHelperClient.ExportKno2IntegrationOrderFormCsv(state.kno2IntegrationOrderForm.id);
    }
  };

  return (
    <div>
      <Row>
        <Col>
          <h1>{props.isCreate && 'Create '}Kno2 Integration Order Form</h1>
        </Col>
      </Row>
      <hr />
      {state.isLoading ? (
        <ImageTrendForm.LoadingSpinner />
      ) : (
        <>
          <Formik
            enableReinitialize={true}
            initialValues={state.kno2IntegrationOrderForm}
            validationSchema={kno2IntegrationOrderFormSchema}
            onSubmit={async (values) => {
              dispatch(setDisabled(true));
              try {
                if (props.isCreate) {
                  await apiClient.HihHelperClient.PostKno2IntegrationOrderForm(
                    values as CreateKno2IntegrationOrderFormModel,
                  );
                  navigate(
                    routes.kno2_integration_order_form_details.replace(
                      ':kno2IntegrationId',
                      state.kno2IntegrationOrderForm.kno2IntegrationId,
                    ),
                  );
                }
              } catch (error) {
                dispatch(setAlert('Error occurred while saving Kno2 Integration Order Form.'));
                dispatch(setDisabled(false));
              }
            }}
          >
            {({ handleSubmit, isSubmitting, values, setTouched, validateForm }) => (
              <Form className="form-horizontal" onSubmit={handleSubmit}>
                <Alert show={!!state.alert} variant="danger" dismissible onClose={() => dispatch(setAlert())}>
                  {state.alert}
                </Alert>
                <ImageTrendForm.HiddenGroup controlId="kno2IntegrationId">
                  <ImageTrendForm.Hidden defaultValue={state.kno2IntegrationOrderForm.kno2IntegrationId} />
                </ImageTrendForm.HiddenGroup>
                <ImageTrendForm.Group controlId="version2">
                  <ImageTrendForm.Label>Version</ImageTrendForm.Label>
                  <ImageTrendForm.Field isEditable={state.edit} name="version2" />
                </ImageTrendForm.Group>
                <ImageTrendForm.Group controlId="singleOrMultiAgency">
                  <ImageTrendForm.Label required>Single or Multi Agency</ImageTrendForm.Label>
                  <ImageTrendForm.Field isEditable={state.edit} name="singleOrMultiAgency" />
                </ImageTrendForm.Group>
                <ImageTrendForm.Group controlId="sponsoringEntity">
                  <ImageTrendForm.Label>Sponsoring Entity</ImageTrendForm.Label>
                  <ImageTrendForm.Field isEditable={state.edit} name="sponsoringEntity" />
                </ImageTrendForm.Group>
                <ImageTrendForm.Group controlId="locationLegalEntityBusinessName">
                  <ImageTrendForm.Label required>Location, Legal Entity, or Business Name</ImageTrendForm.Label>
                  <ImageTrendForm.Field isEditable={state.edit} name="locationLegalEntityBusinessName" />
                </ImageTrendForm.Group>
                <ImageTrendForm.Group controlId="orderDate">
                  <ImageTrendForm.Label required>Order Date</ImageTrendForm.Label>
                  <ImageTrendForm.Field type="date" isEditable={state.edit} name="orderDate" />
                </ImageTrendForm.Group>
                <ImageTrendForm.Group controlId="goLiveDate">
                  <ImageTrendForm.Label required>Go-Live Date</ImageTrendForm.Label>
                  <ImageTrendForm.Field type="date" isEditable={state.edit} name="goLiveDate" />
                </ImageTrendForm.Group>
                <ImageTrendForm.Group controlId="invoicingMetric">
                  <ImageTrendForm.Label required>Invoicing Metric</ImageTrendForm.Label>
                  <ImageTrendForm.Field isEditable={state.edit} name="invoicingMetric" />
                </ImageTrendForm.Group>
                <ImageTrendForm.Group controlId="metricQuantity">
                  <ImageTrendForm.Label required>Metric Quantity</ImageTrendForm.Label>
                  <ImageTrendForm.Field type="number" isEditable={state.edit} name="metricQuantity" />
                </ImageTrendForm.Group>
                <ImageTrendForm.Group controlId="notes">
                  <ImageTrendForm.Label>Notes</ImageTrendForm.Label>
                  <ImageTrendForm.Field isEditable={state.edit} name="notes" />
                </ImageTrendForm.Group>
                {!props.isCreate && (
                  <>
                    <ImageTrendForm.Group controlId="createdOn">
                      <ImageTrendForm.Label>Created On</ImageTrendForm.Label>
                      <ImageTrendForm.Plaintext
                        value={dateTimeUtcToLocalString(state.kno2IntegrationOrderForm.createdOnUtc)}
                      />
                    </ImageTrendForm.Group>
                    <ImageTrendForm.Group controlId="createdBy">
                      <ImageTrendForm.Label>Created By</ImageTrendForm.Label>
                      <ImageTrendForm.Plaintext value={state.kno2IntegrationOrderForm.createdBy} />
                    </ImageTrendForm.Group>
                    <ImageTrendForm.Group controlId="modifiedOn">
                      <ImageTrendForm.Label>Modified On</ImageTrendForm.Label>
                      <ImageTrendForm.Plaintext
                        value={dateTimeUtcToLocalString(state.kno2IntegrationOrderForm.modifiedOnUtc)}
                      />
                    </ImageTrendForm.Group>
                    <ImageTrendForm.Group controlId="modifiedBy">
                      <ImageTrendForm.Label>Modified By</ImageTrendForm.Label>
                      <ImageTrendForm.Plaintext value={state.kno2IntegrationOrderForm.modifiedBy} />
                    </ImageTrendForm.Group>
                  </>
                )}
                {!!props.isCreate ? (
                  <Dropdown as={ButtonGroup}>
                    <CreateButton disabled={isSubmitting || state.disabled} type="submit" />

                    <Dropdown.Toggle split>
                      <span className="visually-hidden">Toggle Dropdown</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={async () => {
                          // Fancy alternate save with validation.
                          const errors = await validateForm();
                          if (Object.keys(errors).length === 0) {
                            await handleSaveAndDownloadClick(values);
                          } else {
                            await setTouched(
                              setNestedObjectValues<FormikTouched<Kno2IntegrationOrderFormModel>>(errors, true),
                            );
                          }
                        }}
                        disabled={isSubmitting || state.disabled}
                      >
                        Create and Download
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : state.edit ? (
                  <Button disabled={isSubmitting || state.disabled} type="submit">
                    Save
                  </Button>
                ) : (
                  <div>
                    {/* <ButtonGroup className="me-2">
                      <Button onClick={handleEditClick}>Edit</Button>
                    </ButtonGroup> */}

                    <ButtonGroup>
                      <Button variant="secondary" onClick={handleExportClick}>
                      Download File
                      </Button>
                    </ButtonGroup>
                  </div>
                )}
              </Form>
            )}
          </Formik>
          <hr />
          <BackButton
            to={routes.kno2_integration_details.replace(
              ':kno2IntegrationId',
              state.kno2IntegrationOrderForm.kno2IntegrationId,
            )}
          />
        </>
      )}
    </div>
  );
};

export default Kno2IntegrationOrderForm;
