import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';

interface StatusCodeProps {
  dispatch: React.Dispatch<{ type: string; payload?: any }>;
  filters: {
    statusCode: string;
  };
  statusOptions: { value: number; label: string }[];
}

const StatusCode: React.FC<StatusCodeProps> = ({ dispatch, filters, statusOptions }) => {
  const options = statusOptions.map(option => ({
    value: option.value,
    label: option.label,
  }));

  const selectedOption = options.find(option => option.value.toString() === filters.statusCode);

  const handleChange = (selectedOption: any) => {
    dispatch({ type: 'SET_STATUS_CODE', payload: selectedOption ? selectedOption.value.toString() : '' });
  };

  return (
    <Col md={6}>
      <Form.Group>
        <Form.Label htmlFor="status-code-select">Status:</Form.Label>
        <Select
          inputId="status-code-select"
          value={selectedOption || null}
          onChange={handleChange}
          options={options}
          placeholder="Select Status"
          isClearable
        />
      </Form.Group>
    </Col>
  );
};

export default StatusCode;
