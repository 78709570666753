const api = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  hihHelperUrl: process.env.REACT_APP_HIH_HELPER_API_URL,
  ingressServiceUrl: process.env.REACT_APP_INGRESS_SERVICE_API_URL,
  outcomesServiceUrl: process.env.REACT_APP_OUTCOMES_SERVICE_API_URL,
  transactionServiceUrl: process.env.REACT_APP_TRANSACTION_SERVICE_API_URL,
  msal_api_domain: process.env.REACT_APP_MSAL_API_DOMAIN,
  msal_client_id: process.env.REACT_APP_MSAL_CLIENT_ID,
};

export default api;
