import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import { Outlet } from "react-router";
import { IsAccountAdmin } from "./RoleHelper";

const RequireAdminRoleRoute = () => {
    const {instance} = useMsal();
    const [userIsAdmin, setUserIsAdmin] = useState(false);
    
    useEffect(() => {
      const activeAccount = instance.getActiveAccount();
      const hasAdminRole = !!activeAccount && IsAccountAdmin(activeAccount);
      if (hasAdminRole !== userIsAdmin) {
        setUserIsAdmin(hasAdminRole);
      }
    }, [instance, userIsAdmin, setUserIsAdmin]);

    if (userIsAdmin) {
      return <Outlet />;
    }
  
    return <Alert variant="warning">Access Denied</Alert>;
  };
  
  export default RequireAdminRoleRoute;
  