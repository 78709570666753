import { IPublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser';
import axios, { AxiosInstance, CreateAxiosDefaults } from 'axios';
import { apiRequest, loginRequest } from '../../authConfig';
import { ApiClientProps } from './ApiClient';

export async function getTokenRequest(msalInstance: IPublicClientApplication) {
  return await msalInstance.acquireTokenSilent(apiRequest).catch(async (error) => {
    if (error instanceof InteractionRequiredAuthError) {
      // fallback to interaction when silent call fails
      await msalInstance.acquireTokenPopup(loginRequest).catch(async (error) => {
        if (error instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          await msalInstance.acquireTokenRedirect(loginRequest);
          return await msalInstance.acquireTokenSilent(apiRequest);
        }
      });
      return await msalInstance.acquireTokenSilent(apiRequest);
    }
  });
}

export default function getAxiosFunctions({ instance, toastRef }: ApiClientProps) {
  // Generates our token when the functions are called.
  const getAxios = async (options?: CreateAxiosDefaults): Promise<AxiosInstance> => {
    const request = await getTokenRequest(instance);
    if (!request) {
      throw new Error('Unable to get token.');
    }

    options = {
      ...options,
      headers: {
        Authorization: `Bearer ${request.accessToken}`,
      },
    };

    const axiosInstance = axios.create(options);

    axiosInstance.interceptors.request.use(
      (config) => {
        return config;
      },
      (error) => {
        console.error('Request Error:', error);
        return Promise.reject(error);
      },
    );

    axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        console.error('Response Error:', error);
        return Promise.reject(error);
      },
    );

    return axiosInstance;
  };

  const getData = async (endpoint: string): Promise<any> => {
    const fetcher = await getAxios({
      validateStatus: (status) => (status >= 200 && status < 300) || status === 404 || status === 403 || status === 500,
    });
    const response = await fetcher.get(endpoint);
    if (response.status === 404) {
      return null;
    } else if (response.status === 403) {
      toastRef.current?.create403();
      throw new Error();
    } else if (response.status === 500) {
      toastRef.current?.create500();
      throw new Error();
    }
    return response.data;
  };

  const getFile = async (endpoint: string): Promise<any> => {
    const fetcher = await getAxios({
      validateStatus: (status) => (status >= 200 && status < 300) || status === 403 || status === 500,
      // Essential to the file portion
      responseType: 'blob',
    });
    const response = await fetcher.get(endpoint);
    if (response.status === 403) {
      toastRef.current?.create403();
      throw new Error();
    } else if (response.status === 500) {
      toastRef.current?.create500();
      throw new Error();
    }

    // Get the file name.
    // @TODO: Probably these these headers!!!
    const fileName = response.headers['content-disposition'].match(/filename="?([A-Za-z0-9-_,\s.]+)"?(;|$)/)[1];
    const fileType = response.headers['content-type'];
    // For the single IE user out there... ;)
    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
      (window.navigator as any).msSaveOrOpenBlob(new Blob([response.data], { type: fileType }), fileName);
    } else {
      const url = window.URL.createObjectURL(new Blob([response.data], { type: fileType }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      // Clean up element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  };

  const postData = async (endpoint: string, data?: any) => {
    const fetcher = await getAxios({
      validateStatus: (status) => (status >= 200 && status < 300) || status === 403 || status === 500,
    });
    const response = await fetcher.post(endpoint, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 403) {
      toastRef.current?.create403();
      throw new Error();
    } else if (response.status === 500) {
      toastRef.current?.create500();
      throw new Error();
    }
    return response.data;
  };

  const postResponse = async (endpoint: string, data?: any) => {
    const fetcher = await getAxios({
      validateStatus: (status) => (status >= 200 && status < 300) || status === 400 || status === 403 || status === 500,
    });
    const response = await fetcher.post(endpoint, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 403) {
      toastRef.current?.create403();
      throw new Error();
    } else if (response.status === 500) {
      toastRef.current?.create500();
      throw new Error();
    }
    return response;
  };

  const putData = async (endpoint: string, data?: any) => {
    const fetcher = await getAxios({
      validateStatus: (status) => (status >= 200 && status < 300) || status === 403 || status === 500,
    });
    const response = await fetcher.put(endpoint, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 403) {
      toastRef.current?.create403();
      throw new Error();
    } else if (response.status === 500) {
      toastRef.current?.create500();
      throw new Error();
    }
    return response.data;
  };

  const patchData = async (endpoint: string, data?: any) => {
    const fetcher = await getAxios({
      validateStatus: (status) => (status >= 200 && status < 300) || status === 403 || status === 500,
    });
    const response = await fetcher.patch(endpoint, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 403) {
      toastRef.current?.create403();
      throw new Error();
    } else if (response.status === 500) {
      toastRef.current?.create500();
      throw new Error();
    }
    return response.data;
  };

  const deleteData = async (endpoint: string): Promise<any> => {
    const fetcher = await getAxios({
      validateStatus: (status) => (status >= 200 && status < 300) || status === 403 || status === 500,
    });
    const response = await fetcher.delete(endpoint);
    if (response.status === 403) {
      toastRef.current?.create403();
      throw new Error();
    } else if (response.status === 500) {
      toastRef.current?.create500();
      throw new Error();
    }
    return response.data;
  };

  return {
    getData,
    getFile,
    postData,
    postResponse,
    putData,
    deleteData,
    patchData,
  };
}
