const routes = Object.freeze({
  // From incoming code
  accounts: '/accounts',
  audits: '/audits',
  audits_nemsisfilerecord: '/audits/nemsisfilerecord',
  audits_nemsissubmitdata: '/audits/nemsissubmitdata',
  client_accounts: '/accounts/client-accounts',
  client_accounts_create: '/accounts/client-accounts/create',
  client_accounts_details: '/accounts/client-accounts/:id',
  dashboard: '/',
  destination_mappings: '/resource-manager/destination-mappings',
  destination_mappings_create: '/resource-manager/destination-mappings/create/:kno2SenderConfigurationId',
  destination_mappings_details: '/resource-manager/destination-mappings/:destinationMappingId',
  kno2_integration_create: '/accounts/client-accounts/:clientId/kno2-integration/create',
  kno2_integration_details: '/accounts/kno2-integration/:kno2IntegrationId',
  kno2_integration_order_form_create: '/accounts/client-accounts/kno2-integration/:kno2IntegrationId/order-form/create',
  kno2_integration_order_form_details: '/accounts/client-accounts/kno2-integration/:kno2IntegrationId/order-form',
  kno2_module_view_via_transaction: '/transactions/kno2-transactions/:transactionId/kno2-module/:moduleId',
  kno2_outcome_users_create: '/accounts/client-accounts/:clientId/kno2-outcome-user/create',
  kno2_outcome_users_view: '/accounts/client-accounts/:clientId/kno2-outcome-user',
  kno2_standard_module_view: '/accounts/client-accounts/:clientId/kno2-module/:moduleId',
  kno2_transactions: '/transactions/kno2-transactions',
  kno2_transactions_details: '/transactions/kno2-transactions/:id',
  kno2_wizard: '/wizard',
  login: '/login',
  resource_manager: '/resource-manager',
  settings: '/settings',
  transactions: '/transactions',
  transactions_transactionRecord: '/transactions/transactionrecord',
  transactions_transactionRecord_details: '/transactions/transactionrecord/transactionrecorddetails/:id',
  submission_results: '/submission-results',
});

export default routes;
