import { useReducer } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ImageTrendForm from '../../Components/common/ImageTrendForm';
import NemsisConfigurationGeneratePasswordModal from './NemsisConfigurationGeneratePasswordModal';

interface GeneratePasswordFieldProps {
  id: string;
}

enum ActionTypes {
  SET_SHOW_MODAL,
  SET_PASSWORD,
}

type State = {
  showModal: boolean;
  password?: string;
};

const initialState: State = {
  showModal: false,
};

type SetShowModal = {
  type: typeof ActionTypes.SET_SHOW_MODAL;
  showModal: boolean;
};

type SetPassword = {
  type: typeof ActionTypes.SET_PASSWORD;
  password: string;
};
type ReducerActions = SetShowModal | SetPassword;

const setShowModal = (showModal: boolean): ReducerActions => ({
  type: ActionTypes.SET_SHOW_MODAL,
  showModal,
});

const setPassword = (password: string): ReducerActions => ({
  type: ActionTypes.SET_PASSWORD,
  password,
});

export const reducer = (state: State, action: ReducerActions): State => {
  switch (action.type) {
    case ActionTypes.SET_SHOW_MODAL:
      const { showModal } = action;
      return {
        ...state,
        showModal,
      };
    case ActionTypes.SET_PASSWORD:
      const { password } = action;
      return {
        ...state,
        password,
      };
    default:
      return state;
  }
};

const NemsisConfigurationPasswordField = ({ id }: GeneratePasswordFieldProps): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ImageTrendForm.Group controlId="nemsisConfigurationPassword">
      <NemsisConfigurationGeneratePasswordModal
        id={id}
        show={state.showModal}
        toggleShow={() => dispatch(setShowModal(!state.showModal))}
        successCallback={(password) => dispatch(setPassword(password))}
      />
      <ImageTrendForm.Label>Password</ImageTrendForm.Label>
      <div>
        {state.password && (
          <>
            <ImageTrendForm.Password initiallyOpen={true} value={state.password} />
            <Form.Text>This information will be unavailable after leaving this page.</Form.Text>
          </>
        )}
        {!state.password && (
          <>
            <ImageTrendForm.Plaintext
              className="fst-italic"
              value="This information is not available and can only be re-generated."
            />
            <Button onClick={() => dispatch(setShowModal(true))}>Generate New</Button>
          </>
        )}
      </div>
    </ImageTrendForm.Group>
  );
};
export default NemsisConfigurationPasswordField;
