import Pagination from 'react-bootstrap/Pagination';

export const getPaginationItems = (
  activePage: number,
  totalPages: number,
  handleClickFunction: (e: number) => void,
) => {
  let cuPageItems = [];
  for (let number = 1; number <= totalPages; number++) {
    cuPageItems.push(
      <Pagination.Item key={number} active={number === activePage} onClick={() => handleClickFunction(number)}>
        {number}
      </Pagination.Item>,
    );
  }
  return cuPageItems;
};

const frontEndNumbers = 1;
const surroundingNumbers = 1;

// @TODO: Sorta works, if you have a lot of items in a list, but its size is inconsistent.
export const getPaginationItems2 = (
  activePage: number,
  totalPages: number,
  handleClickFunction: (e: number) => void,
) => {
  let cuPageItems = [];
  for (let number = 1; number <= Math.min(totalPages, frontEndNumbers); number++) {
    cuPageItems.push(getPaginationItem(number, activePage, handleClickFunction));
  }

  if (activePage - frontEndNumbers > frontEndNumbers + 1) {
    cuPageItems.push(getPaginationEllipsis(frontEndNumbers + 1));
  }

  for (
    let number = Math.max(frontEndNumbers + 1, activePage - frontEndNumbers);
    number <= Math.min(totalPages, activePage + frontEndNumbers);
    number++
  ) {
    cuPageItems.push(getPaginationItem(number, activePage, handleClickFunction));
  }

  if (activePage + surroundingNumbers < totalPages - surroundingNumbers) {
    cuPageItems.push(getPaginationEllipsis(totalPages - frontEndNumbers - 1));
  }

  for (let number = Math.max(totalPages, activePage + surroundingNumbers + 1); number <= totalPages; number++) {
    cuPageItems.push(getPaginationItem(number, activePage, handleClickFunction));
  }
  return cuPageItems;
};

const getPaginationItem = (number: number, activePage: number, handleClickFunction: (e: number) => void) => {
  return (
    <Pagination.Item key={number} active={number === activePage} onClick={() => handleClickFunction(number)}>
      {number}
    </Pagination.Item>
  );
};

const getPaginationEllipsis = (index: number) => {
  return <Pagination.Ellipsis key={index} />;
};

export const getPaginationShowingString = (pageNumber: number, pageLength: number, listLength: number) => {
  let showString = 'Showing ' + ((pageNumber - 1) * pageLength + 1) + ' - ';
  showString += pageNumber * pageLength < listLength ? pageNumber * pageLength : listLength;
  showString += ' of ' + listLength;
  return showString;
};
