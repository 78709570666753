import { Buffer } from 'buffer';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TransactionRecordModel } from '../../Models/TransactionService/TransactionRecordModel';
import '../../Styling/index.css';
import { useApiClient } from '../../API/useApiClient';

interface TransactionRecordDetailsProps {}

const TransactionRecordDetails: React.FC<TransactionRecordDetailsProps> = () => {
  const { id } = useParams<{ id: any }>();
  const [details, setDetails] = useState<TransactionRecordModel>();
  const apiClient = useApiClient();

  useEffect(() => {
    const fetchData = async () => {
      const transactionRecord = await apiClient.TransactionServiceClient.GetTransactionRecord(id);
      setDetails(transactionRecord);
    };

    fetchData();
  }, [apiClient.TransactionServiceClient, id]);

  // @TODO: We can probably move this somewhere more pertinent.
  function decodeBase64(base64String: string) {
    try {
      const buffer = Buffer.from(base64String, 'base64');
      return buffer.toString('utf-8');
    } catch (error) {
      console.error('Error decoding base64:', error);
      return 'Invalid base64 data';
    }
  }

  return (
    <div>
      <h1>Transaction Record Details</h1>
      {details ? (
        <div>
          <div>
            <p>Transaction Record ID: {details.id}</p>
          </div>
          <div>
            <p>Correlation ID: {details.correlationId}</p>
          </div>
          <div>
            <p>Module ID: {details.messageModuleId}</p>
          </div>
          <div>
            <p>Incident ID: {details.incidentId}</p>
          </div>
          <div>
            <p>Created On (UTC): {details.createdOnUtc}</p>
          </div>
          <div>
            <p>Transaction Status: {details.transactionStatus}</p>
          </div>
          <div>
            <p>Transaction Milliseconds: {details.transactionMilliseconds}</p>
          </div>
          <div>
            <p>Delivery Attempts: {details.deliveryAttempts}</p>
          </div>
          <div>
            <p>Message: {details.message ? decodeBase64(details.message) : ''}</p>
          </div>
        </div>
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};

export default TransactionRecordDetails;
