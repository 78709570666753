import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

interface DateRangeProps {
  dispatch: React.Dispatch<{ type: string; payload?: any }>;
  filters: {
    startDate: string;
    endDate: string;
  };
}

const DateRange: React.FC<DateRangeProps> = ({ dispatch, filters }) => {
  return (
    <div>
      <Row>
        <Col md={6}>
          <Form.Group controlId="start-date">
            <Form.Label>Start Date:</Form.Label>
            <Form.Control
              type="date"
              value={filters.startDate}
              onChange={(e) => dispatch({ type: 'SET_START_DATE', payload: e.target.value })}
              aria-label="Select start date"
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="end-date">
            <Form.Label>End Date:</Form.Label>
            <Form.Control
              type="date"
              value={filters.endDate}
              onChange={(e) => dispatch({ type: 'SET_END_DATE', payload: e.target.value })}
              aria-label="Select end date"
            />
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

export default DateRange;
