import { useEffect, useReducer } from 'react';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import { FaArrowLeft } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import { useApiClient } from '../../API/useApiClient';
import ImageTrendForm from '../../Components/common/ImageTrendForm';
import { dateTimeUtcToLocalString } from '../../Helpers/DateTimeHelper';
import { Kno2TransactionDetail } from '../../Models/TransactionService/Kno2Transaction';
import routes from '../../common/routesDefinitions';
import Kno2StatusIcon, { Kno2TransactionStatusEnum } from './Kno2TransactionStatusIcon';

enum ActionTypes {
  SET_KNO2_TRANSACTION,
  SET_ISLOADING,
  SET_KNO2_STATUS,
}

type State = {
  kno2Transaction: Kno2TransactionDetail;
  isLoading: boolean;
  kno2Status?: string;
};

type SetKno2TransactionAction = {
  type: typeof ActionTypes.SET_KNO2_TRANSACTION;
  kno2Transaction: Kno2TransactionDetail;
};

type SetIsLoading = {
  type: typeof ActionTypes.SET_ISLOADING;
  isLoading: boolean;
};

type SetKno2Status = {
  type: typeof ActionTypes.SET_KNO2_STATUS;
  kno2Status?: string;
};

type ReducerActions = SetKno2TransactionAction | SetIsLoading | SetKno2Status;

const setKno2Transaction = (kno2Transaction: Kno2TransactionDetail): ReducerActions => ({
  type: ActionTypes.SET_KNO2_TRANSACTION,
  kno2Transaction,
});

const setIsLoading = (isLoading: boolean): ReducerActions => ({
  type: ActionTypes.SET_ISLOADING,
  isLoading,
});

const setKno2Status = (kno2Status?: string): ReducerActions => ({
  type: ActionTypes.SET_KNO2_STATUS,
  kno2Status,
});

export const reducer = (state: State, action: ReducerActions): State => {
  switch (action.type) {
    case ActionTypes.SET_KNO2_TRANSACTION:
      const { kno2Transaction } = action;
      return {
        ...state,
        kno2Transaction,
      };
    case ActionTypes.SET_ISLOADING:
      const { isLoading } = action;
      return {
        ...state,
        isLoading,
      };
    case ActionTypes.SET_KNO2_STATUS:
      const { kno2Status } = action;
      return {
        ...state,
        kno2Status,
      };
    default:
      return state;
  }
};

const Kno2TransactionDetails = () => {
  const initialState: State = {
    kno2Transaction: {
      correlationId: '',
      kno2OrganizationId: '',
      toAddress: '',
      kno2MessageId: '',
      fromAddress: '',
      messageJson: null,
      ingressNemsisFileRecordId: 0,
      errorReason: '',
      canResend: false,
      audit: {
        auditTrail: [],
        moduleId: '',
        createdOnUtc: new Date(),
      },
      id: 0,
      module: {
        id: '',
        name: '',
      },
      status: {
        name: '',
        value: 0,
      },
      createdOnUtc: new Date(),
    },
    isLoading: true,
    kno2Status: '',
  };
  const { id } = useParams();
  const apiClient = useApiClient();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const getDetail = async () => {
      if (id) {
        const details = await apiClient.TransactionServiceClient.GetKno2TransactionDetail(id);
        dispatch(setKno2Transaction(details));
      }
      dispatch(setIsLoading(false));
    };

    getDetail();
  }, [id, apiClient.TransactionServiceClient]);

  useEffect(() => {
    const getKno2TransactionStatus = async () => {
      if (
        id &&
        !state.isLoading &&
        state.kno2Transaction.status.value === Kno2TransactionStatusEnum.CompleteFailedDelivery
      ) {
        try {
          const status = await apiClient.TransactionServiceClient.GetKno2TransactionMessageStatusError(id);
          if (!status?.reason) {
            throw new Error();
          }
          dispatch(setKno2Status(status.reason));
        } catch (err) {
          dispatch(setKno2Status('Failed to retrieve error'));
        }
      }
    };

    getKno2TransactionStatus();
  }, [id, state.isLoading, apiClient.TransactionServiceClient, state.kno2Transaction.status.value]);

  return (
    <div>
      <h1>Kno2 Transaction Details</h1>
      <br />
      {state.isLoading ? (
        <ImageTrendForm.LoadingSpinner />
      ) : (
        <>
          <ListGroup>
            <ImageTrendForm.ListView title="Incident ID" value={state.kno2Transaction.incidentId} />
            <ImageTrendForm.ListView
              title="Module Name"
              value={state.kno2Transaction.module.name}
              linkTo={routes.kno2_module_view_via_transaction
                .replace(':transactionId', state.kno2Transaction.id.toString())
                .replace(':moduleId', state.kno2Transaction.module.id)}
            />
            {
              // @TODO: Needs to have destination information (i.e. location)
            }
            <ImageTrendForm.ListView title="To Address" value={state.kno2Transaction.toAddress} />
            <ImageTrendForm.ListView
              title="Status"
              value={
                <>
                  <Kno2StatusIcon statusCode={state.kno2Transaction.status.value} /> {state.kno2Transaction.status.name}
                </>
              }
            />
            {state.kno2Transaction.status.value === Kno2TransactionStatusEnum.CompleteFailedDelivery && (
              <ImageTrendForm.ListView
                title="Error Reason"
                value={
                  !state.kno2Status ? (
                    <span className="d-flex align-items-center">
                      <ImageTrendForm.Spinner />
                      &nbsp;Fetching error message&hellip;
                    </span>
                  ) : (
                    state.kno2Status
                  )
                }
              />
            )}
            <ImageTrendForm.ListView
              title="Created On"
              value={dateTimeUtcToLocalString(state.kno2Transaction.createdOnUtc)}
            />
            <ImageTrendForm.ListView title="Kno2 Message Status" value={state.kno2Transaction.kno2MessageStatus} />
            <ImageTrendForm.ListView
              title="Last Audit Message"
              value={state.kno2Transaction.audit.auditTrail[state.kno2Transaction.audit.auditTrail.length - 1]}
            />
          </ListGroup>
          <br />
          <h2>Audit Trail</h2>
          <ListGroup>
            {state.kno2Transaction.audit.auditTrail.map((e, i) => (
              <ListGroup.Item key={i}>{e}</ListGroup.Item>
            ))}
          </ListGroup>
          <br />
          {
            <>
              <h2>Message Data</h2>
              <ListGroup>
                <ImageTrendForm.ListView
                  type="textarea"
                  title="Message Data"
                  value={JSON.stringify(JSON.parse(state.kno2Transaction.messageJson || 'null'), null, 4)}
                />
                <ImageTrendForm.ListView title="From Address" value={state.kno2Transaction.fromAddress} />
                <ImageTrendForm.ListView
                  title="Message Build Time (ms)"
                  value={state.kno2Transaction.messageBuildMilliseconds}
                />
                <ImageTrendForm.ListView
                  title="Message Send Time (ms)"
                  value={state.kno2Transaction.messageSendMilliseconds}
                />
              </ListGroup>
              <br />
            </>
          }
          <h2>Attachment Data</h2>
          {state.kno2Transaction.attachment ? (
            <>
              <ListGroup>
                <ImageTrendForm.ListView title="File Name" value={state.kno2Transaction.attachment?.fileName} />
                <ImageTrendForm.ListView
                  title="Message Type"
                  value={state.kno2Transaction.attachment?.messageType.name}
                />
                <ImageTrendForm.ListView title="MIME Type" value={state.kno2Transaction.attachment?.mimeType} />
                <ImageTrendForm.ListView
                  title="Created On"
                  value={dateTimeUtcToLocalString(state.kno2Transaction.attachment?.createdOnUtc)}
                />
              </ListGroup>
            </>
          ) : (
            <div>No attachment data found.</div>
          )}
          <br />
        </>
      )}
      <Link to={`${routes.kno2_transactions}`}>
        <Button variant="secondary">
          <FaArrowLeft /> Back
        </Button>
      </Link>
    </div>
  );
};

export default Kno2TransactionDetails;
