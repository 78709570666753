import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

interface ModuleIDProps {
  dispatch: React.Dispatch<{ type: string; payload?: any }>;
  filters: {
    moduleId: string;
  };
}

const ModuleID: React.FC<ModuleIDProps> = ({ dispatch, filters }) => {
  return (
    <Col md={6}>
      <Form.Group controlId="module-id">
        <Form.Label>Module ID:</Form.Label>
        <Form.Control
          type="text"
          value={filters.moduleId}
          onChange={(e) => dispatch({ type: 'SET_MODULE_ID', payload: e.target.value })}
          aria-label="Enter Module ID"
        />
      </Form.Group>
    </Col>
  );
};

export default ModuleID;
