import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import FormGroup from 'react-bootstrap/FormGroup';
import { FaPlus } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import Select, { SingleValue } from 'react-select';
import { useImmerReducer } from 'use-immer';
import { useApiClient } from '../../../API/useApiClient';
import routes from '../../../common/routesDefinitions';
import BackButton from '../../../Components/common/BackButton';
import ImageTrendForm from '../../../Components/common/ImageTrendForm';
import ImageTrendPagination from '../../../Components/common/ImageTrendPagination';
import ImageTrendTable from '../../../Components/common/ImageTrendTable';
import { DefaultPagination, PaginationReducer } from '../../../Components/common/Pagination.types';
import { dateTimeUtcToLocalString } from '../../../Helpers/DateTimeHelper';
import { DestinationMappingModel } from '../../../Models/TransactionService/DestinationMappingModel';

const DestinationMappingList = () => {
  // State contains optional parameter moduleId, containing a default selectedModuleId.
  const location = useLocation();
  const apiClient = useApiClient();
  const columnHelper = createColumnHelper<DestinationMappingModel>();
  const [moduleList, setModuleList] = useState<{ label: string; value: string }[]>([]);
  const [selectedKno2SenderConfigId, setSelectedKno2SenderConfigId] = useState<string | null>(
    location?.state?.kno2SenderConfigId ?? null,
  );
  const [destinationMappings, setDestinationMappings] = useState<DestinationMappingModel[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, updateSearchOptions] = useImmerReducer(PaginationReducer, DefaultPagination);

  const resultColumns: ColumnDef<DestinationMappingModel, any>[] = [
    columnHelper.accessor('id', {
      header: 'Id',
      cell: (info) => (
        <Link to={routes.destination_mappings_details.replace(':destinationMappingId', info.getValue().toString())}>
          {info.getValue()}
        </Link>
      ),
    }),
    columnHelper.accessor('destinationInfo.destinationName', {
      header: 'Name',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('destinationInfo.destinationCode', {
      header: 'Code',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('destinationInfo.directAddress', {
      header: 'Direct Address',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('createdOnUtc', {
      header: 'Created On',
      cell: (info) => dateTimeUtcToLocalString(new Date(info.getValue())),
    }),
  ];

  useEffect(() => {
    const fetchModuleList = async () => {
      const modules = await apiClient.TransactionServiceClient.GetKno2SenderConfigWithModuleNameDict();
      const moduleList = (modules ?? []).map((module: { kno2SenderConfigurationId: string; moduleName: string }) => ({
        value: module.kno2SenderConfigurationId,
        label: module.moduleName as string,
      }));
      setModuleList(moduleList);
    };

    fetchModuleList();
  }, [apiClient]);

  useEffect(() => {
    const fetchDestinationMappings = async () => {
      if (selectedKno2SenderConfigId) {
        setIsLoading(true);
        const destinationMappings =
          await apiClient.TransactionServiceClient.GetDestinationMappingsByKno2SenderConfigurationId(
            selectedKno2SenderConfigId,
          );
        setDestinationMappings(destinationMappings ?? []);
      }
      setIsLoading(false);
    };

    fetchDestinationMappings();
  }, [selectedKno2SenderConfigId, apiClient]);

  return (
    <div>
      <h1>Destination Mappings</h1>
      <br />
      {isLoading ? (
        <ImageTrendForm.LoadingSpinner />
      ) : (
        <>
          <FormGroup>
            <label htmlFor="moduleSelect">Select Module</label>
            <Select
              id="moduleSelect"
              options={moduleList}
              value={moduleList.find((module) => module.value === selectedKno2SenderConfigId) || null}
              onChange={(selectedOption: SingleValue<{ label: string; value: string }>) => {
                setSelectedKno2SenderConfigId(selectedOption?.value || null);
              }}
              inputValue=""
              onInputChange={() => {}}
              onMenuOpen={() => {}}
              onMenuClose={() => {}}
            />
          </FormGroup>
          {isLoading && selectedKno2SenderConfigId ? (
            <ImageTrendForm.LoadingSpinner />
          ) : (
            <>
              {destinationMappings.length > 0 ? (
                <div>
                  <ImageTrendTable columns={resultColumns} data={destinationMappings} striped={true} />
                  <ImageTrendPagination
                    paginationQuery={search}
                    updatePaginationQuery={updateSearchOptions}
                    total={destinationMappings.length}
                  />
                </div>
              ) : (
                <p>
                  <br /> No data available
                </p>
              )}
              {selectedKno2SenderConfigId && (
                <>
                  <Link
                    to={routes.destination_mappings_create.replace(
                      ':kno2SenderConfigurationId',
                      selectedKno2SenderConfigId,
                    )}
                  >
                    <Button variant="primary">
                      <FaPlus /> Create
                    </Button>
                  </Link>
                  <br />
                </>
              )}
            </>
          )}
          <br />
          <BackButton to={routes.resource_manager} />
        </>
      )}
    </div>
  );
};

export default DestinationMappingList;
