export enum OutcomeUserTypeEnum {
  Kno2 = 1,
  HL7 = 2,
}

interface OutcomeUserTypeValue {
  name: string;
  value: number;
}

export const OutcomeUserType: {
  [key in OutcomeUserTypeEnum]: OutcomeUserTypeValue;
} = {
  [OutcomeUserTypeEnum.Kno2]: {
    name: 'Kno2 Outcome User',
    value: OutcomeUserTypeEnum.Kno2,
  },
  [OutcomeUserTypeEnum.HL7]: {
    name: 'HL7 Http Outcome User',
    value: OutcomeUserTypeEnum.HL7,
  },
};
