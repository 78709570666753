import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useApiClient } from '../../API/useApiClient';
import { Kno2IntegrationModel } from '../../Models/HihClient/Kno2IntegrationModel';
import ImageTrendForm from '../common/ImageTrendForm';
import { ImageTrendOption } from '../common/ImageTrendSelect';
import { Kno2WizardFormData } from './Kno2Wizard';
import SelectFieldComponent from './SelectFieldComponent';

interface StepProps {
  formData: Kno2WizardFormData;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const Step2: React.FC<StepProps> = ({ formData, setFieldValue }) => {
  const apiClient = useApiClient();
  const location = useLocation();
  const [sourceTypes, setSourceTypes] = useState<ImageTrendOption<number>[]>([]);

  useEffect(() => {
    const fetchSourceTypes = async () => {
      try {
        const response = await apiClient.TransactionServiceClient.GetKno2SenderSourceTypes();
        const options = (response || []).map((type) => ({
          value: type.value,
          label: type.name,
        }));
        setSourceTypes(options);
      } catch (error) {
        console.error('Error fetching Kno2 Source Types:', error);
      }
    };

    fetchSourceTypes();
  }, [apiClient]);

  // @TODO: Remove this and move it to the default values.
  useEffect(() => {
    const { kno2Integration } = location.state || {};
    if (kno2Integration) {
      const integrationData = kno2Integration as Kno2IntegrationModel;
      setFieldValue('module.organizationId', integrationData.kno2OrganizationId || '', false);
    }
  }, [location.state, setFieldValue]);

  return (
    <div>
      <h2>Step 2: Kno2 Sender Configuration</h2>

      <ImageTrendForm.NormalGroup controlId="module.organizationId">
        <ImageTrendForm.Label required>Organization ID</ImageTrendForm.Label>
        <ImageTrendForm.Field name="module.organizationId" isEditable={true} />
      </ImageTrendForm.NormalGroup>

      <SelectFieldComponent
        required
        name="module.kno2ModuleAddress.kno2SourceType"
        label="Kno2 Source Type"
        options={sourceTypes}
      />

      <ImageTrendForm.NormalGroup controlId="module.kno2ModuleAddress.fromAddress">
        <ImageTrendForm.Label required>From Address</ImageTrendForm.Label>
        <ImageTrendForm.Field name="module.kno2ModuleAddress.fromAddress" isEditable={true} />
      </ImageTrendForm.NormalGroup>

      <ImageTrendForm.NormalGroup controlId="module.kno2ModuleAddress.defaultDestinationAddress">
        <ImageTrendForm.Label>Default Destination Address</ImageTrendForm.Label>
        <ImageTrendForm.Field name="module.kno2ModuleAddress.defaultDestinationAddress" isEditable={true} />
      </ImageTrendForm.NormalGroup>

      <ImageTrendForm.NormalGroup controlId="module.messageSubject">
        <ImageTrendForm.Label required>Message Subject</ImageTrendForm.Label>
        <ImageTrendForm.Field name="module.messageSubject" isEditable={true} />
        <div className="form-text phi-warning">Do not put PHI within this field</div>
      </ImageTrendForm.NormalGroup>

      <ImageTrendForm.NormalGroup controlId="module.eliteSite.url">
        <ImageTrendForm.Label required>Elite URL</ImageTrendForm.Label>
        <ImageTrendForm.Field
          name="module.eliteSite.url"
          isEditable={true}
          placeholder="Example: https://www.imagetrendelite.com/Elite"
        />
      </ImageTrendForm.NormalGroup>

      <ImageTrendForm.NormalGroup controlId="module.eliteSite.organization">
        <ImageTrendForm.Label required>Elite Organization</ImageTrendForm.Label>
        <ImageTrendForm.Field name="module.eliteSite.organization" isEditable={true} />
      </ImageTrendForm.NormalGroup>

      <ImageTrendForm.NormalGroup controlId="module.eliteIntegrationAccount.token">
        <ImageTrendForm.Label required>Integration Account Token</ImageTrendForm.Label>
        <ImageTrendForm.Field name="module.eliteIntegrationAccount.token" isEditable={true} />
      </ImageTrendForm.NormalGroup>

      <ImageTrendForm.NormalGroup controlId="module.eliteIntegrationAccount.hexPrivateKey">
        <ImageTrendForm.Label required>Integration Account Private Key Hexadecimal</ImageTrendForm.Label>
        <ImageTrendForm.Field
          name="module.eliteIntegrationAccount.hexPrivateKey"
          isEditable={true}
          placeholder="Example: 0x0123456789abcdef"
        />
      </ImageTrendForm.NormalGroup>
    </div>
  );
};

export default Step2;
