import { useEffect, useReducer } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { useParams } from 'react-router-dom';
import { useApiClient } from '../../API/useApiClient';
import routes from '../../common/routesDefinitions';
import BackButton from '../../Components/common/BackButton';
import CreateButton from '../../Components/common/CreateButton';
import ImageTrendForm from '../../Components/common/ImageTrendForm';
import { Kno2IntegrationModel } from '../../Models/HihClient/Kno2IntegrationModel';
import { Kno2IntegrationOrderFormModel } from '../../Models/TransactionService/Kno2IntegrationOrderFormModel';
import { dateTimeUtcToLocalString } from '../../Helpers/DateTimeHelper';

enum ActionTypes {
  SET_KNO2_INTEGRATION = 'SET_KNO2_INTEGRATION',
  SET_KNO2_INTEGRATION_ORDER_FORM = 'SET_KNO2_INTEGRATION_ORDER_FORM',
  SET_ORGANIZATION_TYPES = 'SET_ORGANIZATION_TYPES',
  SET_ORGANIZATION_SPECIALTIES = 'SET_ORGANIZATION_SPECIALTIES',
  SET_JOB_FUNCTIONS = 'SET_JOB_FUNCTIONS',
  SET_ISLOADING = 'SET_ISLOADING',
}

type State = {
  kno2Integration: Kno2IntegrationModel;
  kno2IntegrationOrderForm?: Kno2IntegrationOrderFormModel;
  organizationTypes: { key: string; value: string }[];
  organizationSpecialties: { key: string; value: string }[];
  jobFunctions: { name: string; id: number }[];
  isLoading: boolean;
};

export const initialState: State = {
  kno2Integration: {
    id: '',
    clientAccountId: '',
    grantedUseForAccessWithKno2: false,
    isActivatedOnKno2Network: false,
    kno2OrganizationId: '',
    primaryContactFirstName: '',
    primaryContactLastName: '',
    primaryContactEmail: '',
    primaryContactPhone: '',
    primaryContactRole: '',
    primaryContactJobFunctionId: 0,
    organizationName: '',
    organizationSubdomain: '',
    organizationTypeId: '',
    organizationSpecialtyId: '',
    organizationEHR: '',
    organizationStreet: '',
    organizationCity: '',
    organizationState: '',
    organizationPostalCode: '',
    createdOnUtc: new Date(),
    modifiedOnUtc: new Date(),
    createFirstUserFromPrimaryContact: false,
  },
  organizationTypes: [],
  organizationSpecialties: [],
  jobFunctions: [],
  isLoading: true,
};

type SetKno2IntegrationAction = {
  type: typeof ActionTypes.SET_KNO2_INTEGRATION;
  kno2Integration: Kno2IntegrationModel;
};

type SetKno2IntegrationOrderFormAction = {
  type: typeof ActionTypes.SET_KNO2_INTEGRATION_ORDER_FORM;
  kno2IntegrationOrderForm: Kno2IntegrationOrderFormModel;
};

type SetOrganizationTypesAction = {
  type: typeof ActionTypes.SET_ORGANIZATION_TYPES;
  organizationTypes: { key: string; value: string }[];
};

type SetOrganizationSpecialtiesAction = {
  type: ActionTypes.SET_ORGANIZATION_SPECIALTIES;
  organizationSpecialties: { key: string; value: string }[];
};

type SetJobFunctionsAction = {
  type: ActionTypes.SET_JOB_FUNCTIONS;
  jobFunctions: { name: string; id: number }[];
};

type SetIsLoading = {
  type: typeof ActionTypes.SET_ISLOADING;
  isLoading: boolean;
};

type ReducerActions =
  | SetKno2IntegrationAction
  | SetKno2IntegrationOrderFormAction
  | SetOrganizationTypesAction
  | SetOrganizationSpecialtiesAction
  | SetJobFunctionsAction
  | SetIsLoading;

const setKno2Integration = (kno2Integration: Kno2IntegrationModel): ReducerActions => ({
  type: ActionTypes.SET_KNO2_INTEGRATION,
  kno2Integration,
});

const setOrganizationTypes = (organizationTypes: { key: string; value: string }[]): ReducerActions => ({
  type: ActionTypes.SET_ORGANIZATION_TYPES,
  organizationTypes,
});

const setOrganizationSpecialties = (organizationSpecialties: { key: string; value: string }[]): ReducerActions => ({
  type: ActionTypes.SET_ORGANIZATION_SPECIALTIES,
  organizationSpecialties,
});

const setJobFunctions = (jobFunctions: { name: string; id: number }[]): ReducerActions => ({
  type: ActionTypes.SET_JOB_FUNCTIONS,
  jobFunctions,
});

const setKno2IntegrationOrderForm = (kno2IntegrationOrderForm: Kno2IntegrationOrderFormModel): ReducerActions => ({
  type: ActionTypes.SET_KNO2_INTEGRATION_ORDER_FORM,
  kno2IntegrationOrderForm,
});

const setIsLoading = (isLoading: boolean): ReducerActions => ({
  type: ActionTypes.SET_ISLOADING,
  isLoading,
});

const getOrganizationTypeName = (state: State, id: string) => {
  const type = state.organizationTypes.find((type) => type.value === id);
  return type ? type.key : 'Unknown Type';
};

const getOrganizationSpecialtyName = (state: State, id: string) => {
  const specialty = state.organizationSpecialties.find((specialty) => specialty.value === id);
  return specialty ? specialty.key : 'Unknown Specialty';
};

const getJobFunctionName = (state: State, id: number) => {
  const jobFunction = state.jobFunctions.find((jobFunction) => jobFunction.id === id);
  return jobFunction ? jobFunction.name : 'Unknown Job Function';
};

export const reducer = (state: State, action: ReducerActions): State => {
  switch (action.type) {
    case ActionTypes.SET_KNO2_INTEGRATION:
      const { kno2Integration } = action;
      return {
        ...state,
        kno2Integration,
      };
    case ActionTypes.SET_KNO2_INTEGRATION_ORDER_FORM:
      const { kno2IntegrationOrderForm } = action;
      return {
        ...state,
        kno2IntegrationOrderForm,
      };
    case ActionTypes.SET_ORGANIZATION_TYPES:
      const { organizationTypes } = action;
      return {
        ...state,
        organizationTypes,
      };
    case ActionTypes.SET_ORGANIZATION_SPECIALTIES:
      const { organizationSpecialties } = action;
      return {
        ...state,
        organizationSpecialties,
      };
    case ActionTypes.SET_JOB_FUNCTIONS:
      const { jobFunctions } = action;
      return {
        ...state,
        jobFunctions,
      };
    case ActionTypes.SET_ISLOADING:
      const { isLoading } = action;
      return {
        ...state,
        isLoading,
      };
    default:
      return state;
  }
};

const Kno2IntegrationDetails = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { kno2IntegrationId } = useParams<{ kno2IntegrationId: string }>();
  const apiClient = useApiClient();

  useEffect(() => {
    const fetchData = async () => {
      if (kno2IntegrationId) {
        const kno2IntegrationResult = await apiClient.HihHelperClient.GetKno2Integration(kno2IntegrationId);

        if (kno2IntegrationResult) {
          dispatch(setKno2Integration(kno2IntegrationResult));

          // Fetch and map the organization types, specialties, and job functions for name display
          const organizationTypesResponse = await apiClient.HihHelperClient.GetOrganizationTypes();
          const organizationSpecialtiesResponse = await apiClient.HihHelperClient.GetOrganizationSpecialties();
          const jobFunctionsResponse = await apiClient.HihHelperClient.GetJobFunctions();

          const organizationTypes = organizationTypesResponse.map((type: { key: string; value: string }) => type);
          const organizationSpecialties = organizationSpecialtiesResponse.map(
            (type: { key: string; value: string }) => type,
          );
          const jobFunctions = jobFunctionsResponse.map((type: { name: string; id: number }) => type);

          if (organizationTypes) {
            dispatch(setOrganizationTypes(organizationTypes));
          }
          if (organizationSpecialties) {
            dispatch(setOrganizationSpecialties(organizationSpecialties));
          }
          if (jobFunctions) {
            dispatch(setJobFunctions(jobFunctions));
          }

          // Get order form
          const kno2IntegrationOrderFormResult =
            await apiClient.HihHelperClient.GetKno2IntegrationOrderFormByKno2IntegrationId(kno2IntegrationResult.id);

          if (kno2IntegrationOrderFormResult) {
            dispatch(setKno2IntegrationOrderForm(kno2IntegrationOrderFormResult));
          }
        } else {
          // @TODO: What if we go to a bad route?
          //navigate(routes.accounts);
        }
      }
      dispatch(setIsLoading(false));
    };

    fetchData();
  }, [apiClient.HihHelperClient, kno2IntegrationId]);

  return (
    <div>
      <h1>Kno2 Integration Details</h1>
      <hr />
      {state.isLoading ? (
        <ImageTrendForm.LoadingSpinner />
      ) : (
        <>
          <ListGroup>
            <ListGroup.Item>
              <strong>Client Account ID:</strong> {state.kno2Integration.clientAccountId}
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>Kno2 Organization ID:</strong> {state.kno2Integration.kno2OrganizationId}
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>Granted Access with Kno2:</strong>{' '}
              {state.kno2Integration.grantedUseForAccessWithKno2 ? 'Yes' : 'No'}
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>Activated on Kno2 Network:</strong>{' '}
              {state.kno2Integration.isActivatedOnKno2Network ? 'Yes' : 'No'}
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>Created On:</strong> {state.kno2Integration.createdOnUtc.toLocaleString()}
            </ListGroup.Item>
          </ListGroup>
          <br />
          <h2>Organization</h2>
          <ListGroup>
            <ListGroup.Item>
              <strong>Name:</strong> {state.kno2Integration.organizationName}
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>Subdomain:</strong> {state.kno2Integration.organizationSubdomain}
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>Type:</strong> {getOrganizationTypeName(state, state.kno2Integration.organizationTypeId)}
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>Specialty:</strong>{' '}
              {getOrganizationSpecialtyName(state, state.kno2Integration.organizationSpecialtyId)}
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>EHR:</strong> {state.kno2Integration.organizationEHR}
            </ListGroup.Item>
            {/* <ListGroup.Item>
          <strong>Website:</strong> {state.kno2Integration.website}
        </ListGroup.Item> */}
            {/* <ListGroup.Item>
          <strong>NPI:</strong> {state.kno2Integration.npi}
        </ListGroup.Item> */}
            <ListGroup.Item>
              <strong>Primary Phone:</strong> {state.kno2Integration.organizationPrimaryPhone}
            </ListGroup.Item>
            {/* <ListGroup.Item>
          <strong>Organization Primary Fax:</strong> {state.kno2Integration.organizationPrimaryFax}
        </ListGroup.Item> */}
            <ListGroup.Item>
              <strong>Description:</strong> {state.kno2Integration.organizationDescription}
            </ListGroup.Item>
            {/* <ListGroup.Item>
          <strong>Care Setting:</strong> {state.kno2Integration.careSetting}
        </ListGroup.Item> */}
            {/* <ListGroup.Item>
          <strong>Create First User From Primary Contact:</strong> {state.kno2Integration.createFirstUserFromPrimaryContact ? 'Yes' : 'No'}
        </ListGroup.Item> */}
          </ListGroup>
          <br />
          <h2>Primary Contact</h2>
          <ListGroup>
            <ListGroup.Item>
              <strong>First Name:</strong> {state.kno2Integration.primaryContactFirstName}
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>Last Name:</strong> {state.kno2Integration.primaryContactLastName}
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>Email Address:</strong> {state.kno2Integration.primaryContactEmail}
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>Phone Number:</strong> {state.kno2Integration.primaryContactPhone}
            </ListGroup.Item>
            {/* <ListGroup.Item>
              <strong>Primary Contact Customer Extension:</strong> {state.kno2Integration.primaryContactCustomerExtension}
            </ListGroup.Item> */}
            <ListGroup.Item>
              <strong>Role:</strong> {state.kno2Integration.primaryContactRole}
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>Job Function:</strong>{' '}
              {getJobFunctionName(state, state.kno2Integration.primaryContactJobFunctionId)}
            </ListGroup.Item>
          </ListGroup>
          <br />
          <h2>Location</h2>
          <ListGroup>
            <ListGroup.Item>
              <strong>Street:</strong> {state.kno2Integration.organizationStreet}
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>City:</strong> {state.kno2Integration.organizationCity}
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>State:</strong> {state.kno2Integration.organizationState}
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>Postal Code:</strong> {state.kno2Integration.organizationPostalCode}
            </ListGroup.Item>
          </ListGroup>
          <hr />
          <h2>Order Form</h2>
          {state.kno2IntegrationOrderForm ? (
            <ListGroup>
              <ImageTrendForm.ListView
                linkTo={routes.kno2_integration_order_form_details.replace(
                  ':kno2IntegrationId',
                  state.kno2IntegrationOrderForm.kno2IntegrationId,
                )}
                title="Location, Legal Entity, or Business Name"
                value={state.kno2IntegrationOrderForm.locationLegalEntityBusinessName}
              />
              <ImageTrendForm.ListView
                title="Single or Multi Agency"
                value={state.kno2IntegrationOrderForm.singleOrMultiAgency}
              />
              <ImageTrendForm.ListView
                title="Invoicing Metric"
                value={state.kno2IntegrationOrderForm.invoicingMetric}
              />
              <ImageTrendForm.ListView title="Metric Quantity" value={state.kno2IntegrationOrderForm.metricQuantity} />
              <ImageTrendForm.ListView
                title="Created On"
                value={dateTimeUtcToLocalString(state.kno2IntegrationOrderForm.createdOnUtc)}
              />
            </ListGroup>
          ) : (
            <CreateButton
              to={routes.kno2_integration_order_form_create.replace(':kno2IntegrationId', state.kno2Integration.id)}
            />
          )}
        </>
      )}
      <hr />
      <BackButton to={routes.client_accounts_details.replace(':id', state.kno2Integration.clientAccountId)} />
    </div>
  );
};

export default Kno2IntegrationDetails;
