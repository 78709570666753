// We could use a library like query-string or qs, but for now we'll go with this very simple implementation.
// It doesn't handle things like more than one param with the same name, so if we need that then we'll need something fancier.

export function parseQueryString(queryString: any): Record<string, string | undefined> {
  if (!queryString) {
    return {};
  }

  if (typeof queryString === 'object') {
    // Make sure all of the values are strings.
    let queryStringClone: Record<string, any> | undefined;
    for (const key of Object.keys(queryString)) {
      let value = queryString[key];
      if (typeof value !== 'string') {
        value = convertToString(value);

        if (!queryStringClone) {
          queryStringClone = { ...queryString } as Record<string, any>;
        }
        if (value) {
          queryStringClone[key] = value;
        } else {
          delete queryStringClone[key];
        }
      }
    }
    return queryString;
  }

  const results: Record<string, string | undefined> = {};
  if (!queryString || typeof queryString !== 'string') {
    return results;
  }

  const tokens = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (const token of tokens) {
    const pair = token.split('=', 2);
    const key = decodeURIComponent(pair[0]);
    const value = decodeURIComponent(pair[1] ?? '');
    results[key] = value;
  }
  return results;
}

type QueryStringValueType = string | number | boolean | undefined;
type QueryStringType =
  | string
  | Partial<Record<string, QueryStringValueType | QueryStringValueType[] | any>>
  | undefined;

export function appendQueryString(urlOrPath: string | undefined, queryStringInput: QueryStringType): string {
  if (!urlOrPath) {
    urlOrPath = '';
  }

  if (!queryStringInput) {
    return urlOrPath;
  }

  queryStringInput = stringifyQueryString(queryStringInput);
  if (!queryStringInput || queryStringInput.length === 0) {
    return urlOrPath;
  }

  return urlOrPath + (urlOrPath.indexOf('?') < 0 ? '?' : '&') + queryStringInput;
}

export function stringifyQueryString(queryStringInput: QueryStringType, leadingDelimiter?: string): string {
  if (!queryStringInput) {
    return '';
  }
  if (typeof queryStringInput === 'string') {
    return leadingDelimiter ? leadingDelimiter + queryStringInput : queryStringInput;
  }
  if (!queryStringInput || typeof queryStringInput !== 'object') {
    return '';
  }

  const parts: string[] = [];
  appendQueryStringParts(parts, queryStringInput, undefined);
  if (parts.length === 0) {
    return '';
  }
  return (leadingDelimiter ?? '') + parts.join('&');
}

function appendQueryStringParts(
  parts: string[],
  value: QueryStringValueType | QueryStringValueType[] | QueryStringType,
  key: string | undefined,
): void {
  if (typeof value === 'undefined' || isNull(value)) {
    return;
  }

  if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
    if (key) {
      parts.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
    }
    return;
  }

  if (Array.isArray(value)) {
    for (const childValue of value) {
      appendQueryStringParts(parts, childValue, key);
    }
    return;
  }

  if (typeof value === 'object') {
    for (const childKey in value) {
      if (!Object.prototype.hasOwnProperty.call(value, childKey)) {
        continue;
      }

      const childValue = value[childKey];
      const childFullKey = key ? `${key}.${childKey}` : childKey;
      appendQueryStringParts(parts, childValue, childFullKey);
    }
  }
}

function convertToString(value: string | number | boolean | undefined): string | undefined {
  if (typeof value === 'undefined') {
    return;
  }
  if (typeof value === 'boolean') {
    return value ? 'true' : 'false';
  }
  if (typeof value === 'number') {
    if (isNaN(value)) {
      return undefined;
    }
    return value + '';
  }
  return value;
}

function isNull(value: any): value is null {
  // eslint-disable-next-line
  return value === null;
}
