import React from 'react';
import { AppContext } from './appContext';
import { ApiClient } from './clients/ApiClient';

export function useApiClient(): ApiClient {
  const appContextValue = React.useContext(AppContext);
  const apiClient = appContextValue?.apiClient;
  if (!apiClient) {
    throw new Error('Missing api client.');
  }
  return apiClient;
}
