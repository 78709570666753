import { useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useApiClient } from '../../API/useApiClient';
import ImageTrendModal from '../../Components/common/ImageTrendModal';

enum RunningState {
  NotStarted,
  Running,
  Finished,
  Errored,
}

interface NemsisConfigurationGeneratePasswordModalProps {
  id: string;
  show: boolean;
  toggleShow: () => void;
  successCallback: (password: string) => void;
}

export default function NemsisConfigurationGeneratePasswordModal({
  id,
  successCallback,
  ...props
}: NemsisConfigurationGeneratePasswordModalProps) {
  const [loadingState, setLoadingState] = useState<RunningState>(RunningState.NotStarted);
  const apiClient = useApiClient();

  const sendGeneratePasswordRequest = async () => {
    if (loadingState !== RunningState.NotStarted) {
      return;
    }
    setLoadingState(RunningState.Running);
    try {
      const newPassword = await apiClient.IngressServiceClient.PatchNemsisConfigurationPasswordReset(id);
      successCallback(newPassword);
      setLoadingState(RunningState.Finished);
      props.toggleShow();
    } catch (error) {
      setLoadingState(RunningState.Errored);
    }
  };

  return (
    <ImageTrendModal
      show={props.show}
      toggleShow={props.toggleShow}
      confirm={() => sendGeneratePasswordRequest()}
      overrideConfirmClose
      keepOpen={loadingState === RunningState.Running}
      hideChoices={loadingState === RunningState.Running}
      confirmText={loadingState === RunningState.NotStarted ? 'Generate Password' : undefined}
      negateText={loadingState === RunningState.NotStarted ? 'Close' : 'Done'}
      headerTitle="Generate New Password"
    >
      {loadingState === RunningState.NotStarted && (
        <>You are about to reset the password to this NEMSIS Configuration.</>
      )}
      {loadingState === RunningState.Running && (
        <>
          <Spinner />
          &nbsp;Generating Password...
        </>
      )}
      {loadingState === RunningState.Errored && (
        <>An error occurred while generating a password. Please try again or contact support.</>
      )}
    </ImageTrendModal>
  );
}
