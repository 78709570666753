import { Formik } from 'formik';
import { useEffect, useReducer } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { FaArrowLeft, FaPlus } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useApiClient } from '../../API/useApiClient';
import ImageTrendForm from '../../Components/common/ImageTrendForm';
import { dateTimeUtcToLocalString } from '../../Helpers/DateTimeHelper';
import { Kno2OutcomeUserModel } from '../../Models/OutcomesService/Kno2OutcomeUser';
import { OutcomeUserType, OutcomeUserTypeEnum } from '../../Models/OutcomesService/OutcomeUserType';
import routes from '../../common/routesDefinitions';
import { outcomeUserSchema } from './Kno2OutcomeUserView.types';

interface Kno2OutcomeUserViewProps {
  isCreate?: boolean;
}

enum ActionTypes {
  SET_KNO2_OUTCOME_USER = 'SET_KNO2_OUTCOME_USER',
  SET_ISLOADING = 'SET_ISLOADING',
  SET_EDIT = 'SET_EDIT',
  SET_ALERT = 'SET_ALERT',
}

type State = {
  kno2OutcomeUser: Kno2OutcomeUserModel;
  isLoading: boolean;
  edit: boolean;
  alert?: string;
};

type SetKno2OutcomeUserAction = {
  type: typeof ActionTypes.SET_KNO2_OUTCOME_USER;
  kno2OutcomeUser: Kno2OutcomeUserModel;
};

type SetIsLoading = {
  type: typeof ActionTypes.SET_ISLOADING;
  isLoading: boolean;
};

type SetEdit = {
  type: typeof ActionTypes.SET_EDIT;
  edit: boolean;
};

type SetAlert = {
  type: typeof ActionTypes.SET_ALERT;
  alert?: string;
};

type ReducerActions = SetKno2OutcomeUserAction | SetIsLoading | SetEdit | SetAlert;

const setKno2OutcomeUser = (kno2OutcomeUser: Kno2OutcomeUserModel): ReducerActions => ({
  type: ActionTypes.SET_KNO2_OUTCOME_USER,
  kno2OutcomeUser,
});

const setIsLoading = (isLoading: boolean): ReducerActions => ({
  type: ActionTypes.SET_ISLOADING,
  isLoading,
});

const setEdit = (edit: boolean): ReducerActions => ({
  type: ActionTypes.SET_EDIT,
  edit,
});

const setAlert = (alert?: string): ReducerActions => ({
  type: ActionTypes.SET_ALERT,
  alert,
});

export const reducer = (state: State, action: ReducerActions): State => {
  switch (action.type) {
    case ActionTypes.SET_KNO2_OUTCOME_USER:
      const { kno2OutcomeUser } = action;
      return {
        ...state,
        kno2OutcomeUser,
      };
    case ActionTypes.SET_ISLOADING:
      const { isLoading } = action;
      return {
        ...state,
        isLoading,
      };
    case ActionTypes.SET_EDIT:
      const { edit } = action;
      return {
        ...state,
        edit,
      };
    case ActionTypes.SET_ALERT:
      const { alert } = action;
      return {
        ...state,
        alert,
      };
    default:
      return state;
  }
};

const Kno2OutcomeUserView = (props: Kno2OutcomeUserViewProps) => {
  const { clientId } = useParams<{ clientId: string }>();
  const initialState: State = {
    kno2OutcomeUser: {
      id: '',
      name: '',
      createdOnUtc: new Date(),
      clientAccountId: clientId ?? '',
      outcomeUserTypeId: null,
      isUsingAlternateId: false,
      eliteSiteUrl: '',
      eliteSiteOrganization: '',
      eliteIntegrationAccountToken: '',
      eliteIntegrationAccountPrivateKeyHex: '',
    },
    isLoading: true,
    edit: false,
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const apiClient = useApiClient();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setEdit(!!props.isCreate));
    const fetchData = async () => {
      if (clientId && !props.isCreate) {
        const kno2OutcomeUserResult = await apiClient.OutcomesServiceClient.GetOutcomeUserByClientAccountId(clientId);
        if (kno2OutcomeUserResult) {
          dispatch(setKno2OutcomeUser(kno2OutcomeUserResult));
        } else {
          dispatch(setEdit(true));
        }
      } else {
        dispatch(setEdit(true));
      }
      dispatch(setIsLoading(false));
    };

    fetchData();
  }, [apiClient.OutcomesServiceClient, clientId, props.isCreate]);

  return (
    <div>
      <Row>
        <Col>
          <h1>{props.isCreate && 'Create '}Outcome User</h1>
        </Col>
      </Row>
      <hr />
      {state.isLoading ? (
        <ImageTrendForm.LoadingSpinner />
      ) : (
        <>
          <Formik
            enableReinitialize={true}
            initialValues={state.kno2OutcomeUser}
            validationSchema={outcomeUserSchema}
            onSubmit={async (values) => {
              try {
                if (props.isCreate) {
                  await apiClient.OutcomesServiceClient.PostKno2OutcomeUser(values);
                  if (clientId) {
                    navigate(routes.kno2_outcome_users_view.replace(':clientId', clientId));
                  } else {
                    navigate(routes.client_accounts);
                  }
                } else {
                  // @TODO: Add update for Outcome User.
                  // await apiClient.OutcomesServiceClient.PutKno2OutcomeUser(values);
                  // dispatch(setEdit(false));
                }
              } catch (error: any) {
                if (error?.message) {
                  dispatch(setAlert(`Error occurred while saving Client Account: ${error.message}`));
                } else {
                  dispatch(setAlert('Error occurred while saving Client Account.'));
                }
              }
            }}
          >
            {({ handleSubmit, isSubmitting, values }) => (
              <Form className="form-horizontal" onSubmit={handleSubmit}>
                <Alert show={!!state.alert} variant="danger" dismissible onClose={() => dispatch(setAlert())}>
                  {state.alert}
                </Alert>
                <ImageTrendForm.HiddenGroup controlId="clientAccountId">
                  <ImageTrendForm.Hidden defaultValue={state.kno2OutcomeUser.clientAccountId} />
                </ImageTrendForm.HiddenGroup>
                <ImageTrendForm.Group controlId="name">
                  <ImageTrendForm.Label required>Name</ImageTrendForm.Label>
                  <ImageTrendForm.Field isEditable={state.edit} name="name" />
                </ImageTrendForm.Group>
                <ImageTrendForm.Group controlId="outcomeUserTypeId">
                  <ImageTrendForm.Label required>Outcome User Type</ImageTrendForm.Label>
                  <ImageTrendForm.SelectField
                    name="outcomeUserTypeId"
                    isEditable={state.edit}
                    options={Object.values(OutcomeUserType).map((x) => ({
                      label: x.name,
                      value: x.value,
                    }))}
                  />
                </ImageTrendForm.Group>
                <Collapse in={values.outcomeUserTypeId === OutcomeUserTypeEnum.Kno2}>
                  <div>
                    <ImageTrendForm.Group controlId="kno2OrganizationId">
                      <ImageTrendForm.Label required>Kno2 Organization ID</ImageTrendForm.Label>
                      <ImageTrendForm.Field isEditable={state.edit} name="kno2OrganizationId" />
                    </ImageTrendForm.Group>
                  </div>
                </Collapse>
                <Collapse in={values.outcomeUserTypeId === OutcomeUserTypeEnum.HL7}>
                  <div>
                    <ImageTrendForm.Group controlId="username">
                      <ImageTrendForm.Label required>Username</ImageTrendForm.Label>
                      <ImageTrendForm.Field isEditable={state.edit} name="username" />
                    </ImageTrendForm.Group>
                    <ImageTrendForm.Group controlId="password">
                      <ImageTrendForm.Label required>Password</ImageTrendForm.Label>
                      <ImageTrendForm.Password isEditable={state.edit} name="password" />
                    </ImageTrendForm.Group>
                  </div>
                </Collapse>
                <hr />
                <h3>Elite Site Integration</h3>
                <ImageTrendForm.Group controlId="eliteSiteUrl">
                  <ImageTrendForm.Label required>Elite Site URL</ImageTrendForm.Label>
                  <ImageTrendForm.Field isEditable={state.edit} name="eliteSiteUrl" />
                </ImageTrendForm.Group>
                <ImageTrendForm.Group controlId="eliteSiteOrganization">
                  <ImageTrendForm.Label required>Elite Site Organization</ImageTrendForm.Label>
                  <ImageTrendForm.Field isEditable={state.edit} name="eliteSiteOrganization" />
                </ImageTrendForm.Group>
                <ImageTrendForm.Group controlId="eliteIntegrationAccountToken">
                  <ImageTrendForm.Label required>Elite Integration Account Token</ImageTrendForm.Label>
                  <ImageTrendForm.Field isEditable={state.edit} name="eliteIntegrationAccountToken" />
                </ImageTrendForm.Group>
                <ImageTrendForm.Group controlId="eliteIntegrationAccountPrivateKeyHex">
                  <ImageTrendForm.Label required>Elite Integration Account Private Key</ImageTrendForm.Label>
                  <ImageTrendForm.Field isEditable={state.edit} name="eliteIntegrationAccountPrivateKeyHex" />
                </ImageTrendForm.Group>
                {/* <ImageTrendForm.Group>
                  <Button variant='secondary' disabled={!values.eliteSiteOrganization ||
                  !values.eliteSiteUrl ||
                  !values.eliteIntegrationAccountToken ||
                  !values.eliteIntegrationAccountPrivateKeyHex
                  }>
                    Test Elite Connection
                  </Button>
                </ImageTrendForm.Group> */}
                {!props.isCreate && (
                  <>
                    <ImageTrendForm.Group controlId="createdOnUtc">
                      <ImageTrendForm.Label>Created On</ImageTrendForm.Label>
                      <ImageTrendForm.Plaintext value={dateTimeUtcToLocalString(state.kno2OutcomeUser.createdOnUtc)} />
                    </ImageTrendForm.Group>
                    <ImageTrendForm.Group controlId="modifiedOnUtc">
                      <ImageTrendForm.Label>Modified On</ImageTrendForm.Label>
                      <ImageTrendForm.Plaintext value={dateTimeUtcToLocalString(state.kno2OutcomeUser.modifiedOnUtc)} />
                    </ImageTrendForm.Group>
                  </>
                )}
                {!!props.isCreate && (
                  <Button disabled={isSubmitting} type="submit">
                    <FaPlus /> Create
                  </Button>
                )}
              </Form>
            )}
          </Formik>
          <hr />
          <Link to={routes.client_accounts}>
            <Button variant="secondary">
              <FaArrowLeft /> Back
            </Button>
          </Link>
        </>
      )}
    </div>
  );
};

export default Kno2OutcomeUserView;
