import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../API/appContext';
import ImageTrendForm from '../common/ImageTrendForm';
import { ImageTrendOption } from '../common/ImageTrendSelect';
import { Kno2WizardFormData } from './Kno2Wizard';
import SelectFieldComponent from './SelectFieldComponent';

interface Step4Props {
  formData: Kno2WizardFormData;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const Step4: React.FC<Step4Props> = ({ formData, setFieldValue }) => {
  const appContext = useContext(AppContext);

  if (!appContext) {
    throw new Error('AppContext is not provided');
  }

  const { apiClient } = appContext;

  const [dataSchemas, setDataSchemas] = useState<ImageTrendOption<number>[]>([]);
  const [schemaVersions, setSchemaVersions] = useState<ImageTrendOption<number>[]>([]);

  useEffect(() => {
    const fetchDataSchemas = async () => {
      try {
        const response = await apiClient.IngressServiceClient.GetNemsisDataSchemas();
        const options = (response || []).map((schema: { value: number; name: string }) => ({
          value: schema.value,
          label: schema.name,
        }));
        setDataSchemas(options);
      } catch (error) {
        console.error('Error fetching NEMSIS Data Schemas:', error);
      }
    };

    const fetchSchemaVersions = async () => {
      try {
        const response = await apiClient.IngressServiceClient.GetNemsisSchemaVersions();
        const options = (response || []).map((version: { value: number; name: string }) => ({
          value: version.value,
          label: version.name,
        }));
        setSchemaVersions(options);
      } catch (error) {
        console.error('Error fetching NEMSIS Schema Versions:', error);
      }
    };

    fetchDataSchemas();
    fetchSchemaVersions();
  }, [apiClient]);

  return (
    <div>
      <h2>Step 4: NEMSIS Configuration</h2>

      <ImageTrendForm.NormalGroup controlId="nemsisConfig.name">
        <ImageTrendForm.Label required>Configuration Name</ImageTrendForm.Label>
        <ImageTrendForm.Field name="nemsisConfig.name" isEditable={true} />
      </ImageTrendForm.NormalGroup>

      <SelectFieldComponent
        required
        name="nemsisConfig.nemsisDataSchema"
        label="NEMSIS Data Schema"
        options={dataSchemas}
      />

      <SelectFieldComponent
        required
        name="nemsisConfig.nemsisSchemaVersion"
        label="NEMSIS Schema Version"
        options={schemaVersions}
      />

      <ImageTrendForm.NormalGroup controlId="nemsisConfig.transactionServiceModuleType">
        <ImageTrendForm.Label>Transaction Service Module Type</ImageTrendForm.Label>
        <ImageTrendForm.Field name="nemsisConfig.transactionServiceModuleType" isEditable={false} />
      </ImageTrendForm.NormalGroup>
    </div>
  );
};

export default Step4;
