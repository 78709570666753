import { string, number, object } from 'yup';
import { PartialShape } from '../../Components/common/Schema';
import { Kno2OutcomeUserModel } from '../../Models/OutcomesService/Kno2OutcomeUser';
import { OutcomeUserTypeEnum } from '../../Models/OutcomesService/OutcomeUserType';

const shape: PartialShape<Kno2OutcomeUserModel> = {
  clientAccountId: string().label('Client Account ID').required(),
  name: string().label('Name').required(),
  outcomeUserTypeId: number().label('Outcome User Type').required(),
  kno2OrganizationId: string()
    .label('Kno2 Organization ID')
    .when('outcomeUserTypeId', {
      is: (outcomeUserTypeId?: number) => outcomeUserTypeId === OutcomeUserTypeEnum.Kno2,
      then: (schema) => schema.required(),
    }),
  eliteSiteUrl: string().label('Elite Site URL').required(),
  eliteSiteOrganization: string().label('Elite Site Organization').required(),
  eliteIntegrationAccountToken: string().label('Elite Integration Account Token').required(),
  eliteIntegrationAccountPrivateKeyHex: string().label('Elite Integration Account Private Key').required(),
};

export const outcomeUserSchema = object<Kno2OutcomeUserModel>().shape(shape, [
  ['outcomeUserTypeId', 'kno2OrganizationId'],
]);
