import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { AppContext, AppContextValue } from './API/appContext';
import { createApiClient } from './API/clients/ApiClient';
import Dashboard from './Views/Dashboard';
import ErrorPage from './Views/ErrorPage';

// Account Routes
import Accounts from './Views/Accounts/Accounts';
import ClientAccount from './Views/ClientAccounts/ClientAccount';
import ClientAccountList from './Views/ClientAccounts/ClientAccountList';
import CreateKno2Integration from './Views/Kno2Integrations/CreateKno2Integration';

// Transaction Routes
import TransactionRecord from './Views/Transactions/TransactionRecord';
import TransactionRecordDetails from './Views/Transactions/TransactionRecordDetails';
import Transactions from './Views/Transactions/Transactions';

// Audit Routes
import Audits from './Views/Audits/Audits';
import NemsisFileRecordAudit from './Views/Audits/NemsisFileRecordAudit';
import NemsisSubmitDataAudit from './Views/Audits/NemsisSubmitDataAudit';

// Resource Manager Routes
import DestinationMapping from './Views/ResourceManager/DestinationMappings/DestinationMapping';
import DestinationMappingList from './Views/ResourceManager/DestinationMappings/DestinationMappingList';
import ResourceManager from './Views/ResourceManager/ResourceManager';

// Auth/Misc
import { useRef } from 'react';
import Alert from 'react-bootstrap/Alert';
import RequireAdminRoleRoute from './Components/security/RequireAdminRoleRoute';
import RequireAuthRoute from './Components/security/RequireAuthRoute';
import RequireSuperAdminRoleRoute from './Components/security/RequireSuperAdminRoleRoute';
import Kno2TransactionDetails from './Views/Kno2Transactions/Kno2TransactionDetails';
import Kno2TransactionList from './Views/Kno2Transactions/Kno2TransactionList';
import Settings from './Views/Users/Settings';
import routes from './common/routesDefinitions';

// Import Wizard Components
import Kno2Wizard from './Components/wizard/Kno2Wizard';
import SubmissionResults from './Components/wizard/SubmissionResults';
import Kno2IntegrationOrderForm from './Views/Kno2IntegrationOrderForm/Kno2IntegrationOrderForm';
import Kno2IntegrationDetails from './Views/Kno2Integrations/Kno2IntegrationDetails';
import Kno2StandardModuleView from './Views/Kno2Modules/Kno2StandardModuleView';
import Kno2OutcomeUserView from './Views/Kno2OutcomeUser/Kno2OutcomeUserView';
import { ImageTrendToastContainerRefProps } from './Components/common/ImageTrendToast';

export interface AppProps {
  instance: IPublicClientApplication;
}

function App({ instance }: AppProps) {
  // This is our toast reference for api calls.
  const toastRef = useRef<ImageTrendToastContainerRefProps | null>(null);

  // A bit of a hacky hack, but we can grab the instance here and work with it.
  const apiClient = createApiClient({ instance, toastRef });
  const appContextValue: AppContextValue = {
    apiClient,
    appVersion: process.env.REACT_APP_VERSION,
  };

  return (
    <MsalProvider instance={instance}>
      <AppContext.Provider value={appContextValue}>
        <Router>
          <Routes>
            <Route element={<RequireAuthRoute toastRef={toastRef} />}>
              {
                // @TODO: Remove these test routes.
              }
              <Route element={<RequireAdminRoleRoute />}>
                <Route path="/test-admin" element={<Alert>Admin Test</Alert>} />
              </Route>
              <Route element={<RequireSuperAdminRoleRoute />}>
                <Route path="/test-super-admin" element={<Alert>Super Admin Test</Alert>} />
              </Route>
              <Route path="*" element={<ErrorPage />} />
              <Route path={routes.dashboard} element={<Dashboard />} />
              <Route path={routes.accounts} element={<Accounts />} />
              <Route path={routes.client_accounts} element={<ClientAccountList />} />
              <Route path={routes.client_accounts_create} element={<ClientAccount isCreate={true} />} />
              <Route path={routes.client_accounts_details} element={<ClientAccount />} />
              <Route path={routes.kno2_integration_create} element={<CreateKno2Integration />} />
              <Route path={routes.kno2_integration_details} element={<Kno2IntegrationDetails />} />
              <Route path={routes.kno2_integration_order_form_create} element={<Kno2IntegrationOrderForm isCreate />} />
              <Route path={routes.kno2_integration_order_form_details} element={<Kno2IntegrationOrderForm />} />
              <Route path={routes.kno2_outcome_users_view} element={<Kno2OutcomeUserView />} />
              <Route path={routes.kno2_outcome_users_create} element={<Kno2OutcomeUserView isCreate />} />
              <Route path={routes.transactions} element={<Transactions />} />
              <Route path={routes.kno2_transactions} element={<Kno2TransactionList />} />
              <Route path={routes.kno2_transactions_details} element={<Kno2TransactionDetails />} />
              <Route path={routes.kno2_module_view_via_transaction} element={<Kno2StandardModuleView />} />
              <Route path={routes.kno2_standard_module_view} element={<Kno2StandardModuleView />} />
              <Route path={routes.transactions_transactionRecord} element={<TransactionRecord />} />
              <Route
                path="/transactions/transactionrecord/transactionrecorddetails/:id"
                element={<TransactionRecordDetails />}
              />
              <Route path={routes.audits} element={<Audits />} />
              <Route path={routes.audits_nemsisfilerecord} element={<NemsisFileRecordAudit />} />
              <Route path={routes.audits_nemsissubmitdata} element={<NemsisSubmitDataAudit />} />
              <Route path={routes.resource_manager} element={<ResourceManager />} />
              <Route path={routes.destination_mappings} element={<DestinationMappingList />} />
              <Route path={routes.destination_mappings_create} element={<DestinationMapping isCreate />} />
              <Route path={routes.destination_mappings_details} element={<DestinationMapping />} />
              <Route path={routes.settings} element={<Settings />} />
              <Route path={routes.kno2_wizard} element={<Kno2Wizard />} />
              <Route path={routes.submission_results} element={<SubmissionResults />} />
            </Route>
          </Routes>
        </Router>
      </AppContext.Provider>
    </MsalProvider>
  );
}

export default App;
