import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ImmerReducer, useImmerReducer } from 'use-immer';
import { useApiClient } from '../../API/useApiClient';
import routes from '../../common/routesDefinitions';
import ImageTrendForm from '../../Components/common/ImageTrendForm';
import ImageTrendPagination from '../../Components/common/ImageTrendPagination';
import ImageTrendTable from '../../Components/common/ImageTrendTable';
import {
  DefaultPagination,
  OffsetLimit,
  PaginationReducer,
  ReducerAction,
} from '../../Components/common/Pagination.types';
import { dateTimeUtcToLocalString } from '../../Helpers/DateTimeHelper';
import { QueryResults } from '../../Models/Common/QueryResults';
import { Kno2StandardModuleModel } from '../../Models/TransactionService/Kno2StandardModuleModel';

interface Kno2StandardModuleListProps {
  clientAccountId: string;
}

interface QueryParamaters extends OffsetLimit {
  clientAccountId: string;
}

enum QueryAction {
  clientAccountId = 'clientAccountId',
}

export const Kno2StandardModuleReducer: ImmerReducer<QueryParamaters, ReducerAction<QueryParamaters>> = (
  draft,
  action,
) => {
  switch (action.type) {
    case QueryAction.clientAccountId:
      draft.clientAccountId = action.data.clientAccountId;
      break;
    default:
      PaginationReducer(draft, action);
  }
};

const Kno2StandardModuleList = ({ clientAccountId }: Kno2StandardModuleListProps) => {
  const [data, setData] = useState<QueryResults<Kno2StandardModuleModel>>({ results: [], totalRows: 0 });
  const [isLoading, setIsLoading] = useState(true);
  const apiClient = useApiClient();
  const columnHelper = createColumnHelper<Kno2StandardModuleModel>();
  const defaultState = {
    clientAccountId,
    ...DefaultPagination,
  };
  const [tableState, dispatchTableState] = useImmerReducer(Kno2StandardModuleReducer, defaultState);

  const resultColumns: ColumnDef<Kno2StandardModuleModel, any>[] = [
    columnHelper.accessor('name', {
      header: 'Name',
      cell: ({ row }) => (
        <Link
          to={routes.kno2_standard_module_view
            .replace(':clientId', clientAccountId)
            .replace(':moduleId', row.original.id)}
        >
          {row.original.name}
        </Link>
      ),
    }),
    columnHelper.accessor((row) => dateTimeUtcToLocalString(row.createdOnUtc), {
      id: 'createdOnUtc',
      header: 'Created On',
    }),
  ];

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const data = await apiClient.TransactionServiceClient.GetKno2StandardModuleListByClientId(tableState);
      setData(data);
      setIsLoading(false);
    };

    fetchData();
  }, [apiClient.TransactionServiceClient, tableState]);

  return (
    <>
      <div>
        <h2>Kno2 Modules</h2>
        {isLoading ? (
          <ImageTrendForm.LoadingSpinner />
        ) : data?.results?.length > 0 ? (
          <div>
            <ImageTrendTable columns={resultColumns} data={data.results} striped={true} />
            <ImageTrendPagination
              paginationQuery={tableState}
              updatePaginationQuery={dispatchTableState}
              total={data.totalRows}
            />
          </div>
        ) : (
          <p>No modules available.</p>
        )}
      </div>
    </>
  );
};

export default Kno2StandardModuleList;
