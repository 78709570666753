import React, { useEffect, useState } from 'react';
import { useApiClient } from '../../API/useApiClient';
import HihPagination from '../../Components/common/HihPagination';
import { NemsisSubmitDataAuditListItem } from '../../Models/IngressService/NemsisSubmitDataAuditListItem';
import '../../Styling/index.css';

interface DataTableProps {}

const NemsisSubmitDataAudit: React.FC<DataTableProps> = () => {
  // @TODO: Use reducer.
  const [data, setData] = useState<NemsisSubmitDataAuditListItem[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [createdOnStart, setCreatedOnStart] = useState('');
  const [createdOnEnd, setCreatedOnEnd] = useState('');
  const apiClient = useApiClient();

  useEffect(() => {
    const fetchData = async () => {
      const { data, totalPages } = await apiClient.IngressServiceClient.GetNemsisSubmitDataAuditList({
        PageNumber: currentPage,
        CreatedOnStart: createdOnStart,
        CreatedOnEnd: createdOnEnd,
      });
      setData(data);
      setTotalPages(totalPages);
    };

    fetchData();
  }, [apiClient.IngressServiceClient, currentPage, createdOnStart, createdOnEnd]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleTimeFilter = (interval: number) => {
    const currentTime = new Date();
    currentTime.setHours(currentTime.getHours() - interval);
    const createdOnStart = currentTime.toLocaleString();
    setCreatedOnStart(createdOnStart);
    setCreatedOnEnd('');
    setCurrentPage(1);
  };

  return (
    <div>
      <h1>Nemsis Submit Data Audits</h1>
      <h2>Search Parameters</h2>
      <div>
        <button onClick={() => handleTimeFilter(1)}>Last Hour</button>
        <button onClick={() => handleTimeFilter(6)}>Last 6 Hours</button>
        <button onClick={() => handleTimeFilter(24)}>Last 24 Hours</button>
      </div>
      <div>
        <label htmlFor="startDateTimePicker">Start Date: </label>
        <input
          type="datetime-local"
          id="startDateTimePicker"
          value={createdOnStart}
          onChange={(event) => setCreatedOnStart(event.target.value)}
        />
        <label htmlFor="endDateTimePicker">End Date: </label>
        <input
          type="datetime-local"
          id="endDateTimePicker"
          value={createdOnEnd}
          onChange={(event) => setCreatedOnEnd(event.target.value)}
        />
      </div>
      {data?.length > 0 ? (
        <div>
          <table>
            <thead>
              <tr>
                <th>SubmitDataAuditID</th>
                <th>NemsisConfigurationID</th>
                <th>Status Code</th>
                <th>Description</th>
                <th>Message</th>
                <th>TransactionHandle</th>
                <th>CreatedOn</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.submitDataAuditID}>
                  <td>{item.submitDataAuditID}</td>
                  <td>{item.nemsisConfigurationID}</td>
                  <td>{item.statusCode}</td>
                  <td>{item.statusCodeDescription}</td>
                  <td>{item.message}</td>
                  <td>{item.transactionHandle}</td>
                  <td>{new Date(item.createdOn).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <HihPagination
            handleNextPage={handleNextPage}
            handlePreviousPage={handlePreviousPage}
            currentPage={currentPage}
            totalPages={totalPages}
          />
        </div>
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};

export default NemsisSubmitDataAudit;
