import Form from 'react-bootstrap/Form';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';

export type ImageTrendOption<T> = {
  label: string;
  value: T;
};

export type ImageTrendOptionGroup<T> = {
  label?: string;
  options: ImageTrendOption<T>[];
};

export type ImageTrendOptionOrGroup<T> = ImageTrendOption<T> | ImageTrendOptionGroup<T>;

/**
 * Gets the Option from an array of Options or OptionGroups
 * @param id The value of the option to find.
 * @param arr The array of options to search through.
 * @returns The option if found or null.
 */
export function getImageTrendOption<T>(id: T, arr: ImageTrendOptionOrGroup<T>[]) {
  if (arr.length === 0) return null;
  return arr.flatMap((_) => (_ as ImageTrendOptionGroup<T>).options || _).find((_) => _.value === id) ?? null;
}

export function getImageTrendOptions<T>(ids: T[], arr: ImageTrendOption<T>[]) {
  if (arr.length === 0) return [];
  if (ids.length === 0) return [];
  return arr.filter((x) => ids.indexOf(x.value) !== -1);
}

export function createImageTrendOption(value: string | undefined | null): ImageTrendOption<string> | null {
  return value
    ? {
        value: value,
        label: value,
      }
    : null;
}

export function createImageTrendOptions<T extends string>(arr: T[] | undefined | null): ImageTrendOption<T>[] {
  return (
    arr?.map((value) => ({
      value: value,
      label: value,
    })) ?? []
  );
}

export type ImageTrendSelectProps = {
  isInvalid?: boolean;
  isValid?: boolean;
  feedback?: string;
} & StateManagerProps<unknown, false>;

const ImageTrendSingleSelect = ({ isInvalid, isValid, feedback, ...props }: ImageTrendSelectProps) => {
  return (
    <>
      <ReactSelect
        {...props}
        isSearchable
        aria-invalid={isInvalid}
        classNames={{
          control: (props) => {
            return `${props.className ?? ''} ${
              isValid === true && !props.isFocused
                ? 'border-success'
                : isInvalid === true && !props.isFocused
                  ? 'border-danger'
                  : ''
            }`;
          },
          container: (props) => {
            return `${props.className ?? ''} ${isInvalid === true && !props.isFocused ? 'is-invalid' : ''}`;
          },
        }}
        styles={{
          menu: (base) => ({
            ...base,
            width: 'max-content',
            minWidth: '100%',
            zIndex: 9999,
          }),
        }}
      />
      {feedback && <Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>}
    </>
  );
};

const ImageTrendSingleCreatableSelect = ({ isInvalid, isValid, feedback, id, ...props }: ImageTrendSelectProps) => {
  return (
    <>
      <CreatableSelect
        {...props}
        isSearchable
        aria-invalid={isInvalid}
        classNames={{
          control: (props) => {
            return `${props.className ?? ''} ${
              isValid === true && !props.isFocused
                ? 'border-success'
                : isInvalid === true && !props.isFocused
                  ? 'border-danger'
                  : ''
            }`;
          },
          container: (props) => {
            return `${props.className ?? ''} ${isInvalid === true && !props.isFocused ? 'is-invalid' : ''}`;
          },
        }}
        styles={{
          menu: (base) => ({
            ...base,
            width: 'max-content',
            minWidth: '100%',
            zIndex: 9999,
          }),
        }}
      />
      {feedback && <Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>}
    </>
  );
};

export type ImageTrendMultiSelectProps = {
  isInvalid?: boolean;
  isValid?: boolean;
  feedback?: string;
} & StateManagerProps<unknown, true>;

const ImageTrendMultiSelect = ({ isInvalid, isValid, feedback, ...props }: ImageTrendMultiSelectProps) => {
  return (
    <>
      <ReactSelect
        {...props}
        isSearchable
        isMulti
        classNames={{
          control: (props) => {
            return `${props.className ?? ''} ${
              isValid === true && !props.isFocused
                ? 'border-success'
                : isInvalid === true && !props.isFocused
                  ? 'border-danger'
                  : ''
            }`;
          },
          container: (props) => {
            return `${props.className ?? ''} ${isInvalid === true && !props.isFocused ? 'is-invalid' : ''}`;
          },
        }}
        styles={{
          menu: (base) => ({
            ...base,
            width: 'max-content',
            minWidth: '100%',
            zIndex: 9999,
          }),
        }}
      />
      {feedback && <Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>}
    </>
  );
};

export { ImageTrendMultiSelect, ImageTrendSingleSelect, ImageTrendSingleCreatableSelect };
