import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import { Link } from 'react-router-dom';
import routes from '../../common/routesDefinitions';

const ResourceManager = () => {
  return (
    <div>
      <h1>Resource Manager</h1>
      <br />
      <ListGroup>
        <ListGroupItem>
          <Link to={routes.destination_mappings}>Destination Mappings</Link>
        </ListGroupItem>
      </ListGroup>
    </div>
  );
};

export default ResourceManager;
