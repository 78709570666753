import React from 'react';
import Button, { ButtonProps } from 'react-bootstrap/Button';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';

interface CreateButtonProps extends ButtonProps {
  to?: string;
}

/**
 * A create button with an optional <Link> wrapper.
 * @param props Button properties with an optional to for Links.
 * @returns
 */
const CreateButton: React.FC<CreateButtonProps> = ({ to, ...props }: CreateButtonProps) => {
  const button = (
    <Button {...props}>
      <FaPlus /> Create
    </Button>
  );

  return to ? <Link to={to}>{button}</Link> : button;
};

export default CreateButton;
