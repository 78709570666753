import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
import LoginPage from './LoginPage';

const Login = () => {
  const { instance } = useMsal();

  const handleSubmit = async () => {
    await instance.loginPopup(loginRequest);
  };

  return (
    <>
      <LoginPage handleSubmit={handleSubmit} />
    </>
  );
};

export default Login;
